import React, { Component } from 'react';
import PropTypes from 'prop-types';
import queryString from 'query-string';

import Subheader from 'components/Subheader';
import Button from 'components/Button';
import StudentsListTable from 'components/StudentsListTable';

import { Container, TableContainer } from './styled';

class StudentsList extends Component {
  constructor(props) {
    super(props);

    this.toCreateActivity = this.toCreateActivity.bind(this);
    this.toStudentProfile = this.toStudentProfile.bind(this);
    this.selectStudent = this.selectStudent.bind(this);
  }

  state = {
    selectedStudents: [],
  };

  toCreateActivity() {
    const { collectionId } = this.props.match.params;
    const selected = queryString.stringify(
      { selectedStudents: this.state.selectedStudents },
      { arrayFormat: 'bracket' },
    );
    this.props.history.push(`/collections/${collectionId}/activities/create/?${selected}`);
  }

  toStudentProfile(row) {
    this.props.history.push(`./${row.id}/tasks`);
  }

  selectStudent(row) {
    const selected = [...this.state.selectedStudents];
    if (row.student_enrollment_id) {
      if (!selected.includes(row.student_enrollment_id)) {
        selected.push(row.student_enrollment_id);
      } else {
        const index = selected.indexOf(row.student_enrollment_id);
        selected.splice(index, 1);
      }

      this.setState({ selectedStudents: selected });
    }
  }

  render() {
    return (
      <Container>
        <Subheader module="students">
          <div>students</div>
          <Button
            color="#70a823"
            icon="plus"
            tabIndex={0}
            onClick={this.toCreateActivity}
          >
            Create activity
          </Button>
        </Subheader>
        <TableContainer>
          <StudentsListTable
            selectedStudents={this.state.selectedStudents}
            isExpanded
            onStudentClick={this.toStudentProfile}
            onStudentSelect={this.selectStudent}
          />
        </TableContainer>
      </Container>
    );
  }
}

StudentsList.propTypes = {
  history: PropTypes.object.isRequired,
  match: PropTypes.object.isRequired,
};

export default StudentsList;
