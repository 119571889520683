import React from 'react';
import PropTypes from 'prop-types';

import Title from 'components/Title';

import GroupName from './components/GroupName';
import GroupColorSelect from './components/GroupColorSelect';

import { Container, Main } from './styled';

const Description = ({
  onInputChange,
  groupName,
  groupColorKey,
  errorMessage,
}) => (
  <Container>
    <Title text="Description" />
    <Main>
      <GroupName
        groupName={groupName}
        onInputChange={onInputChange}
        errorMessage={errorMessage}
      />
      <GroupColorSelect groupColorKey={groupColorKey} onInputChange={onInputChange} />
    </Main>
  </Container>
);

Description.propTypes = {
  errorMessage: PropTypes.string.isRequired,
  groupColorKey: PropTypes.string.isRequired,
  groupName: PropTypes.string,
  onInputChange: PropTypes.func.isRequired,
};

Description.defaultProps = {
  groupName: '',
};

export default Description;
