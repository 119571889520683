import styled from 'styled-components';
import { Link } from 'react-router-dom';

export const EmptyDiv = styled.div`
  height: 50px;
`;

export const Header = styled.div`
  height: 50px;
  padding: 10px 0;
  display: flex;
  box-sizing: border-box;
  align-items: center;
  border-bottom: 1px solid ${({ theme }) => theme.primaryGrey};
  font-size: 16px;
  color: ${({ theme }) => theme.darkGrey};
  background: ${({ theme }) => theme.lightGrey};
  font-weight: bold;
  width: 100%;
`;

export const Info = styled.div`
  color: ${({ theme }) => theme.darkGrey};
`;

export const Rate = styled.div`
  height: 50px;
  width: 50px;
  margin-right: 20px;
  background-color: ${({ grade, theme }) => theme.grades[grade]};
  position: relative;
`;

export const Round = styled.h3`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 30px;
  height: 30px;
  border-radius: 50%;
  background-color: rgba(0,0,0,0.6);
  position: absolute;
  top: -7px;
  left: 10px;
  color: ${({ theme }) => theme.primaryWhite};
`;

export const StudentContainer = styled.div`
`;

export const StudentList = styled.div`
  overflow-y: auto;
  height: 420px;

  & > * {
    border-bottom: 1px solid ${({ theme }) => theme.primaryGrey};
    padding: 10px 0 10px 10px;
    height: 15%;
    color: ${({ theme }) => theme.darkGrey};
  }
`;

export const StyledLink = styled(Link)`
  text-decoration: none;
`;

export const Title = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px 13px 10px;
  border-bottom: 1px solid ${({ theme }) => theme.primaryGrey};
`;
