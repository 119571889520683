import moment from 'moment';
import { getTaskLastScoredDate } from 'redux/modules/grades/helpers';

const formatActivities = (list, grades) => list.map(item => ({
  ...item,
  end_date: moment(+(`${item.end_date}000`)).format('M/D/YYYY'),
  updated: moment().format('M/D/YYYY'),
  scored: getTaskLastScoredDate(grades, item.data_collections_sets_items),
  tasks_amount: item.data_collections_sets_items.length,
  students_amount: item.students_enrollments.length,
}));

export default formatActivities;
