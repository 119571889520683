import React from 'react';
import PropTypes from 'prop-types';

import { Button } from './styled';

const ActionButton = ({
  buttonText,
  handleClick,
  ...rest
}) => (
  <Button
    onClick={handleClick}
    {...rest}
  >
    {buttonText}
  </Button>
);

ActionButton.defaultProps = {
  handleClick: () => {},
  uppercase: true,
};

ActionButton.propTypes = {
  buttonText: PropTypes.string.isRequired,
  handleClick: PropTypes.func,
  uppercase: PropTypes.bool,
};

export default ActionButton;
