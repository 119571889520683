import React from 'react';
import PropTypes from 'prop-types';

import DroplistItem from './components/DroplistItem';
import { Container, ScrollWrapper } from './styled';

const Droplist = ({
  list,
  type,
  onDropdownSelect,
  onDropdownKeySelect,
  selectedItemId,
}) => (
  <ScrollWrapper>
    <Container type={type}>
      {list.map(item => (
        <DroplistItem
          key={item.id}
          role="button"
          tabIndex={0}
          item={item}
          type={type}
          onDropdownKeySelect={onDropdownKeySelect}
          onDropdownSelect={onDropdownSelect}
          selectedItemId={selectedItemId}
        />
      ))}
    </Container>
  </ScrollWrapper>
);

Droplist.propTypes = {
  list: PropTypes.arrayOf(PropTypes.object).isRequired,
  type: PropTypes.string.isRequired,
  onDropdownSelect: PropTypes.func.isRequired,
  onDropdownKeySelect: PropTypes.func.isRequired,
  selectedItemId: PropTypes.string.isRequired,
};

export default Droplist;
