import styled from 'styled-components';
import Select from 'react-select';

export const SelectStudentsGroup = styled(Select)`
  width: 220px;
  text-align: left;
  text-transform: none;
`;

export const SelectContainer = styled.div`
  margin-left: auto;
`;
