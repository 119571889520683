import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';

import Button from 'components/Button';
import Preloader from 'components/Preloader';

import Item from './components/Item';
import { Container, Header, Task, Title, Category, List } from './styled';

import { fetchHistory } from 'redux/modules/history';
import { getHistory } from 'redux/modules/history/selectors';
import { getShortcutByTaskId } from 'redux/modules/tasks/selectors';

class History extends Component {
  componentDidMount() {
    const { collectionId, studentId, taskId } = this.props.match.params;

    this.props.fetchHistory(collectionId, studentId, taskId);
  }

  render() {
    return (
      <Container>
        <Preloader loading={this.props.loading} />
        <Header>
          <Button
            icon="blueBack"
            size="lg"
            color="transparent"
            textColor="#666"
            aria-label="back to student's profile"
            onClick={() => this.props.history.goBack()}
          >
            Task history
          </Button>
          <Button
            onClick={() => this.props.history.push('./resources')}
          >
            Resources/materials
          </Button>
        </Header>
        <Task>
          <Category>{this.props.task && this.props.task.category}</Category>
          <Title>{this.props.task && this.props.task.title}</Title>
        </Task>
        <List>
          {this.props.historyData.map((item, index) => (
            <Item data={item} key={index} />
          ))}
        </List>
      </Container>
    );
  }
}

History.propTypes = {
  history: PropTypes.object.isRequired,
  historyData: PropTypes.array.isRequired,
  match: PropTypes.object.isRequired,
  task: PropTypes.object.isRequired,
  fetchHistory: PropTypes.func.isRequired,
  loading: PropTypes.bool.isRequired,
};

const mapStateToProps = ({ scale, history, tasks }, { match }) => ({
  historyData: getHistory({ history, scale }),
  task: getShortcutByTaskId(tasks, match.params.taskId),
  loading: scale.loading || history.loading || tasks.loading,
});

export default withRouter(connect(mapStateToProps, { fetchHistory })(History));

