import styled from 'styled-components';

export const Container = styled.div`
  height: 50px;
  display: flex;
  width: 150px;
  margin: 10px 0;
  border: 1px solid ${({ theme }) => theme.primaryGrey};
  border-radius: 3px;
  cursor: pointer;
  color: ${({ theme }) => theme.primaryGrey};
`;

export const Item = styled.span`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 50%;
  height: 100%;
  border-right: 1px solid ${({ theme }) => theme.primaryGrey};
  background: ${({ theme, value }) => (value ? theme.primaryGreen : 'none')};
  color: ${({ theme, value }) => (value ? theme.primaryWhite : theme.primaryGrey)};

  &:last-child {
    border: none;
  }
`;
