import styled from 'styled-components';

const Container = styled.div`
  position: relative;
  display: flex;
  flex: 1;
  flex-direction: column;
  background-color: ${({ theme }) => theme.lightGrey};
  color: ${({ theme }) => theme.darkGrey};
  overflow-y: scroll;
  height: calc(100% - 70px);
`;

export default Container;
