import http from '../../../http';
import update from 'immutability-helper';

import { normalizeHierarchy, normalizeSets } from './normalizers';
import { formatTasks, formatShortcuts } from './formatters';

const FETCH_TASKS_DATA_REQUEST = 'rfc/tasks/FETCH_TASKS_REQUEST';
const FETCH_TASKS_DATA_SUCCESS = 'rfc/tasks/FETCH_TASKS_SUCCESS';
const FETCH_TASKS_DATA_FAILURE = 'rfc/tasks/FETCH_TASKS_DATA_FAILURE';

const initialState = {
  hierarchy: {
    byId: [],
    listing: {},
  },
  sets: {
    byId: [],
    listing: {},
  },
  taskShortcuts: [],
  tasks: [],
  error: '',
  loading: false,
};

export default function reducer(state = initialState, action = {}) {
  switch (action.type) {
    case FETCH_TASKS_DATA_REQUEST:
      return update(state, { loading: { $set: true } });
    case FETCH_TASKS_DATA_SUCCESS:
      return update(state, {
        $set: {
          hierarchy: {
            byId: action.payload.hierarchy.result,
            listing: action.payload.hierarchy.entities.hierarchyItems || {},
          },
          sets: {
            byId: action.payload.sets.result,
            listing: action.payload.sets.entities.setItems || {},
          },
          taskShortcuts: action.payload.taskShortcuts,
          tasks: action.payload.tasks,
          error: '',
          loading: false,
        },
      });
    case FETCH_TASKS_DATA_FAILURE:
      return update(state, { $merge: { error: action.payload, loading: false } });
    default:
      return state;
  }
}

const requestTasks = () => ({ type: FETCH_TASKS_DATA_REQUEST });
const failedTasks = payload => ({ type: FETCH_TASKS_DATA_FAILURE, payload });
const receiveTasks = payload => ({ type: FETCH_TASKS_DATA_SUCCESS, payload });

export const fetchTasks = collectionId => (
  (dispatch) => {
    dispatch(requestTasks());

    return Promise.all([
      http.get(`en/api/v1/user/collections/${collectionId}/items.json`),
      http.get(`en/api/v1/user/collections/${collectionId}/items/hierarchy.json`),
      http.get(`en/api/v1/user/collections/${collectionId}/sets.json`),
    ])
      .then((values) => {
        const hierarchy = normalizeHierarchy(values[1].data);
        const sets = normalizeSets(values[2].data);
        const tasks = formatTasks(hierarchy.entities.hierarchyItems, sets, values[0].data);
        const taskShortcuts = formatShortcuts(sets.entities.setItems, tasks);
        dispatch(receiveTasks({
          hierarchy,
          sets,
          tasks,
          taskShortcuts,
        }));
      })
      .catch(responce => dispatch(failedTasks(responce)));
  }
);
