import styled from 'styled-components';

const Container = styled.div`
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  height: 100%;
  width: 100%;
  padding: 10px 0;
  padding-left: 25px;
  justify-content: space-around;
  border-bottom: 1px solid ${({ theme }) => (theme.primaryGrey)};
  height: 70px;
  color: ${({ theme }) => (theme.darkGrey)};
`;

export default Container;
