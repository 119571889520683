import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import find from 'lodash/find';

import { compareWithSelected } from 'redux/modules/scale/helpers';
import { getLevel } from 'redux/modules/scale/selectors';

import Button from 'components/Button';
import Preloader from 'components/Preloader';
import withKeyDown from 'components/withKeyDown';
import Changer from 'components/RatingChanger';
import Scale from 'components/Scale';

import {
  Container,
  Header,
  Subheader,
  ExpandHeader,
  Text,
  Title,
  Category,
  ExpandContent,
} from './styled';

const ExpandHeaderWithKeyDown = withKeyDown(ExpandHeader, 'Both');

class Score extends Component {
  static propTypes = {
    task: PropTypes.object.isRequired,
    scale: PropTypes.array.isRequired,
    history: PropTypes.object.isRequired,
    selected: PropTypes.string.isRequired,
    loading: PropTypes.bool.isRequired,
    match: PropTypes.object.isRequired,
    location: PropTypes.object.isRequired,
  };

  constructor(props) {
    super(props);

    this.toggle = this.toggle.bind(this);
    this.onScoreChange = this.onScoreChange.bind(this);
  }

  state = {
    isListExpanded: false,
  }

  onScoreChange(level) {
    const { history, location, match } = this.props;
    const { collectionId, taskId, studentId } = match.params;

    const baseUrl = `/collections/${collectionId}/rating/task/${taskId}/student/${studentId}`;

    if (location.search) {
      history.push(`${baseUrl + location.search}&mode=students&rating=${level}`);
      return;
    }

    history.push(`${baseUrl}?mode=students&rating=${level}`);
  }

  toggle() {
    this.setState({
      isListExpanded: !this.state.isListExpanded,
    });
  }

  render() {
    const { isListExpanded } = this.state;
    const {
      history,
      loading,
      scale,
      selected,
      task: { category, group, title },
    } = this.props;

    return (
      <Container>
        <Preloader loading={loading} />
        <Header>
          <Button
            icon="blueBack"
            size="lg"
            color="transparent"
            textColor="#666"
            onClick={() => history.goBack()}
            aria-label="back to student's profile"
          >
            Rate SKB
          </Button>
        </Header>
        <Subheader>{group}</Subheader>
        <ExpandHeaderWithKeyDown
          aria-label="open levels description"
          tabIndex={0}
          onClick={this.toggle}
        >
          <Text>
            <Category>{category}</Category>
            <Title>{title}</Title>
          </Text>
          {isListExpanded ?
            <Button
              aria-label="open levels description"
              tabIndex={-1}
              icon="collapse"
              size="md"
              color="transparent"
            /> :
            <Button
              aria-label="close levels description"
              tabIndex={-1}
              icon="expand"
              size="md"
              color="transparent"
            />
          }
        </ExpandHeaderWithKeyDown>
        <ExpandContent>
          {isListExpanded && <Scale grades={scale} />}
          <Changer onScoreChange={this.onScoreChange} score={scale} selected={selected} />
        </ExpandContent>
      </Container>
    );
  }
}

const mapStateToProps = ({
  tasks,
  scale,
  students,
  grades,
}, props) => {
  let level = '';
  const currentStudent = find(students.list, item => item.id === +props.match.params.studentId);

  if (currentStudent && grades.list.length && tasks.tasks.length && scale && scale.scale.length) {
    level = getLevel({
      grades,
      tasks,
      scale,
      currentStudent,
      props,
    });
  }

  const task = find(tasks.tasks, { id: +props.match.params.taskId });

  return {
    scale: compareWithSelected(
      scale.scale.filter(item => item.parent_id === +task.assessment_items_scales_directory_parent_id),
      scale.selectedLevels,
    ),
    task: find(tasks.taskShortcuts, item => (+item.id === +props.match.params.taskId)) || tasks,
    selected: level,
    loading: grades.loading || scale.loading,
  };
};

export default withRouter(connect(mapStateToProps)(Score));
