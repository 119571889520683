import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';

import { fetchArtifacts } from 'redux/modules/artifacts';
import ArtifactsList from 'components/ArtifactsList';
import Button from 'components/Button';
import Modal from 'components/Modal';
import Preloader from 'components/Preloader';
import Searchbar from 'components/Searchbar';
import { Container, Footer } from './styled';

class BrowseArtifactsModal extends PureComponent {
  static propTypes = {
    addSelected: PropTypes.func.isRequired,
    artifacts: PropTypes.arrayOf(PropTypes.object).isRequired,
    fetchArtifacts: PropTypes.func.isRequired,
    loading: PropTypes.bool.isRequired,
    match: PropTypes.object.isRequired,
    onRequestClose: PropTypes.func.isRequired,
  }

  constructor(props) {
    super(props);

    this.onSearchChange = this.onSearchChange.bind(this);
    this.getVisibleArtifacts = this.getVisibleArtifacts.bind(this);
    this.add = this.add.bind(this);
    this.close = this.close.bind(this);
    this.selectArtifact = this.selectArtifact.bind(this);
  }

  state = {
    selectedArtifacts: [],
    search: '',
  }

  componentDidMount() {
    this.props.fetchArtifacts(this.props.match.params.collectionId);
  }

  onSearchChange(event) {
    this.setState({ search: event.target.value });
  }

  getVisibleArtifacts() {
    const { artifacts } = this.props;
    const { search } = this.state;

    if (search) {
      const regexp = new RegExp(search, 'i');

      return artifacts.filter(artifact => regexp.test(artifact.comment));
    }

    return artifacts;
  }

  add() {
    const { addSelected, artifacts, onRequestClose } = this.props;
    const images = this.state.selectedArtifacts.map(item => artifacts.find(artifact => +artifact.id === +item));

    this.setState({ selectedArtifacts: [], search: '' });
    addSelected(images);
    onRequestClose();
  }

  close() {
    this.setState({ selectedArtifacts: [], search: '' });
    this.props.onRequestClose();
  }

  selectArtifact(id) {
    const selected = [...this.state.selectedArtifacts];

    if (!selected.includes(id)) {
      selected.push(id);
    } else {
      const index = selected.indexOf(id);
      selected.splice(index, 1);
    }

    this.setState({ selectedArtifacts: selected });
  }

  render() {
    const { loading } = this.props;

    if (loading) {
      return <Preloader loading={loading} />;
    }

    const artifacts = this.getVisibleArtifacts();

    return (
      <Modal {...this.props} headerTitle={<Searchbar onChange={this.onSearchChange} />} size="lg">
        <Container>
          <ArtifactsList artifacts={artifacts} selected={this.state.selectedArtifacts} onSelect={this.selectArtifact} />
        </Container>
        <Footer>
          <Button color="#70a823" onClick={this.add}>Add artifact</Button>
          <Button color="#ffffff" textColor="#777777" border="1px solid #cccccc" onClick={this.close}>Cancel</Button>
        </Footer>
      </Modal>
    );
  }
}

const mapStateToProps = ({ artifacts }) => ({
  artifacts: artifacts.artifacts,
  loading: artifacts.loading,
});

export default withRouter(connect(mapStateToProps, { fetchArtifacts })(BrowseArtifactsModal));
