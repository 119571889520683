import FileIcon from './images/file.png';
import FileDarkIcon from './images/fileDark.png';
import StartIcon from './images/start.png';
import BackIcon from './images/back.png';
import PlusIcon from './images/plus.png';
import BlueBackIcon from './images/blueBack.png';
import ExpandIcon from './images/expand.png';
import CollapseIcon from './images/collapse.png';
import CloseIcon from './images/close.png';
import CloseBigIcon from './images/closeBig.png';
import PlusDarkIcon from './images/plusDark.png';
import MinusDarkIcon from './images/minusDark.png';
import DeleteIcon from './images/delete.png';
import LeftArrowIcon from './images/leftArrow.png';
import RightArrowIcon from './images/rightArrow.png';
import SettingsIcon from './images/settings.png';
import EditWhiteIcon from './images/editWhite.png';
import DeleteWhiteIcon from './images/deleteWhite.png';
import CopyIcon from './images/copy.png';
import WhitePenIcon from './images/whitePen.png';
import UpArrowIcon from './images/upArrow.png';
import CollapseDarkIcon from './images/collapseDark.png';
import CollapseWhiteIcon from './images/collapseWhite.png';
import ExpandDarkIcon from './images/expandDark.png';
import ExpandWhiteIcon from './images/expandWhite.png';
import PlusBlueIcon from './images/plusBlue.png';
import SearchIcon from './images/search.png';
import DownArrowIcon from './images/downArrow.png';
import MenuArrowDown from './images/menuArrowDown.png';
import LockDark from './images/lockDark.png';
import LockWhite from './images/lockWhite.png';
import LockOpenedDark from './images/lockOpenedDark.png';
import LockOpenedWhite from './images/lockOpenedWhite.png';

export const sizes = {
  lg: {
    padding: '15px 20px',
    fontSize: '16px',
    textTransformation: 'uppercase',
    fontWeight: 600,
    height: '40px',
    iconSize: '30px',
  },
  md: {
    padding: '8px 13px',
    fontSize: '12px',
    textTransformation: 'uppercase',
    fontWeight: 600,
    height: '35px',
    iconSize: '17px',
  },
  mdIcon: {
    padding: '8px 13px',
    fontSize: '12px',
    textTransformation: 'uppercase',
    fontWeight: 600,
    height: '35px',
    iconSize: '20px',
  },
  sm: {
    padding: '5px 10px',
    fontSize: '12px',
    textTransformation: 'none',
    fontWeight: 500,
    height: '30px',
    iconSize: '15px',
  },
};

export const iconTypes = {
  file: FileIcon,
  fileDark: FileDarkIcon,
  start: StartIcon,
  back: BackIcon,
  blueBack: BlueBackIcon,
  plus: PlusIcon,
  expand: ExpandIcon,
  collapse: CollapseIcon,
  close: CloseIcon,
  closeBig: CloseBigIcon,
  plusDark: PlusDarkIcon,
  minusDark: MinusDarkIcon,
  delete: DeleteIcon,
  leftArrow: LeftArrowIcon,
  rightArrow: RightArrowIcon,
  settings: SettingsIcon,
  deleteWhite: DeleteWhiteIcon,
  editWhite: EditWhiteIcon,
  copy: CopyIcon,
  whitePen: WhitePenIcon,
  upArrow: UpArrowIcon,
  collapseDark: CollapseDarkIcon,
  collapseWhite: CollapseWhiteIcon,
  expandDark: ExpandDarkIcon,
  expandWhite: ExpandWhiteIcon,
  plusBlue: PlusBlueIcon,
  search: SearchIcon,
  downArrow: DownArrowIcon,
  menuDropdown: MenuArrowDown,
  lockDark: LockDark,
  lockWhite: LockWhite,
  lockOpenedDark: LockOpenedDark,
  lockOpenedWhite: LockOpenedWhite,
};
