import styled from 'styled-components';

export const Navbar = styled.div`
  background-color: #54585B;
  display: flex;
  position: relative;
  z-index: 99;
  flex-direction: column;
  align-items: center;
  box-sizing: border-box;
  font-size: 14px;
  height: 100vh;
  width: 75px;
  padding-top: 5px;
`;

export const OutsideOverlay = styled.div`
  position: absolute;
  top: ${props => (props.isOpened ? 0 : '50%')};
  left: 0;
  z-index: 97;
  display: block;
  width: 100vw;
  height: ${props => (props.isOpened ? '100vh' : 0)};
  background-color: #000;
  opacity: ${props => (props.isOpened ? 0.3 : 0)};
`;

