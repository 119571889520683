import styled from 'styled-components';
import Title from 'components/Title';

const StyledTitle = styled(Title)`
  padding: 10px 15px;
  text-transform: none;
  background-color: ${({ theme }) => theme.primaryGrey};
`;

export default StyledTitle;
