import styled from 'styled-components';

export const Content = styled.div`
  padding: 20px;
  display: flex;
  flex-direction: column;
`;

export const Header = styled.div`
  height: 50px;
  padding: 10px 20px;
  width: 100%;
  display: flex;
  box-sizing: border-box;
  align-items: center;
  border-bottom: 1px solid ${({ theme }) => theme.primaryGrey};
  font-size: 16px;
  color: ${({ theme }) => theme.darkGrey};
  text-transform: uppercase;
  background: ${({ theme }) => theme.lightGrey};
`;

export const LevelsList = styled.div`
  display: flex;
  height: 200px;
  margin-bottom: 20px;
  flex-flow: row wrap;
  align-items: center;
`;

export const Title = styled.div`
  font-weight: 600;
  line-height: 1.5;

  div {
    font-weight: 100;
  }
`;
