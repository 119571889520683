import React from 'react';
import PropTypes from 'prop-types';

import Dropdown from 'components/Dropdown';
import { Container, TasksTitle, Title, SubTitle, RateItem, Round, TitleWrapper } from './styled';

const Header = props => (
  <Container>
    <TasksTitle>
      <Dropdown
        type="common"
        list={props.separators}
        onDropdownSelect={props.onDropdownItem}
        index={0}
      >
        <TitleWrapper>
          <Title>DOMAINS/LEARNING PROGRESSIONS/</Title>
          <SubTitle>SKILLS, KNOWLEDGE, OR BEHAVIORS</SubTitle>
        </TitleWrapper>
      </Dropdown>
    </TasksTitle>
    {
      props.list.map(level => (
        <RateItem key={level.label} color={level.label}>
          <Round>{level.label}</Round>
        </RateItem>
      ))
    }
  </Container>
);

Header.propTypes = {
  list: PropTypes.array.isRequired,
  separators: PropTypes.arrayOf(PropTypes.object).isRequired,
  onDropdownItem: PropTypes.func.isRequired,
};

export default Header;
