import React from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router';

import BaseFooter from 'components/BaseFooter';
import { StyledButton, StyledLink } from './styled';

const getMode = (location) => {
  switch (location.pathname.match(/open|closed|notes|artifacts/)[0]) {
    case 'open':
      return { path: 'create', type: 'activity' };
    case 'closed':
      return { path: 'create', type: 'activity' };
    case 'notes':
      return { path: 'notes/create', type: 'note' };
    case 'artifacts':
      return { path: 'list/artifacts/create', type: 'artifact' };
    default:
      return {};
  }
};

const Footer = ({ location }) => {
  const mode = getMode(location);

  return (
    <BaseFooter>
      <StyledLink to={`../${mode.path}`}>
        <StyledButton tabIndex={-1} icon="plus" color="#70a823">Add {mode.type}</StyledButton>
      </StyledLink>
    </BaseFooter>
  );
};

Footer.propTypes = {
  location: PropTypes.object.isRequired,
};

export default withRouter(Footer);
