import styled from 'styled-components';

import Logo from './images/logo.png';

export const Container = styled.div`
  background-color: #24282e;
  height: 100%;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  flex: 1;
`;

export const LogoImage = styled.div`
  width: 100%;
  height: 145px;
  max-width: 800px;
  background-color: #ffe124;
  background: url(${Logo}) no-repeat;
  background-position: left bottom;
  background-size: 90%;
`;

export const Row = styled.div`
  display: flex;
  height: 30%;
`;

export const Text = styled.div`
  color: ${({ theme }) => theme.primaryGrey};
  display: flex;
  flex-direction: column;
  height: 30%;

  h3 {
    font-weight: 500;
    font-size: 2.25rem;
    margin: 1rem 0;
  }

  h4 {
    font-size: 2rem;
    margin: 5px 0;
  }

  p {
    font-size: 1.7rem;
    margin: 5px 0;
  }
`;

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-self: center;
  width: 90%;
  height: 90%;
  max-width: 1000px;
`;
