import moment from 'moment';
import http from '../../../http';
import getFileBase64 from 'services/getFileBase64';

export const getArtifact = (grades, id, student) => {
  const artifacts = grades.filter(item => (
    (+item.assessment_items_id === +id) && (+item.studentsEnrollments.student_id === +student.id)
  ));

  return artifacts[0];
};

function formatComments(comments) {
  if (!comments) {
    return [];
  }

  return comments.map(item => ({
    ...item,
    text: item.original_file_name,
    imageUrl: item.s3_file_name,
    author: '',
    date: '',
    time: '',
  }));
}

export const formatArtifacts = (artifact) => {
  if (!artifact) {
    return {};
  }

  return {
    comment: artifact.comment,
    createdAt: moment(+(`${artifact.created_at}000`)).format('M/D/YYYY - h:mm A'),
    createdBy: `
      ${artifact.userRelatedByCreatedBy.first_name}
      ${artifact.userRelatedByCreatedBy.last_name}
    `,
    comments: formatComments(artifact.dataCollectionsStudentScoreArtifacts),
  };
};

export const calculateStudentsTableDates = (grades, studentId) => {
  const reduced = grades.reduce((filtered, option) => {
    if (+option.studentsEnrollments.student_id === +studentId) {
      filtered.push(option.created_at);
    }
    return filtered;
  }, []);

  const last = moment(+(`${Math.max(...reduced)}000`));

  const thisWeek = reduced.filter((item) => {
    const formattedDate = moment(+(`${item}000`)).startOf('day');

    // if your want the sunday to be the week start, change 'isoweek' to 'week'
    const weekStart = moment(+(`${moment().unix()}000`)).startOf('isoweek');

    return formattedDate.isAfter(weekStart);
  }).length;

  return {
    thisWeek,
    last: last.isValid() ? last.format('M/D/YYYY') : '-',
  };
};

export const getTaskLastScoredDate = (grades, activityTasks) => {
  const reduced = grades.reduce((result, grade) => {
    activityTasks.forEach((task) => {
      if (+grade.assessment_items_id === +task.id) {
        result.push(grade.created_at);
      }
    });
    return result;
  }, []);

  if (reduced.length) {
    return moment(+(`${Math.max(...reduced)}000`)).format('M/D/YYYY');
  }
  return '';
};

// This function takes ids of selected artifacts from global artifacts list and fetch this artifacts.
const fetchSelectedArtifacts = (arrayOfSelectedArtifacts, collectionId) => {
  const promises = [];

  arrayOfSelectedArtifacts.forEach((artifact) => {
    promises.push(http.get(`/en/api/v2/collections/${collectionId}/artifacts/${artifact.id}/download.json?link=true`)
      .then(response => ({
        data: response.data,
        name: artifact.original_file_name,
      }))
      .catch((error) => {
        throw error;
      }));
  });

  return Promise.all(promises);
};

export const postByScoreId = async (collectionId, gradeId, modalInput) => {
  const { images, savedImages } = modalInput;

  if (images.length || savedImages.length) {
    const urlArtifact = `en/api/v1/user/collections/${collectionId}/grades/${gradeId}/artifacts.json`;
    const newImages = await fetchSelectedArtifacts(savedImages, collectionId);

    const dataArray = await Promise.all(images.map(i => getFileBase64(i.file)));
    const formattedNewImages = images.map((image, i) => ({
      name: image.file.name,
      data: dataArray[i].split(',')[1],
    }));

    const imagesForUpload = newImages.concat(formattedNewImages);

    return http.post(urlArtifact, imagesForUpload)
      .catch((error) => {
        throw error;
      });
  }

  return Promise.resolve();
};
