import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  padding: 12px 24px;
`;

export const Lable = styled.div`
  font-size: 17px;
  margin-top: 6px;
  margin-bottom: 12px;
`;

export const ColorList = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
`;
