import styled from 'styled-components';
import Button from 'components/Button';

export const ButtonsColumn = styled.div`
  display: flex;
  flex-direction: column;
  flex: none;
  padding: 15px;
`;

export const Column = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  overflow-y: scroll;
`;

export const Container = styled.div`
  display: flex;
  flex-direction: row;
  border-bottom: 2px solid ${({ theme }) => theme.primaryOrange};
  max-height: ${({ detailOpened }) => (detailOpened ? '245px' : 0)};
  overflow: hidden;
  transition: max-height 0.4s ease-out;
`;

export const StyledButton = styled(Button)`
  margin-bottom: 10px;
`;

export const Text = styled.p`
  margin: 15px;
  margin-bottom: 30px;
  min-width: 0;
  word-wrap: break-word;
`;

export const TextInput = styled.textarea`
  color: ${({ theme }) => theme.darkGrey};
  border: 1px solid ${({ theme }) => theme.primaryGrey};
  border-radius: 4px;
  padding: 10px;
  resize: none;
  margin: 15px;
  flex: none;
`;
