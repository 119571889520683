import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  background-color: #333333;
  padding: 18px 24px;
`;

export const ButtonGroup = styled.div`
  display: flex;
`;

export const ButtonContainer = styled.div`
  margin-right: 12px;

  button {
    min-width: 100px;
  }

  &:last-child {
    margin-right: 0;
  }
`;
