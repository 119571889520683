import styled from 'styled-components';
import { sizes, iconTypes } from 'configs/btnConfigs';

export const Container = styled.button`
  background-color: ${props => (props.color ? props.color : '#70a823')};
  border-radius: 3px;
  display: flex;
  flex-direction: ${props => (props.iconAlign === 'right' ? 'row' : 'row-reverse')};
  align-items: center;
  justify-content: center;
  flex-shrink: 0;
  color: ${props => (props.textColor)};
  padding: ${props => (props.color !== 'transparent' ? sizes[props.size].padding : '0 0')};
  font-weight: ${props => (sizes[props.size].fontWeight)};
  text-transform: ${props => (sizes[props.size].textTransformation)};
  font-size: ${props => (sizes[props.size].fontSize)};
  cursor:pointer;
  border: ${props => (props.border ? props.border : 'none')};
  height: ${props => (sizes[props.size].height)};
  transition: 0.1s linear;
  line-height: 100%;

  &:disabled {
    opacity: 0.5;
    cursor: default;
  }

  &:hover {
    box-shadow: ${props => (props.color === 'transparent' ? 'none' : 'inset 1px 1px 50px rgba(100, 100, 100, 0.3);')};
  }

  &:focus {
    outline-style: solid;
    outline-width: 2px;
  }

  &:active {
    transform: scale(0.98);
  }
`;

export const Icon = styled.span`
  display: inline-block;
  box-sizing: border-box;
  flex-shrink: 0;
  width: ${props => (sizes[props.iconSize].iconSize ? sizes[props.iconSize].iconSize : '16px')};
  height: ${props => (sizes[props.iconSize].iconSize ? sizes[props.iconSize].iconSize : '16px')};
  float: ${props => (props.iconAlign)};
  margin-left: ${props => (props.iconAlign === 'right' && props.content ? '10px' : '0')};
  margin-right: ${props => (props.iconAlign === 'left' && props.content ? '10px' : '0')};
  background: url(${props => (iconTypes[props.icon])});
  background-position: 50%;
  background-repeat: no-repeat;
  background-size: contain;
`;

export const Text = styled.span`
  display: flex;
  width: 100%;
  justify-content: center;
  text-decoration: none !important;
`;
