import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import update from 'immutability-helper';

import FullScreenImage from 'components/FullScreenImage';
import Student from 'components/Student';
import { Artifacts, Icon, Row, Score, StudentWrapper, StyledButton, Wrapper } from './styled';

export default class Item extends PureComponent {
  static propTypes = {
    student: PropTypes.object.isRequired,
  }

  constructor(props) {
    super(props);

    this.toogleStudent = this.toogleStudent.bind(this);
  }

  state = {
    expanded: false,
  }

  onDeleteClick = (id) => {
    console.log('Delete artifact', id);
  };

  handleKeyDown = (event) => {
    const keyCodes = {
      up: 38,
      down: 40,
    };

    const prevParentStudentItem = event.target.parentElement.parentElement.previousElementSibling;
    const nextParentStudentItem = event.target.parentElement.parentElement.nextElementSibling;
    const prevStudentItem = prevParentStudentItem ? prevParentStudentItem.firstElementChild.firstElementChild : '';
    const nextStudentItem = nextParentStudentItem ? nextParentStudentItem.firstElementChild.firstElementChild : '';

    if (event.keyCode === keyCodes.down && nextStudentItem) {
      event.preventDefault();
      nextStudentItem.focus();
    }

    if (event.keyCode === keyCodes.up && prevStudentItem) {
      event.preventDefault();
      prevStudentItem.focus();
    }
  };

  toogleStudent() {
    this.setState(update(this.state, {
      expanded: { $apply: expanded => !expanded },
    }));
  }

  render() {
    const { expanded } = this.state;
    const {
      id,
      name: {
        firstname,
        lastname,
        middlename,
        gender,
      },
      artifacts,
    } = this.props.student;

    return (
      <div role="treeitem">
        <label htmlFor={`student${id}`}>
          <Row
            tabIndex={0}
            aria-label={`${lastname} ${firstname} ${middlename} ${artifacts.length} artifacts`}
            clickable={artifacts.length}
            studentExpanded={expanded}
            onKeyDown={this.handleKeyDown}
          >
            <StudentWrapper>
              <Student firstname={firstname} lastname={lastname} middlename={middlename} gender={gender} />
            </StudentWrapper>
            {
              artifacts.length ?
                <Score>
                  <Icon
                    tabIndex={-1}
                    iconAlign="right"
                    size="sm"
                    icon="fileDark"
                    color="transparent"
                    textColor="#666666"
                    onClick={this.toogleStudent}
                  >
                    {artifacts.length}
                  </Icon>
                  <StyledButton
                    aria-label={expanded ? 'collapse artifacts' : 'expand artifacts'}
                    id={`student${id}`}
                    size="sm"
                    icon={expanded ? 'collapseDark' : 'expandDark'}
                    color="transparent"
                    onClick={this.toogleStudent}
                  />
                </Score> :
                null
            }
          </Row>
        </label>
        <Artifacts studentExpanded={expanded}>
          {artifacts.map(artifact => (
            <Wrapper key={artifact.id}>
              <FullScreenImage
                url={artifact.image}
                info={artifact}
                onDelete={() => this.onDeleteClick(artifact.id)}
              />
            </Wrapper>
          ))}
        </Artifacts>
      </div>
    );
  }
}
