import styled from 'styled-components';
import Button from 'components/Button';

export const CheckboxContainer = styled.div`
  display: flex;
  flex-shrink: 0;
  align-items: center;
  width: 45px;
  height: 40px;
`;

export const Container = styled.li`
  display: flex;
  flex-direction: row;
  list-style-type: none;
  border-bottom: 1px solid ${({ theme }) => theme.primaryGrey};
  padding: 15px;
  cursor: pointer;
`;

export const Description = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  align-self: center;
  min-width: 0;
  word-wrap: break-word;
  color: ${({ theme }) => theme.darkGrey};
`;

export const Label = styled.div`
  font-weight: 600;
`;

export const ObservationDate = styled.div`
  font-size: 14px;
`;

export const StyledButton = styled(Button)`
  font-weight: 600;
  font-size: 14px;
`;

export const Text = styled.div`
  min-width: 0;
  word-wrap: break-word;
  line-height: 1.25;
`;
