import styled from 'styled-components';

export const Toast = styled.div`
  position: fixed;
  bottom: 0;
  width: 100%;
  height: 3em;
  will-change: transform;
  transform: translate3d(0, ${props => (props.open ? '0' : '3em')}, 0);
  transition: transform .2s linear;
  background-color: #cc0000;
  z-index: 10000;
  line-height: 3em;
  color: white;
`;

export const Text = styled.span`
  margin-left: 3em;
  font-weight: 500;
`;
