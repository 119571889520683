import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';

import { fetchNotes } from 'redux/modules/notes';
import { getVisibleNotes } from 'redux/modules/notes/selectors';
import Preloader from 'components/Preloader';
import Item from './components/Item';
import { Container, StyledTitle } from './styled';

class NotesList extends PureComponent {
  static defaultProps = {
    clickable: false,
    onNoteSelect: () => {},
    search: '',
    selected: null,
  }

  static propTypes = {
    clickable: PropTypes.bool,
    fetchNotes: PropTypes.func.isRequired,
    loading: PropTypes.bool.isRequired,
    match: PropTypes.object.isRequired,
    notes: PropTypes.arrayOf(PropTypes.object).isRequired,
    onNoteSelect: PropTypes.func,
    search: PropTypes.string, // eslint-disable-line react/no-unused-prop-types
    selected: PropTypes.array,
  }

  componentDidMount() {
    this.props.fetchNotes(this.props.match.params.collectionId);
  }

  render() {
    const {
      clickable,
      loading,
      notes,
      onNoteSelect,
      selected,
    } = this.props;

    if (loading) {
      return (
        <Container>
          <Preloader loading={loading} />
        </Container>
      );
    }

    return (
      <Container>
        {notes.length ?
          notes.map(note => (
            <Item
              key={note.id}
              note={note}
              clickable={clickable}
              onNoteSelect={onNoteSelect}
              selected={selected}
            />
          )) :
          <StyledTitle text="There are no notes yet" />
        }
      </Container>
    );
  }
}

const mapStateToProps = ({ notes }, { search }) => ({
  notes: getVisibleNotes(notes, search),
  loading: notes.loading,
});

export default withRouter(connect(mapStateToProps, { fetchNotes })(NotesList));
