import styled from 'styled-components';

export const Container = styled.div`
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  overflow: hidden;
`;

export const HeaderContainer = styled.div`
  box-sizing: border-box;
  display: flex;
  height: 70px;
  width: 100%;
  justify-content: space-around;
  align-items: center;
  color: #666666;
  font-size: 14px;
  font-weight: 700;
  border: 1px solid #ccc;
  border-left: 0;
`;

export const Header = styled.div`
  display: flex;
  width: 100%;
  height: 100%;
`;

export const ScrollDiv = styled.div`
  width: 17px;
  height: 100%;
  background-color: #f1f1f1;
`;

export const Content = styled.div`
  box-sizing: border-box;
  display: flex;
  height: ${props => (props.isExpanded ? 'calc(100% - 70px)' : '100%')};
  width: 100%;
  overflow-y: scroll;
  flex-direction: column;
`;

export const Column = styled.div`
  box-sizing: border-box;
  display: flex;
  width: ${props => (props.columnWidth ? `${props.columnWidth}px` : '100%')};
  max-width: ${props => (props.primaryWidth ? props.primaryWidth : 'wrong')};
  flex-shrink: ${props => (props.columnWidth || props.primaryWidth ? 0 : 1)};
  height: 100%;
  align-items: center;
  justify-content: ${props => (props.primaryWidth ? 'flex-start' : 'center')};
  padding-left: ${props => (props.primaryWidth ? '15px' : '0')};
  background-color: #f1f1f1;
  border-right: 1px solid #ccc;
  text-align: center;

  &:last-of-type {
    border-right: none;
  }
`;
