import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';

import { Container, Splash } from './styled';
import LoginPage from 'modules/auth';
import Main from 'modules/main';
import OnlineStatus from 'components/OnlineStatus';
import PrivateRoute from './components/PrivateRoute';

import { checkAuth } from 'redux/modules/auth';

class AppRouter extends PureComponent {
  static propTypes = {
    auth: PropTypes.object.isRequired,
    actions: PropTypes.shape({
      checkAuth: PropTypes.func.isRequired,
    }).isRequired,
  }

  componentDidMount() {
    this.props.actions.checkAuth();
  }

  render() {
    if (!this.props.auth.checked) {
      return (
        <Splash />
      );
    }

    return (
      <Router>
        <Container>
          <OnlineStatus />
          <Switch>
            <Route exact path="/login" component={LoginPage} />
            <PrivateRoute
              path="/"
              component={Main}
              authenticated={this.props.auth.authenticated}
            />
          </Switch>
        </Container>
      </Router>
    );
  }
}

const mapStateToProps = ({ auth }) => ({
  auth,
});

const mapDispatchToProps = dispatch => ({
  actions: bindActionCreators({ checkAuth }, dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(AppRouter);
