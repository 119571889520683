import http from '../../../http';

const AUTHENTICATE_REQUEST = 'rfc/auth/AUTHENTICATE_REQUEST';
const AUTHENTICATE_SUCCESS = 'rfc/auth/AUTHENTICATE_SUCCESS';
const AUTHENTICATE_FAILURE = 'rfc/auth/AUTHENTICATE_FAILURE';

const AUTHENTICATE_CHECK_SUCCESS = 'rfc/auth/CHECK_SUCCESS';
const AUTHENTICATE_CHECK_FAILURE = 'rfc/auth/CHECK_FAILURE';

const LOGOUT_REQUEST = 'rfc/auth/LOGOUT_REQUEST';
const LOGOUT_SUCCESS = 'rfc/auth/LOGOUT_SUCCESS';
const LOGOUT_FAILURE = 'rfc/auth/LOGOUT_FAILURE ';

const initialState = {
  authenticated: false,
  error: '',
  user: null,
  loading: false,
  checked: false,
};

export default function reducer(state = initialState, action = {}) {
  switch (action.type) {
    case AUTHENTICATE_REQUEST:
      return { ...state, ...{ authenticated: false, loading: true } };
    case AUTHENTICATE_SUCCESS:
      return { ...state, ...{ authenticated: true, user: action.payload, loading: false } };
    case AUTHENTICATE_FAILURE:
      return { ...state, ...{ authenticated: false, error: action.payload, loading: false } };
    case AUTHENTICATE_CHECK_SUCCESS:
      return {
        ...state,
        ...{
          authenticated: true,
          user: action.payload,
          loading: false,
          checked: true,
        },
      };
    case AUTHENTICATE_CHECK_FAILURE:
      return { ...state, ...{ authenticated: false, checked: true, loading: false } };
    case LOGOUT_SUCCESS:
      return { ...initialState, checked: true };
    default:
      return state;
  }
}

const requestAuthenticate = () => ({ type: AUTHENTICATE_REQUEST });
const successAuthenticate = payload => ({ type: AUTHENTICATE_SUCCESS, payload });
const failedAuthenticate = payload => ({ type: AUTHENTICATE_FAILURE, payload });

export function authenticate(credentials) {
  return (dispatch) => {
    dispatch(requestAuthenticate());

    http({ method: 'get', url: '/login' })
      .then((response) => {
        const data = {
          ...credentials,
          _csrf_token: response.data.match(/(name="_csrf_token" value="(.*)")/)[2],
        };

        const formData = Object.keys(data).map(key => (
          `${encodeURIComponent(key)}=${encodeURIComponent(data[key])}`
        )).join('&');

        return http({
          method: 'post',
          url: '/login_check',
          data: formData,
        });
      })
      .then(() => http({
        method: 'get',
        url: 'en/api/v1/user/profile.json',
      }))
      .then((response) => {
        dispatch(successAuthenticate(response.data));
      })
      .catch(() => {
        dispatch(failedAuthenticate('Invalid Credentials. Please Try Again'));
      });
  };
}

const requestLogout = () => ({ type: LOGOUT_REQUEST });
const successLogout = () => ({ type: LOGOUT_SUCCESS });
const failedLogout = () => ({ type: LOGOUT_FAILURE });

export const logout = () => (dispatch) => {
  dispatch(requestLogout());
  http.get('logout')
    .then(() => dispatch(successLogout()))
    .catch(() => dispatch(failedLogout()));
};

const successAuthenticateCheck = payload => ({ type: AUTHENTICATE_CHECK_SUCCESS, payload });
const failedAuthenticateCheck = () => ({ type: AUTHENTICATE_CHECK_FAILURE });

export const checkAuth = () => (dispatch) => {
  dispatch(requestAuthenticate());
  http.get('en/api/v1/user/profile.json')
    .then(response => dispatch(successAuthenticateCheck(response.data)))
    .catch(() => dispatch(failedAuthenticateCheck()));
};
