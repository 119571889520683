import styled from 'styled-components';

const Container = styled.div`
  display: flex;
  color: ${props => (props.type === 'header' ? '#fff' : '#333')};
  background: ${props => (props.active ? 'rgba(0,0,0,0.3)' : '')};
  cursor: ${props => (props.active ? 'default' : 'pointer')};
  padding: 16px;
  text-decoration: none;
  text-transform: uppercase;
  transition: 0.25s;
  border-bottom: ${props => (props.type === 'header' ? 'none' : '1px solid #ccc')};
  pointer-events: ${props => (props.active ? 'none' : '')};

  &:focus {
    background: ${props => (props.active ? '' : 'rgba(0,0,0,0.1)')};
  }

  &:hover {
    background: ${props => (props.active ? '' : 'rgba(0,0,0,0.1)')};
  }
`;

export default Container;
