import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import Dropzone from 'react-dropzone';
import update from 'immutability-helper';

import Button from 'components/Button';
import Modal from 'components/Modal';
import AttachmentsPreview from './components/AttachmentsPreview';
import { Container, Footer, Header, DropzoneWrapper, Comment, TextArea, Error } from './styled';

class ArtifactModal extends PureComponent {
  static propTypes = {
    isOpen: PropTypes.bool.isRequired,
    onRequestClose: PropTypes.func.isRequired,
    uploadArtifact: PropTypes.func.isRequired,
  }

  constructor(props) {
    super(props);

    this.save = this.save.bind(this);
    this.onDrop = this.onDrop.bind(this);
    this.onFileRemove = this.onFileRemove.bind(this);
    this.onTextareaChange = this.onTextareaChange.bind(this);
  }

  state = {
    attachments: [],
    comment: '',
  }

  componentWillReceiveProps(nextProps) {
    if (this.props.isOpen && !nextProps.isOpen) {
      this.setState({ attachments: [], comment: '' });
    }
  }

  onDrop(files) {
    this.setState({ attachments: [files[0]] });
  }

  onTextareaChange(event) {
    this.setState({ comment: event.target.value });
  }

  onFileRemove(index) {
    this.setState({
      attachments: update(this.state.attachments, { $splice: [[index, 1]] }),
    });
  }

  save() {
    const { comment, attachments } = this.state;

    this.props.uploadArtifact(attachments[0], comment);
    this.props.onRequestClose();
    this.setState({ comment: '', attachments: [] });
  }

  render() {
    const { attachments, comment } = this.state;

    return (
      <Modal headerTitle={(<Header>Add activity artifact</Header>)} {...this.props}>
        <Container>
          <Comment>
            Comment (Optional)
            <TextArea
              aria-label="Input comment area"
              value={comment}
              onChange={this.onTextareaChange}
            />
          </Comment>
          <DropzoneWrapper>
            Add Artifact
            <Dropzone aria-label="Upload files" tabIndex={-1} role="region" onDrop={this.onDrop}>
              <Button icon="file" tabIndex={0}>Browse</Button>
            </Dropzone>
          </DropzoneWrapper>
          <AttachmentsPreview
            files={attachments}
            onRemove={this.onFileRemove}
          />
          {!attachments.length && <Error>Artifact Required*</Error>}
          <Footer>
            <Button
              disabled={!attachments.length}
              color="#70a823"
              onClick={this.save}
            >
              Save
            </Button>
            <Button
              color="#ffffff"
              textColor="#cccccc"
              border="1px solid #cccccc"
              onClick={this.props.onRequestClose}
            >
              Cancel
            </Button>
          </Footer>
        </Container>
      </Modal>
    );
  }
}

export default ArtifactModal;
