import React from 'react';
import PropTypes from 'prop-types';
import findIndex from 'lodash/findIndex';

import StyledTitle from './styled';

const Separator = ({ text, ...rest }) => {
  const handleKeyDown = (event) => {
    const keyCodes = {
      tab: 9,
      up: 38,
      down: 40,
    };

    const item = event.target;
    const prevTaskItem = item.previousElementSibling || '';
    const nextTaskItem = item.nextElementSibling || '';

    if (event.keyCode === keyCodes.down && nextTaskItem) {
      event.preventDefault();
      nextTaskItem.focus();
    }

    if (event.keyCode === keyCodes.up && prevTaskItem) {
      event.preventDefault();
      prevTaskItem.focus();
    }

    if (event.keyCode === keyCodes.tab) {
      event.preventDefault();
      const separators = document.getElementsByName('separator-activity-detail');
      const indexItem = findIndex(separators, separator => separator.innerText === item.innerText);
      if (separators[indexItem + 1]) {
        separators[indexItem + 1].focus();
      } else {
        document.getElementById('browse-artifacts-button').focus();
      }
    }

    if (event.shiftKey && event.keyCode === keyCodes.tab) {
      event.preventDefault();
      const separators = document.getElementsByName('separator-activity-detail');
      const indexItem = findIndex(separators, separator => separator.innerText === item.innerText);
      if (separators[indexItem - 1]) {
        separators[indexItem - 1].focus();
      } else {
        document.getElementById('tasks-list').focus();
      }
    }
  };

  return (
    <StyledTitle
      tabIndex={0}
      aria-label={text}
      text={text}
      name="separator-activity-detail"
      onKeyDown={handleKeyDown}
      {...rest}
    />
  );
};

Separator.propTypes = {
  text: PropTypes.string.isRequired,
};

export default Separator;
