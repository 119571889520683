import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';

import { downloadArtifactFromHistory, getBaseURL } from '../../http';
import Button from 'components/Button';
import withKeyDown from 'components/withKeyDown';
import {
  Collapsed,
  Container,
  Controls,
  Description,
  DownloadHelp,
  Expanded,
  FullImage,
  ImageContainer,
  Img,
  Overlay,
  Toggle,
} from './styled';

import noImage from './images/image-placeholder.png';

const CollapsedWithKeyDown = withKeyDown(Collapsed, 'Both');
const ImageWithKeyDown = withKeyDown(FullImage, 'Both');
const OverlayWithKeyDown = withKeyDown(Overlay, 'Both');

class FullScreenImage extends PureComponent {
  static defaultProps = {
    disabled: false,
    info: {},
    maxWidth: 55,
    onDelete: null,
    onEdit: null,
  }

  static propTypes = {
    disabled: PropTypes.bool,
    info: PropTypes.object,
    match: PropTypes.object.isRequired,
    maxWidth: PropTypes.number,
    onDelete: PropTypes.func,
    onEdit: PropTypes.func,
  }

  constructor(props) {
    super(props);

    this.isExist = true;
    this.loadingSuccess = this.loadingSuccess.bind(this);
    this.downloadArtifact = this.downloadArtifact.bind(this);
    this.close = this.close.bind(this);
    this.open = this.open.bind(this);
  }

  state = {
    isExpanded: false,
    url: noImage,
  }

  componentDidMount() {
    const {
      info: {
        id: artifactId,
        data_collections_student_score_id: scoreId,
      },
      match: { params: { collectionId } },
    } = this.props;

    const img = new Image();
    img.src = `${getBaseURL()}/en/api/v1/user/collections/${collectionId}/grades/${scoreId}/artifacts/${artifactId}/download.json`;
    img.onload = this.loadingSuccess;
  }

  componentWillUnmount() {
    this.isExist = false;
  }

  loadingSuccess() {
    if (this.isExist) {
      const {
        info: {
          id: artifactId,
          data_collections_student_score_id: scoreId,
        },
        match: { params: { collectionId } },
      } = this.props;

      this.setState({
        url: `${getBaseURL()}/en/api/v1/user/collections/${collectionId}/grades/${scoreId}/artifacts/${artifactId}/download.json`,
      });
    }
  }

  downloadArtifact() {
    const {
      info: {
        id: artifactId,
        data_collections_student_score_id: scoreId,
      },
      match: { params: { collectionId } },
    } = this.props;

    downloadArtifactFromHistory(collectionId, artifactId, scoreId);
  }

  close() {
    this.setState({ isExpanded: false });
  }

  open() {
    if (this.props.disabled) {
      return;
    }

    this.setState({ isExpanded: true });
  }

  render() {
    const { isExpanded, url } = this.state;
    const {
      disabled,
      info: { author, date, text },
      maxWidth,
      onDelete,
      onEdit,
    } = this.props;

    return (
      <Container >
        {
          <CollapsedWithKeyDown
            onClick={this.open}
            tabIndex={0}
            aria-label="full screen image"
            disabled={disabled}
          >
            <ImageContainer>
              <Img isExpanded={isExpanded} src={url} alt="comment" />
            </ImageContainer>
            <Toggle aria-label="full screen image" />
          </CollapsedWithKeyDown>
        }
        {isExpanded &&
          <Expanded maxWidth={maxWidth}>
            <OverlayWithKeyDown
              aria-label="close modal"
              tabIndex={0}
              onClick={this.close}
            />

            <ImageWithKeyDown src={url} alt="img" onClick={this.downloadArtifact} />
            <DownloadHelp>Click artifact to download*</DownloadHelp>
            <Description>
              <span>{author} {date}</span>
              <span>{text}</span>
            </Description>
            <Controls >
              <Button icon="closeBig" size="lg" color="transparent" onClick={this.close} />
              { /* this construction is used to hide buttons not supperted at current version */
                false &&
                  <div>
                    {onDelete && <Button icon="deleteWhite" size="lg" color="transparent" onClick={onDelete} />}
                    {onEdit && <Button icon="editWhite" size="lg" color="transparent" onClick={onEdit} />}
                  </div>
              }
            </Controls>
          </Expanded>
        }
      </Container>
    );
  }
}

export default withRouter(FullScreenImage);
