import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';

import { online, offline } from 'redux/modules/app';

import { Toast, Text } from './styled';

class OnlineStatus extends PureComponent {
  static propTypes = {
    online: PropTypes.bool.isRequired,
    actions: PropTypes.shape({
      online: PropTypes.func.isRequired,
      offline: PropTypes.func.isRequired,
    }).isRequired,
  }

  constructor() {
    super();

    this.online = this.online.bind(this);
    this.offline = this.offline.bind(this);
  }

  componentDidMount() {
    window.addEventListener('online', this.online);
    window.addEventListener('offline', this.offline);
  }

  componentWillUnmount() {
    window.removeEventListener('online', this.online);
    window.removeEventListener('offline', this.offline);
  }

  online() {
    this.props.actions.online();
  }

  offline() {
    this.props.actions.offline();
  }

  render() {
    return (
      <Toast open={!this.props.online}>
        <Text>You are offline. Please check you internet connection.</Text>
      </Toast>
    );
  }
}

const mapStateToProps = ({ app }) => ({
  online: app.online,
});

const mapDispatchToProps = dispatch => ({
  actions: bindActionCreators({ online, offline }, dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(OnlineStatus);
