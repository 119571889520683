import styled from 'styled-components';
import Title from 'components/Title';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  box-sizing: border-box;
  overflow-y: auto;
`;

export const StyledTitle = styled(Title)`
  padding: 10px 15px;
  text-transform: none;
  background-color: ${({ theme }) => theme.lightGrey};
  border-top: none;
`;
