import styled from 'styled-components';

export const Container = styled.div`
  padding: 12px 24px;
  display: flex;
  flex-direction: column;
`;

export const Label = styled.div`
  font-size: 17px;
  margin-top: 6px;
  margin-bottom: 12px;
`;

export const Error = styled.div`
  color: ${({ theme }) => theme.error};
  margin-top: 5px;
  font-size: initial;
`;
