import React, { Component } from 'react';
import PropTypes from 'prop-types';

import theme from 'theme';

import UploadButton from '../../components/UploadButton';

import {
  Container,
  TextWrapper,
  Wrapper,
  ButtonWrapper,
} from './styled';

class ScoreSheetsUpload extends Component {
  static propTypes = {
    history: PropTypes.object.isRequired,
  }

  constructor(props) {
    super(props);

    this.toScoreSheetHistory = this.toScoreSheetHistory.bind(this);
  }

  toScoreSheetHistory() {
    this.props.history.push('/scoresheets/history');
  }

  render() {
    return (
      <Container role="main" aria-label="Score Sheets Upload">
        <Wrapper>
          <TextWrapper>
            <p>
            Here is where you will take a photo of a student’s score sheet.<br />
            Please make sure to include the entire score sheet
            in the photo including all QR codes.
            </p>
          </TextWrapper>
          <ButtonWrapper>
            <UploadButton
              buttonText="Continue"
              history={this.props.history}
              backgroundColor={theme.primaryGreen}
              textColor={theme.primaryWhite}
              fontSize="20px"
              fontWeight={700}
            />
          </ButtonWrapper>
          {/* <LinkWithKeyDown
            role="link"
            aria-label="Browse score sheet history"
            tabIndex={0}
            onClick={this.toScoreSheetHistory}
          >
            Browse Score Sheet History
          </LinkWithKeyDown> */}
        </Wrapper>
      </Container>
    );
  }
}

export default ScoreSheetsUpload;
