import React from 'react';
import PropTypes from 'prop-types';

import { StyledButton, StyledNavLink } from './styled';

const Tab = ({ location, text, to }) => {
  const isActive = (match, currentLocation) => (new RegExp(to).test(currentLocation.pathname));
  const active = isActive(null, location);

  return (
    <StyledNavLink to={`./${to}`} isActive={isActive}>
      <StyledButton
        textColor={active ? '#666666' : '#ffffff'}
      >
        {text}
      </StyledButton>
    </StyledNavLink>
  );
};

Tab.propTypes = {
  location: PropTypes.object.isRequired,
  text: PropTypes.string.isRequired,
  to: PropTypes.string.isRequired,
};

export default Tab;
