import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import { editNote, fetchNotes } from 'redux/modules/notes';
import { getNote } from 'redux/modules/notes/selectors';
import Preloader from 'components/Preloader';
import CreateEditNote from 'modules/activities/components/CreateEditNote';

class EditNote extends PureComponent {
  static propTypes = {
    editNote: PropTypes.func.isRequired,
    fetchNotes: PropTypes.func.isRequired,
    loading: PropTypes.bool.isRequired,
    match: PropTypes.object.isRequired,
    note: PropTypes.object.isRequired,
    notes: PropTypes.array.isRequired,
  }

  constructor(props) {
    super(props);

    this.editNote = this.editNote.bind(this);
  }

  componentDidMount() {
    const { match, notes } = this.props;

    if (!notes.length) {
      this.props.fetchNotes(match.params.collectionId);
    }
  }

  editNote(note) {
    const { collectionId, noteId } = this.props.match.params;

    this.props.editNote(collectionId, noteId, note);
  }

  render() {
    const { loading, note } = this.props;

    if (loading) {
      return (
        <Preloader loading={loading} />
      );
    }

    return <CreateEditNote onRequestSave={this.editNote} note={note} />;
  }
}

const mapStateToProps = ({ notes }, { match }) => ({
  loading: notes.loading,
  note: getNote(notes, match.params.noteId),
  notes: notes.notes,
});

export default connect(mapStateToProps, { editNote, fetchNotes })(EditNote);
