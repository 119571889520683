import 'core-js/fn/array/includes';
import 'core-js/fn/array/values';
import 'core-js/fn/object/values';

import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import { ThemeProvider } from 'styled-components';

import 'normalize.css';
import './index.css';

import theme from './theme';
import AppRouter from './router';
import configureStore from './redux/configure-store';
import registerServiceWorker from './registerServiceWorker';

if (process.env.NODE_ENV !== 'production') {
  const a11y = require('react-a11y'); // eslint-disable-line
  a11y(React, { ReactDOM, includeSrcNode: true });
}

const store = configureStore();

ReactDOM.render(
  <ThemeProvider theme={theme}>
    <Provider store={store}>
      <AppRouter />
    </Provider>
  </ThemeProvider>,
  document.getElementById('root'),
);
registerServiceWorker();
