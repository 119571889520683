import React from 'react';
import PropTypes from 'prop-types';

import ListItem from './components/ListItem';
import { Container, StyledTitle } from './styled';

const text = 'There are no artifacts yet';

const ArtifactsList = ({
  artifacts,
  deletable,
  deleteArtifact,
  downloadable,
  onSelect,
  selected,
}) => (
  <Container>
    {artifacts.length ?
      artifacts.map(artifact => (
        <ListItem
          key={artifact.id}
          artifact={artifact}
          onDeleteArtifact={deleteArtifact}
          selected={selected}
          onSelect={onSelect}
          deletable={deletable}
          downloadable={downloadable}
        />
      )) :
      <StyledTitle tabIndex={0} aria-label={text} text={text} />
    }
  </Container>
);

ArtifactsList.defaultProps = {
  deleteArtifact: () => {},
  onSelect: () => {},
  selected: null,
  deletable: false,
  downloadable: false,
};

ArtifactsList.propTypes = {
  artifacts: PropTypes.array.isRequired,
  deleteArtifact: PropTypes.func,
  onSelect: PropTypes.func,
  selected: PropTypes.array,
  deletable: PropTypes.bool,
  downloadable: PropTypes.bool,
};

export default ArtifactsList;
