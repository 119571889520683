import styled from 'styled-components';

export const Container = styled.div`
  flex-direction: row;
  align-items: center;
  text-transform: uppercase;
  height: 70px;
  box-sizing: border-box;
  justify-content: space-between;
  flex-shrink: 0;
`;

export const MenuItems = styled.div`
  height: 100%;
  display: flex;
  flex: 1;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
`;

export const Wrapper = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
`;

export const ChildrenContainer = styled.div`
  display: flex;
  flex: 1;
  justify-content: flex-start;
  height: 100%;
`;

export const DropContainer = styled.div`
  display: flex;
  justify-content: flex-end;
  height: 100%;
`;

export const Chevron = styled.div`
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0 20px;
  border-right: 1px solid rgba(255,255,255,0.1);

  &:focus {
    position: relative;
    z-index: 99;
  }
`;

export const Arrow = styled.div`
  width: 0;
  height: 0;
  border-left: 6px solid transparent;
  border-right: 6px solid transparent;
  border-top: 6px solid #47b3e4;
`;
