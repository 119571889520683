import moment from 'moment';
import omit from 'lodash/omit';

export const formatActivity = activity => ({
  ...activity,
  end_date: moment(+(`${activity.end_date}000`)),
  students_amount: activity.students_enrollments.length,
});

export const formatArtifact = (artifact) => {
  const { first_name: first, last_name: last, middle_name: middle } = artifact.user_related_by_created_by;
  return ({
    ...artifact,
    author: `${first} ${last} ${middle || ''}`,
    date: moment(+(`${artifact.created_at}000`)).format('M/D/YYYY'),
    time: moment(+(`${artifact.created_at}000`)).format('h:mm A'),
  });
};

export const formatActivityForPost = (activity, mode) => ({
  ...omit(activity, ['id', 'students_amount', 'data_collections_activities_artifacts']),
  label: (mode === 'copy' ? `${activity.label} copy` : activity.label),
  end_date: moment(activity.end_date, 'M/D/YYYY').unix(),
  data_collections_sets_items: activity.data_collections_sets_items ?
    activity.data_collections_sets_items : [],
  students_enrollments: activity.students_enrollments ?
    activity.students_enrollments : [],
});

export const formDataForUpload = (file, comment) => {
  const data = new FormData();
  data.append('file_0', file);
  data.append('comment', comment);
  return data;
};

export const formActivitySetsAndStudents = (currentState) => {
  const { activity } = currentState;
  const { tasks } = currentState.tasks;
  const students = currentState.students.list;

  return {
    ...activity,
    data_collections_sets_items: activity.activity.data_collections_sets_items.reduce((res, taskId) => {
      const taskToActivity = tasks.find(task => +task.id === +taskId);
      return Object.assign(res, { [taskId]: { id: taskToActivity.id, priority: taskToActivity.priority } });
    }, {}),
    students_enrollments: activity.activity.students_enrollments.reduce((res, studentId) => {
      const studentToActivity = students.find(student => +student.student_enrollment_id === +studentId);

      return Object.assign(res, {
        [studentId]: {
          id: studentToActivity.student_enrollment_id,
          special_education: studentToActivity.special_education,
          student_id: studentToActivity.id,
          unique_id: studentToActivity.student_district_id,
        },
      });
    }, {}),
  };
};
