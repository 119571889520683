import keys from 'lodash/keys';
import isObject from 'lodash/isObject';
import difference from 'lodash/difference';
import transform from 'lodash/transform';

import { filterStudentsByGroup } from 'redux/modules/students/formatters';

const RATES = ['R', 'N', 'NE', 'A', 'B', 'C', 'D', '1', '2', '3', '4', '5'];

const filterStudents = (students, ratedStudents) => (
  students.filter(student => keys(ratedStudents).includes(student.id.toString()))
);

const formAssessmentRow = (assignment, overview, students) => {
  const data = RATES.map((rating) => {
    if (keys(overview[assignment.id]).includes(rating) || rating === 'R') {
      return {
        rate: rating === 'R' ? students.length : overview[assignment.id][rating].total_students,
        grade: rating,
        studentsWithThisGrade: rating !== 'R'
          ? filterStudents(students, overview[assignment.id][rating].students)
          : null,
      };
    }

    return {
      rate: 0,
      grade: rating,
    };
  });
  return data;
};

/* eslint-disable */
const formGroupTaskElement = (overview, students) => (assignment) => {
  if (assignment.separator) {
    return assignment.separator;
  }

  return {
    task: {
      title: assignment.taskClass,
      subtitle: assignment.label,
      taskId: assignment.id,
      priority: assignment.priority,
      order: assignment.datacollection_sets_items_label
    },
    rates: formAssessmentRow(assignment, overview, students),
    scoredStudents: 0,
    scoredStudentsId: [],
    studentsAmount: students.length,
  };
};

const setStudentsWithScoreInRow = (obj) => {
  if (isObject(obj)) {
    obj.rates.forEach((item) => {
      if (item.rate && item.grade !== 'R') {
        obj.rates[0].rate -= item.rate; // lint
        obj.scoredStudents += item.rate; // lint
        obj.scoredStudentsId.push(...item.studentsWithThisGrade);
      }
      if (item.grade === 'R') {
        item.studentsWithThisGrade = obj.scoredStudentsId; // lint
      }
    });
  }
};

const setStudentsWithoutScore = students => (obj) => {
  if (isObject(obj)) {
    obj.rates[0].studentsWithThisGrade = difference( // lint
      students,
      obj.rates[0].studentsWithThisGrade,
    );
  }
};

// This function takes api response for overview requiest
// and transforms all the grades and scales in response into upper case
const formatOverviewResponse = response => transform(response, (overview, assessedScales, taskId) => {
  overview[taskId] = transform(assessedScales, (assessment, info, scale) => {
    assessment[`${scale}`.toUpperCase()] = transform(info, (result, value, key) => {
      if (key === 'students') {
        result[key] = transform(value, (students, student, studentId) => {
          students[studentId] = { ...student, symbol: student.symbol.toUpperCase() };
        }, {});
      } else {
        result[key] = value;
      }
    }, {});
  }, {});
}, {});
/* eslint-enable */

const formatAssessmentsOverview = (assessments, students, response) => {
  const overview = formatOverviewResponse(response);
  const groupTasks = assessments.tasks.map(formGroupTaskElement(overview, students));
  groupTasks.forEach(setStudentsWithScoreInRow);
  groupTasks.forEach(setStudentsWithoutScore(students));

  return groupTasks;
};

const studentsRates = (selectedGroupId, selectedStudentsId) => (rate) => {
  const studentsWithThisGrade = rate.rate ?
    filterStudentsByGroup(
      rate.studentsWithThisGrade,
      selectedGroupId,
      selectedStudentsId,
    ) : [];

  return {
    grade: rate.grade,
    studentsWithThisGrade,
    rate: studentsWithThisGrade.length,
  };
};

export const selectStudentsFromGroup = (assessments, selectedStudentsId, selectedGroupId) => (
  assessments.map((item) => {
    if (!isObject(item)) {
      return item;
    }

    const scoredStudentsId = filterStudentsByGroup(item.scoredStudentsId, selectedGroupId, selectedStudentsId);
    return {
      scoredStudentsId,
      scoredStudents: scoredStudentsId.length,
      task: item.task,
      rates: item.rates.map(studentsRates(selectedGroupId, selectedStudentsId)),
      studentsAmount: selectedStudentsId.length,
    };
  })
);

export default formatAssessmentsOverview;
