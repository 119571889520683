import styled from 'styled-components';
import Button from 'components/Button';

export const StyledButton = styled(Button)`
  margin: 30px 0;
`;

export const Text = styled.p`
  font-size: initial;
  margin: 5px 0;
  min-width: 0;
  word-wrap: break-word;
`;
