import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  height: 70px;
  width: 100%;
  box-sizing: border-box;
  align-items: center;
  background-color: #fff;
  border-bottom: 1px solid #ccc;
`;

export const Info = styled.div`
  display: flex;
  align-items: center;
  box-sizing: border-box;
  height: 100%;
  min-width: 50%;
  padding: 5px 20px;
  color: #666;
  flex: 1;

  label {
    margin-right: 20px;
  }
`;

export const Clickable = styled.div`
  display: flex;
  align-items: center;
  flex: 1;
  height: 100%;
`;

export const Value = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 5.55%;
  height: 100%;
  box-sizing: border-box;
  border-left: 1px solid ${({ theme }) => (theme.primaryGrey)};
`;

export const ArtifactsButton = styled.div`
  button {
    width: 60px;
  }
`;

export const ColoredRound = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 30px;
  height: 30px;
  border-radius: 50%;
  border: ${props => (!props.isSelected ? '1px solid #ccc;' : 'none')};
  background-color: ${props => (props.isSelected ? props.theme.grades[props.grade] : '#f1f1f1')};
  color: ${({ theme }) => (theme.primaryWhite)};
  cursor: pointer;
  box-shadow: inset 0 0 4px #ccc;

  &:hover {
    background-color: ${props => (props.isSelected ? props.background : '#e6e6e6')};
  }
`;
