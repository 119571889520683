import React from 'react';
import PropTypes from 'prop-types';
import { Container, Item } from './styled';

const List = props => (
  <Container>
    {props.roster.map((item, index) => (
      <Item key={index}>
        <span>{item.lastname}, {item.firstname}</span>
      </Item>
    ))}
  </Container>
);

List.propTypes = {
  roster: PropTypes.arrayOf(PropTypes.shape({
    lastname: PropTypes.string,
    firstname: PropTypes.string,
  })).isRequired,
};

export default List;
