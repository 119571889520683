import styled from 'styled-components';

export const Container = styled.div`
  width: 100%;
  height: 100%;
  overflow-y: auto;
  display: flex;
  flex-direction: column;
`;

export const Header = styled.div`
  height: 70px;
  background: ${({ theme }) => (theme.lightGrey)};
  border-bottom: 1px solid #ccc;
  display: flex;
  align-items: center;
  padding-left: 25px;
  text-transform: uppercase;
  font-size: 14px;
  color: ${({ theme }) => (theme.darkGrey)};;
  font-weight: 600;
`;

