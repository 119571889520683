import React from 'react';
import PropTypes from 'prop-types';

import withKeyDown from 'components/withKeyDown';
import Container from './styled';

const ContainerWithKeyDown = withKeyDown(Container, 'Both');

const DroplistItem = ({
  type,
  item,
  onDropdownSelect,
  onDropdownKeySelect,
  selectedItemId,
}) => {
  const isActive = selectedItemId && +selectedItemId === item.id;

  return (
    <ContainerWithKeyDown
      key={item.id}
      role="button"
      tabIndex={0}
      type={type}
      active={isActive}
      onClick={() => { onDropdownKeySelect(); return onDropdownSelect(item); }}
    >
      {item.name}
    </ContainerWithKeyDown>
  );
};

DroplistItem.propTypes = {
  item: PropTypes.object.isRequired,
  type: PropTypes.string.isRequired,
  onDropdownSelect: PropTypes.func.isRequired,
  onDropdownKeySelect: PropTypes.func.isRequired,
  selectedItemId: PropTypes.string.isRequired,
};

export default DroplistItem;
