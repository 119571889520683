import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import { fetchActivities } from 'redux/modules/activities';
import makeGetVisibleActivities from 'redux/modules/activities/selectors';
import Preloader from 'components/Preloader';
import Table from 'components/Table';
import Container from './styled';

class ActivitiesTable extends PureComponent {
  static propTypes = {
    activities: PropTypes.arrayOf(PropTypes.object).isRequired,
    fetchActivities: PropTypes.func.isRequired,
    filter: PropTypes.oneOf(['OPEN', 'CLOSED']).isRequired, // eslint-disable-line react/no-unused-prop-types
    history: PropTypes.object.isRequired,
    loading: PropTypes.bool.isRequired,
    match: PropTypes.object.isRequired,
  }

  componentDidMount() {
    this.props.fetchActivities(this.props.match.params.collectionId);
  }

  onActivityClick(activity) {
    this.props.history.push(`../${activity.id}/detail`);
  }

  columns = [
    {
      title: 'Activity name',
      field: 'label',
      clickable: true,
      onClick: this.onActivityClick.bind(this),
      primaryWidth: '30%',
    },
    {
      title: 'Activity date',
      field: 'end_date',
    },
    {
      title: '# Students',
      field: 'students_amount',
    },
    {
      title: 'Last updated',
      field: 'updated',
    },
    {
      title: 'Last rated',
      field: 'scored',
    },
    {
      title: '# Tasks',
      field: 'tasks_amount',
    },
  ];

  render() {
    const { activities, loading } = this.props;

    if (loading) {
      return (
        <Container>
          <Preloader loading={loading} />
        </Container>
      );
    }

    return <Table columns={this.columns} data={activities} />;
  }
}

const makeMapStateToProps = () => {
  const getVisibleActivities = makeGetVisibleActivities();
  const mapStateToProps = ({ activities }, { filter }) => ({
    activities: getVisibleActivities(activities, filter),
    loading: activities.loading,
  });
  return mapStateToProps;
};

export default connect(makeMapStateToProps, { fetchActivities })(ActivitiesTable);
