import React from 'react';
import PropTypes from 'prop-types';

import Button from 'components/Button';
import Scale from 'components/Scale';

import {
  Container,
  Task,
  TaskTitle,
  TaskDescription,
  StyledLink,
  ScaleContainer,
} from './styled';

const FullDescription = ({ openModal, grades, description }) => (
  <Container>
    <Task>
      <TaskTitle>Description</TaskTitle>
      <TaskDescription><p>{description}</p></TaskDescription>
      <StyledLink>
        <Button onClick={openModal}>Resources/Materials</Button>
      </StyledLink>
    </Task>
    {grades[0] &&
      <ScaleContainer>
        <Scale grades={grades} />
      </ScaleContainer>
    }
  </Container>
);

FullDescription.propTypes = {
  openModal: PropTypes.func.isRequired,
  grades: PropTypes.array.isRequired,
  description: PropTypes.string.isRequired,
};

export default FullDescription;
