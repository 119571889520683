import styled from 'styled-components';
import Button from 'components/Button';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  overflow-y: hidden;
`;

export const Column = styled.div`
  display: flex;
  flex: 1;
  max-width: 50%;
  border-right: 1px solid ${({ theme }) => theme.primaryGrey};
`;

export const Row = styled.div`
  display: flex;
  flex: 1;
  overflow-y: hidden;
`;

export const StyledButton = styled(Button)`
  padding: 5px;
`;

