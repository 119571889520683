import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';

import Button from 'components/Button';
import withKeyDown from 'components/withKeyDown';
import CommentsModal from 'components/Modals/CommentsModal';
import ButtonRating from 'modules/students/pages/Profile/components/ButtonRating';

import {
  Container,
  TextBlock,
  Title,
  Category,
  ButtonsBlock,
  ArtifactsButton,
} from './styled';

const ContainerWithKeyDown = withKeyDown(Container, 'Both');

class GroupsItem extends Component {
  constructor(props) {
    super(props);

    this.closeModal = this.closeModal.bind(this);
    this.openModal = this.openModal.bind(this);
    this.toScore = this.toScore.bind(this);
    this.toHistory = this.toHistory.bind(this);
  }

  state = {
    isModalOpened: false,
  };

  closeModal() {
    this.setState({
      isModalOpened: false,
    });
  }

  openModal(event) {
    event.stopPropagation();
    event.nativeEvent.stopImmediatePropagation();

    this.setState({
      isModalOpened: true,
    });
  }

  toScore(event) {
    event.stopPropagation();
    event.nativeEvent.stopImmediatePropagation();
    const { groupId, history, taskId } = this.props;

    history.push(`./group/${groupId}/task/${taskId}/score`);
  }

  toHistory(event) {
    event.stopPropagation();
    event.nativeEvent.stopImmediatePropagation();

    this.props.history.push(`./task/${this.props.taskId}/history`);
  }

  render() {
    const { comments } = this.props;
    const isShowButton = comments.comment || (comments.comments && comments.comments.length);
    return (
      <ContainerWithKeyDown onClick={event => this.toHistory(event)}>
        <TextBlock>
          <Category>{this.props.category}</Category>
          <Title>{this.props.title}</Title>
        </TextBlock>
        <ButtonsBlock>
          {isShowButton ?
            <ArtifactsButton>
              <Button
                icon="file"
                size="md"
                iconAlign="right"
                aria-label="open task comments"
                onClick={(event => this.openModal(event))}
              >
                {+this.props.comments.comments.length ? this.props.comments.comments.length : ''}
              </Button>
            </ArtifactsButton>
            : null
          }
          <ButtonRating grade={this.props.level} aria-label="open task history">
            {this.props.level}
          </ButtonRating>
          <Button color="#70a823" onClick={event => this.toScore(event)} aria-label="open task rating">
            Rate
          </Button>
        </ButtonsBlock>
        <CommentsModal isOpen={this.state.isModalOpened} onRequestClose={this.closeModal} data={this.props.comments} />
      </ContainerWithKeyDown>
    );
  }
}

GroupsItem.propTypes = {
  title: PropTypes.string,
  category: PropTypes.string,
  comments: PropTypes.object,
  level: PropTypes.string,
  history: PropTypes.object.isRequired,
  taskId: PropTypes.number.isRequired,
  groupId: PropTypes.number.isRequired,
};

GroupsItem.defaultProps = {
  title: '',
  category: '',
  comments: {},
  level: '',
};

export default withRouter(GroupsItem);
