const formatGroupsList = list => list.map(item => ({
  ...item,
  studentsAmount: item.data_collections_student_groups_student_enrollments.length,
  studentsIds: item.data_collections_student_groups_student_enrollments.map(i => ({
    id: i.student_enrollment_id,
  })),
  color: item.color ? item.color.toLowerCase() : '',
  name: {
    name: item.label,
    color: item.color ? item.color.toLowerCase() : '',
    id: item.id,
  },
}));

export default formatGroupsList;
