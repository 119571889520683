const theme = {
  primaryBlue: '#036a9b',
  primaryLightBlue: '#3399ff',
  primaryDark: '#2C2F36',
  primaryGreen: '#70a823',
  primaryOrange: '#ff9933',
  primaryPurple: '#9933cc',
  primaryWhite: '#ffffff',
  primaryGrey: '#cccccc',
  primaryTurquoise: '#008080',
  lightGrey: '#f1f1f1',
  darkGrey: '#666666',
  error: '#d8000c',
  grades: {
    R: 'black',
    N: '#ca69a8',
    NE: '#f1c232',
    A: '#c2c2c2',
    B: '#a3a3a3',
    C: '#858585',
    D: '#666666',
    1: '#ff9933',
    2: '#9933cc',
    3: '#3399ff',
    4: '#003399',
    5: '#33cc00',
  },
};

export default theme;
