import React from 'react';
import PropTypes from 'prop-types';

import { Container, Search } from './styled';

const Searchbar = ({ onChange, ...rest }) => (
  <Container {...rest}>
    <Search onChange={onChange} />
  </Container>
);

Searchbar.propTypes = {
  onChange: PropTypes.func.isRequired,
};

export default Searchbar;
