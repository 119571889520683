import React from 'react';
import PropTypes from 'prop-types';

import ErrorMessage from 'components/ErrorMessage';
import ObservationDate from 'components/ObservationDate';
import { CustomField, StyledLabel } from './styled';

const ObservationDateField = ({ input, label, meta: { error } }) => (
  <CustomField>
    <StyledLabel>{label}</StyledLabel>
    <ObservationDate
      {...input}
      date={input.value}
    />
    {error && <ErrorMessage text={error} />}
  </CustomField>
);

ObservationDateField.propTypes = {
  input: PropTypes.object.isRequired,
  label: PropTypes.string.isRequired,
  meta: PropTypes.object.isRequired,
};

export default ObservationDateField;
