import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  height: calc(100% - 70px);
`;

export const Content = styled.div`
  display: flex;
  width: 100%;
  height: 100%;
  height: calc(100% - 70px);
`;

export const StudentColumn = styled.div`
  display: flex;
  flex-direction: column;
  width: 30%;
  min-width: 350px;
  height: 100%;
  border: 1px solid ${({ theme }) => theme.primaryGrey};
  box-sizing: border-box;
`;

export const GroupsColumn = styled.div`
  display: flex;
  flex-direction: column;
  width: 70%;
  height: 100%;
  border: 1px solid ${({ theme }) => theme.primaryGrey};
  box-sizing: border-box;
`;
