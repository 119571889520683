import styled from 'styled-components';

export const Wrapper = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  width: 25%;
`;

export const LevelItem = styled.div`
  display: flex;
  flex-shrink: 0;
  justify-content: center;
  align-items: center;
  width: 50px;
  height: 50px;
  margin-right: 7px;
  background-color: ${({ grade, theme }) => theme.grades[grade]}
`;

export const Round = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 30px;
  height: 30px;
  border-radius: 50%;
  background-color: rgba(0,0,0,0.6);
  color: ${({ theme }) => theme.primaryWhite};
`;
