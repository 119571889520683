import React from 'react';
import PropTypes from 'prop-types';

import ListItem from './components/ListItem';
import { Container, Message } from './styled';

const List = ({ list }) => {
  if (!list.length) {
    return (
      <Container role="tree" aria-label="assessments list">
        <Message role="treeitem" aria-label="There is no assessments">
          <div>
            There is no assessments.
          </div>
        </Message>
      </Container>
    );
  }

  return (
    <Container role="tree" aria-label="assessments list">
      {list.map(assessment => <ListItem key={assessment.id} assessment={assessment} />)}
    </Container>
  );
};

List.propTypes = {
  list: PropTypes.arrayOf(PropTypes.object).isRequired,
};

export default List;
