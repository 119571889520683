import { fetchActivities } from 'redux/modules/activities';
import { fetchStudents } from 'redux/modules/students';
import { fetchTasks } from 'redux/modules/tasks';
import { fetchScale } from 'redux/modules/scale';
import { fetchGrades } from 'redux/modules/grades';
import { getGroupsList } from 'redux/modules/groups-list';
import { getGroupStudentsIds, getAllStudentsIds } from 'redux/modules/students/selectors';

const ONLINE = 'rfc/app/ONLINE';
const OFFLINE = 'rfc/app/OFFLINE';
const FETCH_DATA_REQUEST = 'rfc/app/FETCH_DATA_REQUEST';
const FETCH_DATA_SUCCESS = 'rfc/app/FETCH_DATA_SUCCESS';
const FETCH_DATA_FAILURE = 'rfc/app/FETCH_DATA_FAILURE';
const SELECTED_STUDENTS_CHANGE = 'rfc/app/SELECTED_STUDENTS_CHANGE';
const SHOULD_REDIRECT_TO_LOGIN_SET = 'rfc/app/SHOULD_REDIRECT_TO_LOGIN_SET';
const SHOULD_REDIRECT_TO_LOGIN_UNSET = 'rfc/app/SHOULD_REDIRECT_TO_LOGIN_UNSET';

const isOnline = window.navigator && window.navigator.onLine;
const initialState = {
  online: isOnline || true,
  selectedGroupId: -1,
  selectedStudentsIds: [],
  shouldRedirectToLogin: false,
  loading: true,
  error: '',
};

export default function reducer(state = initialState, action = {}) {
  switch (action.type) {
    case ONLINE:
      return { ...state, ...{ online: true } };
    case OFFLINE:
      return { ...state, ...{ online: false } };
    case FETCH_DATA_REQUEST:
      return { ...state, loading: true };
    case SELECTED_STUDENTS_CHANGE: {
      return {
        ...state,
        ...{
          selectedStudentsIds: [...action.payload.selectedStudentsIds],
          selectedGroupId: action.payload.selectedGroupId,
        },
      };
    }
    case SHOULD_REDIRECT_TO_LOGIN_SET:
      return { ...state, ...{ shouldRedirectToLogin: true } };
    case SHOULD_REDIRECT_TO_LOGIN_UNSET:
      return { ...state, ...{ shouldRedirectToLogin: false } };
    case FETCH_DATA_SUCCESS:
      return { ...state, ...{ loading: false } };
    case FETCH_DATA_FAILURE:
      return { ...state, ...{ error: action.payload, loading: false } };
    default:
      return state;
  }
}

export function online() {
  return { type: ONLINE };
}

export function offline() {
  return { type: OFFLINE };
}

const requestData = () => ({ type: FETCH_DATA_REQUEST });
const changeSelectedStudents = (selectedGroupId, selectedStudentsIds) => ({
  type: SELECTED_STUDENTS_CHANGE,
  payload: { selectedGroupId, selectedStudentsIds },
});
const recieveData = payload => ({ type: FETCH_DATA_SUCCESS, payload });
const errorData = payload => ({ type: FETCH_DATA_FAILURE, payload });

export function fetchData(collectionId = 0) {
  return (dispatch, getState) => {
    dispatch(requestData());

    Promise.all([
      dispatch(fetchStudents(collectionId)),
      dispatch(fetchTasks(collectionId)),
      dispatch(fetchScale(collectionId)),
      dispatch(fetchGrades(collectionId)),
      dispatch(getGroupsList(collectionId)),
    ]).then(() => (
      dispatch(fetchActivities(collectionId))
    )).then(() => {
      dispatch(changeSelectedStudents(-1, getAllStudentsIds(getState().students)));
    }).then(() => {
      dispatch(recieveData());
    })
      .catch((error) => { dispatch(errorData(error)); });
  };
}

export const selectGroup = (selectedGroup, groupStudents) => (dispatch, getState) => {
  dispatch(changeSelectedStudents(
    selectedGroup,
    getGroupStudentsIds(getState().students, groupStudents) || getAllStudentsIds(getState().students),
  ));
};
export const setShouldRedirectToLogin = () => (dispatch) => { dispatch({ type: SHOULD_REDIRECT_TO_LOGIN_SET }); };
export const unsetShouldRedirectToLogin = () => (dispatch) => { dispatch({ type: SHOULD_REDIRECT_TO_LOGIN_UNSET }); };
