import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';

import { getAssessmentsList } from 'redux/modules/assessments-list';
import { setShouldActivitiesListUpdate } from 'redux/modules/activities';
import { setShouldGroupsListUpdate } from 'redux/modules/groups-list';
import Dropdown from 'components/Dropdown';
import LevelSettingsModal from './components/LevelSettingsModal';
import Navigation from './components/Navigation';
import { SettingsButton, Content, LevelSettings, AssessmentMain } from './styled';

class Header extends Component {
  static propTypes = {
    list: PropTypes.arrayOf(PropTypes.object).isRequired,
    getAssessmentsList: PropTypes.func.isRequired,
    history: PropTypes.object.isRequired,
    match: PropTypes.object.isRequired,
    setShouldActivitiesListUpdate: PropTypes.func.isRequired,
    setShouldGroupsListUpdate: PropTypes.func.isRequired,
  }

  constructor(props) {
    super(props);

    this.toggle = this.toggle.bind(this);
    this.onDropdownSelect = this.onDropdownSelect.bind(this);
  }

  state = {
    isModalOpened: false,
  }

  componentDidMount() {
    this.props.getAssessmentsList();
  }

  onDropdownSelect({ id }) {
    this.props.setShouldActivitiesListUpdate();
    this.props.setShouldGroupsListUpdate();
    this.props.history.push(`/collections/${id}/assessments/overview`);
  }

  toggle() {
    this.setState({
      isModalOpened: !this.state.isModalOpened,
    });
  }

  render() {
    const { list, match } = this.props;
    const { collectionId } = match.params;
    const url = `/collections/${collectionId}/assessments/overview`;

    return (
      <Content>
        <Dropdown
          onDropdownSelect={this.onDropdownSelect}
          list={list}
          type="header"
          index={0}
          selectedItemId={collectionId}
        >
          <AssessmentMain id="assessment-main" to={url} tabIndex={-1} aria-label="Home">
            EARLY LEARNING ASSESSMENT
          </AssessmentMain>
          <LevelSettings>
            <SettingsButton
              icon="settings"
              aria-label="settings"
              tabIndex={0}
              onClick={this.toggle}
              color="transparent"
            />
          </LevelSettings>
        </Dropdown>
        <Navigation />
        <LevelSettingsModal
          isOpen={this.state.isModalOpened}
          onRequestClose={this.toggle}
        />
      </Content>
    );
  }
}

const mapStateToProps = ({ assessments: { list, error, loading } }) => ({
  list,
  error,
  loading,
});

export default withRouter(connect(
  mapStateToProps,
  { getAssessmentsList, setShouldActivitiesListUpdate, setShouldGroupsListUpdate },
)(Header));
