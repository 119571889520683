import React, { Component } from 'react';
import PropTypes from 'prop-types';

import { Content, Separator } from './styled';

import Row from './components/Row';

export default class Grid extends Component {
  static propTypes = {
    tasks: PropTypes.array.isRequired,
    registerSeparatorRef: PropTypes.func.isRequired,
    onRowClicked: PropTypes.func.isRequired,
  }

  constructor(props) {
    super(props);

    this.onRowClicked = this.onRowClicked.bind(this);
  }

  state = {
    rate: {},
    header: '',
  }

  shouldComponentUpdate(nextProps) {
    return (nextProps.tasks !== this.props.tasks);
  }

  onStudentModalClosed() {
    this.setState({ studentsModalOpened: false });
  }

  onRowClicked(rate, header) {
    if (!rate.rate) {
      return;
    }
    this.setState({ studentsModalOpened: true, rate, header });
  }

  render() {
    const { registerSeparatorRef, tasks, onRowClicked } = this.props;
    return (
      <div>
        {tasks.map((item, index) => (
          <Content key={index}>
            {typeof item === 'string' ?
              <Separator tabIndex={-1} innerRef={ref => registerSeparatorRef(ref, index)}>
                {item}
              </Separator> :
              <Row row={item} index={item.task.order} key={item.task.title} onClick={onRowClicked} />
            }
          </Content>
        ))}
      </div>
    );
  }
}
