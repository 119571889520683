import React from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';

import { Container, Timestamp } from './styled';

const Note = ({ note }) => (
  <Container>
    {
      note ?
        <div>
          <Timestamp>{moment().format('M/D/YYYY')}</Timestamp>
          <div>{note}</div>
        </div> :
        null
    }
  </Container>
);

Note.defaultProps = {
  note: '',
};

Note.propTypes = {
  note: PropTypes.string,
};

export default Note;
