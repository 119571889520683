import styled, { keyframes } from 'styled-components';

export const spin = keyframes`
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
`;

export const Loader = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 1000;
  background: #000000;
  backface-visibility: hidden;
  display: flex;
  opacity: 0.8;
  align-items: center;
  justify-content: center;
  div {
    display: flex;
    flex-shrink: 0;
    position: relative;
    align-items: center;
    justify-content: center;
    width: 120px;
    height: 120px;
    border-radius: 50%;
    border: 5px solid transparent;
    border-right-color: #3399ff;
    border-left-color: #3399ff;
    animation: 2s ${spin} linear infinite;

    span {
      display: flex;
      flex-shrink: 0;
      align-items: center;
      justify-content: center;
      content: '';
      width: 90px;
      height: 90px;
      border-radius: 50%;
      border: 5px solid transparent;
      border-right-color: #33cc00;
      border-left-color: #33cc00;
      animation: 1.5s ${spin} linear infinite;
      p {
        display: flex;
        flex-shrink: 0;
        align-items: center;
        justify-content: center;
        content: '';
        width: 60px;
        height: 60px;
        border-radius: 50%;
        border: 5px solid transparent;
        border-right-color: #ff9933;
        border-left-color: #ff9933;
        animation: 3s ${spin} linear infinite;
      }
    }
  }
`;

