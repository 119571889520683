import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import find from 'lodash/find';
import queryString from 'query-string';
import update from 'immutability-helper';
import moment from 'moment';

import { compareWithSelected } from 'redux/modules/scale/helpers';
import { getArtifact } from 'redux/modules/grades/helpers';
import { fetchGrades, postGrade, deleteGrade } from 'redux/modules/grades';
import Button from 'components/Button';
import ObservationDate from 'components/ObservationDate';
import Preloader from 'components/Preloader';
import Subheader from 'components/Subheader';
import Artifacts from './components/Artifacts';
import Comment from './components/Comment';
import Information from './components/Information';
import Rating from './components/Rating';
import { Container, Title, ButtonsBlock, DatePickerContainer } from './styled';

class RatingDetail extends Component {
  static propTypes = {
    fetchGrades: PropTypes.func.isRequired,
    history: PropTypes.object.isRequired,
    loading: PropTypes.bool.isRequired,
    location: PropTypes.object.isRequired,
    match: PropTypes.object.isRequired,
    postGrade: PropTypes.func.isRequired,
    deleteGrade: PropTypes.func.isRequired,
    scale: PropTypes.array.isRequired,
    gradeList: PropTypes.array.isRequired,
    selected: PropTypes.string.isRequired,
    student: PropTypes.object.isRequired,
    task: PropTypes.object.isRequired,
  }

  constructor(props) {
    super(props);

    this.goBack = this.goBack.bind(this);
    this.clearScore = this.clearScore.bind(this);
    this.submit = this.submit.bind(this);
    this.onScoreChange = this.onScoreChange.bind(this);
    this.closeScaleModal = this.closeScaleModal.bind(this);
    this.openScaleModal = this.openScaleModal.bind(this);
    this.closeBrowseArtifactsModal = this.closeBrowseArtifactsModal.bind(this);
    this.openBrowseArtifactsModal = this.openBrowseArtifactsModal.bind(this);
    this.closeBrowseNotesModal = this.closeBrowseNotesModal.bind(this);
    this.openBrowseNotesModal = this.openBrowseNotesModal.bind(this);
    this.addSelectedArtifacts = this.addSelectedArtifacts.bind(this);
    this.addSelectedNotes = this.addSelectedNotes.bind(this);
    this.deleteFromSavedImages = this.deleteFromSavedImages.bind(this);
    this.deleteFromImages = this.deleteFromImages.bind(this);
    this.handleKeyDown = this.handleKeyDown.bind(this);
    this.handleCommentChange = this.handleCommentChange.bind(this);
    this.onDrop = this.onDrop.bind(this);
    this.assignInnerRef = this.assignInnerRef.bind(this);
    this.onDateChange = this.onDateChange.bind(this);
  }

  state = {
    isScaleModalOpened: false,
    selected: this.props.selected,
    isBrowseArtifactsModalOpened: false,
    isBrowseNotesModalOpened: false,
    observationDate: moment(),
    modalInput: {
      images: [],
      savedImages: [],
      comment: '',
    },
  }

  onDrop(acceptedFiles) {
    let files = [...this.state.modalInput.images];
    const currentTime = (`${new Date().getTime()}`).substr(0, 10);
    const formattedFiles = acceptedFiles.map(item => ({
      ...item,
      comment: '',
      created_at: currentTime,
      file: item,
      original_file_name: item.name,
      s3_file_name: item.preview,
    }));

    files = files.concat(formattedFiles);

    this.setState(update(this.state, { modalInput: { images: { $set: files } } }));
  }

  //  Date
  onDateChange(value) {
    this.setState(update(this.state, { observationDate: { $set: value } }));
  }

  //  Rating
  onScoreChange(level) {
    this.setState({ selected: level });
  }

  closeScaleModal() {
    this.setState({ isScaleModalOpened: false });
  }

  openScaleModal() {
    this.setState({ isScaleModalOpened: true });
  }

  //  Comment
  closeBrowseNotesModal() {
    this.setState({ isBrowseNotesModalOpened: false });
  }

  openBrowseNotesModal() {
    this.setState({ isBrowseNotesModalOpened: true });
  }

  addSelectedNotes(newNotes) {
    this.setState(update(this.state, {
      modalInput: { comment: { $set: `${this.state.modalInput.comment}${newNotes}` } },
    }));
  }

  handleCommentChange(event) {
    this.setState(update(this.state, { modalInput: { comment: { $set: event.target.value } } }));
  }

  //  Artifacts
  closeBrowseArtifactsModal() {
    this.setState({ isBrowseArtifactsModalOpened: false });
  }

  openBrowseArtifactsModal() {
    this.setState({ isBrowseArtifactsModalOpened: true });
  }

  addSelectedArtifacts(newImages) {
    this.setState(update(this.state, { modalInput: { savedImages: { $push: newImages } } }));
  }

  deleteFromSavedImages(index) {
    const files = [...this.state.modalInput.savedImages];
    files.splice(index, 1);

    this.setState(update(this.state, { modalInput: { savedImages: { $set: files } } }));
  }

  deleteFromImages(index) {
    const files = [...this.state.modalInput.images];
    files.splice(index, 1);

    this.setState(update(this.state, { modalInput: { images: { $set: files } } }));
  }

  handleKeyDown(event) {
    if (event.key === 'Enter' || event.key === ' ') {
      this.dropzoneRef.open();
    }
  }

  assignInnerRef(node) {
    this.dropzoneRef = node;
  }

  //  Footer
  clearScore() {
    this.setState({
      selected: 'R',
      modalInput: { images: [], savedImages: [], comment: '' },
    });
  }

  submit() {
    const { taskId, collectionId } = this.props.match.params;
    const postParams = {
      assessment_items_id: +taskId,
      assessment_items_scales_entries_id: null,
      data_collection_parent_id: collectionId,
      student_enrollment_id: this.props.student.student_enrollment_id,
      observed_at: this.state.observationDate.unix(),
    };

    if (this.state.selected === 'R') {
      const grade = getArtifact(
        this.props.gradeList,
        taskId,
        this.props.student,
      );

      this.props.deleteGrade(grade && grade.id, collectionId).then(() => this.props.fetchGrades(collectionId)).then(() => this.goBack());
    } else {
      this.props.postGrade(
        this.state.selected,
        this.props.scale,
        postParams,
        this.state.modalInput,
      )
        .then(() => this.props.fetchGrades(collectionId))
        .then(() => this.goBack());
    }
  }

  dropzoneRef = null;

  goBack() {
    this.props.history.goBack();
  }

  render() {
    const {
      loading,
      location: { search },
      scale,
      student,
      task,
    } = this.props;
    const searchParam = queryString.parse(search);
    const grade = getArtifact(this.props.gradeList, this.props.match.params.taskId, this.props.student);

    return (
      <Container>
        <Preloader loading={loading} />
        <Subheader module={searchParam.mode ? searchParam.mode : 'assessments'}>
          <Button icon="back" size="lg" color="transparent" onClick={this.goBack}>
            Rating
          </Button>
        </Subheader>

        <Information
          student={student}
          task={task}
        />

        <Rating
          scale={scale}
          selected={this.state.selected}
          onScoreChange={this.onScoreChange}
          isOpen={this.state.isScaleModalOpened}
          openScaleModal={this.openScaleModal}
          closeScaleModal={this.closeScaleModal}
        />

        <DatePickerContainer>
          <Title>Observation Date</Title>
          <ObservationDate date={this.state.observationDate} onChange={this.onDateChange} />
        </DatePickerContainer>

        <Comment
          commentValue={this.state.modalInput.comment}
          isOpen={this.state.isBrowseNotesModalOpened}
          openModal={this.openBrowseNotesModal}
          closeModal={this.closeBrowseNotesModal}
          handleChange={this.handleCommentChange}
          addSelected={this.addSelectedNotes}
        />

        <Artifacts
          addSelected={this.addSelectedArtifacts}
          assignInnerRef={this.assignInnerRef}
          closeModal={this.closeBrowseArtifactsModal}
          deleteFromImages={this.deleteFromImages}
          deleteFromSavedImages={this.deleteFromSavedImages}
          handleKeyDown={this.handleKeyDown}
          isOpen={this.state.isBrowseArtifactsModalOpened}
          modalInput={this.state.modalInput}
          onDrop={this.onDrop}
          openModal={this.openBrowseArtifactsModal}
        />

        <ButtonsBlock>
          {this.state.selected && this.state.selected === 'R' && !grade ?
            null :
            <Button
              size="md"
              color="#70a823"
              onClick={this.submit}
            >
            Submit
            </Button>
        }
          {this.state.selected && this.state.selected !== 'R' ?
            <Button
              color="#EF8434"
              onClick={this.clearScore}
            >
              Clear
            </Button> :
            null
          }
          <Button
            size="md"
            color="#fff"
            textColor="#777"
            border="1px solid #ccc"
            onClick={this.goBack}
          >
            Cancel
          </Button>
        </ButtonsBlock>
      </Container>
    );
  }
}

const mapStateToProps = ({
  grades,
  scale,
  students,
  tasks,
}, { match, location }) => {
  const { taskId, studentId } = match.params;
  const currentStudent = find(students.list, item => item.id === +studentId);
  const rating = queryString.parse(location.search).rating || 'R';
  const task = find(tasks.tasks, { id: +taskId });

  return {
    scale: compareWithSelected(
      scale.scale.filter(item => item.parent_id === +task.assessment_items_scales_directory_parent_id),
      scale.selectedLevels,
    ),
    student: currentStudent,
    task: find(tasks.taskShortcuts, item => (+item.id === +taskId)) || { description: '' },
    selected: rating,
    gradeList: grades.list,
    loading: grades.loading,
  };
};

export default connect(mapStateToProps, { fetchGrades, postGrade, deleteGrade })(RatingDetail);
