import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import update from 'immutability-helper';
import { withRouter, Redirect } from 'react-router-dom';

import Button from 'components/Button';
import Subheader from 'components/Subheader';
import Preloader from 'components/Preloader';

import Description from 'modules/groups/components/Description';
import SelectStudents from 'modules/groups/components/SelectStudents';
import BottomBar from 'modules/groups/components/BottomBar';
import { Container, Main, SubheaderGroup } from './styled';

import { editGroup, deleteGroup, clearShouldGoBack } from 'redux/modules/group';

const validate = (field, value) => {
  const errors = {};

  if (field === 'label') {
    errors[field] = (value ? '' : 'Please fill out this field.');
  }

  return errors;
};

class EditGroup extends Component {
  static propTypes = {
    actions: PropTypes.shape({
      clearShouldGoBack: PropTypes.func.isRequired,
      deleteGroup: PropTypes.func.isRequired,
      editGroup: PropTypes.func.isRequired,
    }).isRequired,
    group: PropTypes.object.isRequired,
    history: PropTypes.object.isRequired,
    loading: PropTypes.bool.isRequired,
    match: PropTypes.object.isRequired,
    shouldGoBack: PropTypes.bool.isRequired,
  }

  constructor(props) {
    super(props);

    this.toGroupList = this.toGroupList.bind(this);
    this.onInputChange = this.onInputChange.bind(this);
    this.onSave = this.onSave.bind(this);
    this.onCancel = this.onCancel.bind(this);
    this.onDelete = this.onDelete.bind(this);
    this.onStudentSelect = this.onStudentSelect.bind(this);
  }

  state = {
    group: this.props.group,
    studentsIds: this.props.group.studentsIds,
    errors: {
      label: '',
    },
  }

  componentWillUnmount() {
    this.props.actions.clearShouldGoBack();
  }

  onInputChange(field, value) {
    const errors = validate(field, value);
    const state = update(this.state, { group: { [field]: { $set: value } }, errors: { $merge: errors } });

    this.setState(state);
  }

  onStudentSelect(row) {
    const selected = [...this.state.studentsIds];
    if (row.student_enrollment_id) {
      if (!selected.includes(row.student_enrollment_id)) {
        selected.push(row.student_enrollment_id);
      } else {
        const index = selected.indexOf(row.student_enrollment_id);
        selected.splice(index, 1);
      }

      this.setState({ studentsIds: selected });
    }
  }

  onSave() {
    const { collectionId, id } = this.props.match.params;
    const { group, studentsIds } = this.state;

    this.props.actions.editGroup(collectionId, id, group, studentsIds);
  }

  onCancel() {
    this.props.history.push(`/collections/${this.props.match.params.collectionId}/groups/list`);
  }

  onDelete() {
    this.props.actions.deleteGroup(this.props.match.params.collectionId, this.props.match.params.id);
  }

  toGroupList() {
    this.props.history.push(`/collections/${this.props.match.params.collectionId}/groups/list`);
  }

  render() {
    const { group, errors, studentsIds } = this.state;
    const { loading, shouldGoBack } = this.props;

    if (shouldGoBack) {
      return (
        <Redirect to={`/collections/${this.props.match.params.collectionId}/groups/list`} />
      );
    }

    return (
      <Container>
        <Preloader loading={loading} />
        <Subheader module="groups">
          <SubheaderGroup>
            <Button
              size="lg"
              color="transparent"
              icon="back"
              onClick={this.toGroupList}
            >
              Create/Edit Group
            </Button>
          </SubheaderGroup>
        </Subheader>
        <Main>
          <Description
            groupName={group.label}
            groupColorKey={group.color}
            onInputChange={this.onInputChange}
            errorMessage={errors.label}
          />
          <SelectStudents
            studentsIds={studentsIds}
            onChange={this.onStudentSelect}
          />
        </Main>
        <BottomBar
          isSaveDisabled={Object.values(errors).some(error => error)}
          onSave={this.onSave}
          onCancel={this.onCancel}
          onDelete={this.onDelete}
        />
      </Container>
    );
  }
}

const mapStateToProps = ({ group, groups }, props) => ({
  group: groups.groupsListing[props.match.params.id],
  loading: group.loading,
  shouldGoBack: group.shouldGoBack,
});

const mapDispatchToProps = dispatch => ({
  actions: bindActionCreators({ editGroup, deleteGroup, clearShouldGoBack }, dispatch),

});

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(EditGroup));
