import styled from 'styled-components';
import Logo from './images/logo.png';

export const Container = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  background-color: ${props => props.theme.primaryDark};
`;

export const Wrapper = styled.div`
  width: 50%;
  max-width: 600px;
`;

export const Heading = styled.img.attrs({
  src: Logo,
})`
  width: 100%;
  margin-bottom: 20px;
`;

export const Errors = styled.div`
  display: flex;
  justify-content: center;
  margin-top: 40px;
  color: #f00;
  font-size: 18px;
`;
