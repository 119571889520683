import styled from 'styled-components';
import TimePicker from 'rc-time-picker';

export const Container = styled.div`
  display: flex;
  flex: 1;
`;

export const StyledTimePicker = styled(TimePicker).attrs({
  allowEmpty: false,
  showSecond: false,
  format: 'hh:mm A',
  use12Hours: true,
})`

  input {
    height: 36px;
    width: 100px;
    color: ${({ theme }) => theme.darkGrey};
    border: 1px solid ${({ theme }) => theme.primaryGrey};
    border-radius: 0;
    font-size: inherit;
    font-family: inherit;
  }
`;
