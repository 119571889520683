import concat from 'lodash/concat';

function isLevelSelected(scale, label) {
  const filtered = scale.filter(item => item.label === label)[0];

  return filtered.checked;
}

function sortLevels(list) {
  const letters = [];
  const numbers = [];

  list.map((item) => {
    if (+item.label) {
      numbers.push(item);
    } else if (item.label === 'NE') {
      const nIndex = letters.indexOf('N');
      const index = nIndex === -1 ? 0 : nIndex;
      letters.splice(index + 1, 0, item);
    } else if (item.label === 'N') {
      const neIndex = letters.indexOf('NE');
      const index = neIndex === -1 ? 0 : neIndex;
      letters.splice(index, 0, item);
    } else {
      letters.push(item);
    }
    return null;
  });

  return concat(letters, numbers);
}

export function compareWithSelected(scale, selectedLevels) {
  if (!scale) return [];
  const filtered = scale.filter(item => isLevelSelected(selectedLevels, item.label));

  return sortLevels(filtered);
}

export function getAvailableLevels(scale) {
  if (!scale) return [];

  return scale.filter(item => (item.checked && !item.default));
}

export function getAvailableLevelsDefault(scale) {
  if (!scale) return [];
  return scale.filter(item => item.checked);
}

export function getLabelById(scale, id) {
  if (!id || !scale.scale.length) {
    return scale.selectedLevels[0].label;
  }

  return scale.scale
    && scale.scale.filter(item => (
      +item.level_id === id
    ))[0]
    && scale.scale.filter(item => (
      +item.level_id === id
    ))[0].label;
}

export function getLevelIdByLabel(label, scale) {
  return scale.filter(item => item.label === label)[0].level_id;
}
