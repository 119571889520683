import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { Field, reduxForm } from 'redux-form';
import { Redirect, withRouter } from 'react-router';
import { connect } from 'react-redux';
import moment from 'moment';

import Button from 'components/Button';
import Preloader from 'components/Preloader';
import Subheader from 'components/Subheader';
import ObservationDateField from './components/ObservationDateField';
import RenderCustomField from './components/RenderCustomField';
import { Container, Form, Row, StyledButton, TextArea, TextInput } from './styled';

const validate = (values) => {
  const errors = {};
  if (!values.label) {
    errors.label = 'A title is required';
  }
  if (!values.description) {
    errors.description = 'A note is required';
  }
  if (!values.observation_date.isValid()) {
    errors.observation_date = 'A valid date is required';
  }

  return errors;
};

class CreateEditNote extends PureComponent {
  static defaultProps = {
    createdId: null,
    note: {},
  }

  static propTypes = {
    createdId: PropTypes.number,
    handleSubmit: PropTypes.func.isRequired,
    history: PropTypes.object.isRequired,
    loading: PropTypes.bool.isRequired,
    match: PropTypes.object.isRequired,
    note: PropTypes.object, // eslint-disable-line react/no-unused-prop-types
    onRequestSave: PropTypes.func.isRequired,
    submitting: PropTypes.bool.isRequired,
  }

  constructor(props) {
    super(props);

    this.onCancel = this.onCancel.bind(this);
  }

  onCancel() {
    const { history: { push }, match: { params } } = this.props;
    push(`/collections/${params.collectionId}/activities/list/notes`);
  }

  render() {
    const {
      createdId,
      handleSubmit,
      history,
      loading,
      match,
      onRequestSave,
      submitting,
    } = this.props;

    if (createdId) {
      return <Redirect to={`/collections/${match.params.collectionId}/activities/list/notes`} />;
    }

    return (
      <Container>
        <Preloader loading={loading} />
        <Subheader module="activities">
          <Button
            aria-label="Back"
            icon="back"
            size="lg"
            color="transparent"
            onClick={history.goBack}
          >
            <div>Create note</div>
          </Button>
        </Subheader>
        <Form onSubmit={handleSubmit(onRequestSave)}>
          <Field name="label" component={RenderCustomField} label="title" type={TextInput} />
          <Field name="observation_date" component={ObservationDateField} label="observation date" />
          <Field name="description" component={RenderCustomField} label="note" type={TextArea} />
          <Row>
            <StyledButton
              aria-label="Save"
              color="#70a823"
              disabled={submitting || loading}
              type="submit"
            >
              Save
            </StyledButton>
            <StyledButton
              aria-label="Cancel"
              border="1px solid #666666"
              color="#ffffff"
              disabled={submitting || loading}
              onClick={this.onCancel}
              textColor="#666666"
              type="button"
            >
              Cancel
            </StyledButton>
          </Row>
        </Form>
      </Container>
    );
  }
}

const mapStateToProps = ({ notes }, { note = {} }) => ({
  initialValues: {
    description: note.description || '',
    label: note.label || '',
    observation_date: note.observation_date || moment(),
  },
  createdId: notes.createdId,
  loading: notes.loading,
});

export default withRouter(connect(mapStateToProps)(reduxForm({ form: 'note', validate })(CreateEditNote)));
