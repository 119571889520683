import { createSelector } from 'reselect';
import moment from 'moment';

export const getNote = createSelector(
  (notes, noteId) => noteId,
  notes => notes.notes,
  (noteId, notes) => {
    const noteById = notes.find(note => +note.id === +noteId);
    if (noteById) {
      return {
        description: noteById.description,
        label: noteById.label,
        observation_date: moment.unix(+noteById.observation_date),
      };
    }

    return {};
  },
);

export const getVisibleNotes = createSelector(
  (notes, search) => search,
  notes => notes.notes,
  (search, notes) => {
    if (search) {
      const regexp = new RegExp(search, 'i');

      return notes.filter(note => regexp.test(note.label));
    }

    return notes;
  },
);
