import { createSelector } from 'reselect';
import { formatHistory } from 'redux/modules/history/formatters';

export const getHistory = createSelector(
  data => data.history,
  data => data.scale,
  (history, scale) => formatHistory(history.data, scale),
);

export const selector = () => {};
