import styled from 'styled-components';

export const Container = styled.div`
  position: relative;
  display: flex;
  flex: 1;
  flex-direction: column;
  background-color: ${({ theme }) => theme.lightGrey};
  color: ${({ theme }) => theme.darkGrey};
  overflow-y: scroll;
  height: calc(100% - 70px);
`;

export const Content = styled.div`

`;

export const Separator = styled.div`
  font-weight: bold;
  padding-left: 20px;
  display: flex;
  font-size: 16px;
  text-transform: uppercase;
  align-items: center;
  height: 50px;
  box-sizing: border-box;
`;
