import React from 'react';
import PropTypes from 'prop-types';
import find from 'lodash/find';

import GroupColor from './components/GroupColor';
import { Container, ColorList, Lable } from './styled';
import groupColors from 'configs/groupColors';

const GroupColorSelect = ({ groupColorKey, onInputChange }) => {
  const selectedColor = find(groupColors, ['key', groupColorKey]);
  const selectedColorKey = selectedColor ? selectedColor.key : groupColors[0].key;

  return (
    <Container>
      <Lable>Color</Lable>
      <ColorList role="radiogroup" aria-label="Group color">
        {groupColors.map(item => (
          <GroupColor
            color={item}
            selectedColorKey={selectedColorKey}
            key={item.id}
            onInputChange={onInputChange}
          />
        ))}
      </ColorList>
    </Container>
  );
};

GroupColorSelect.propTypes = {
  groupColorKey: PropTypes.string.isRequired,
  onInputChange: PropTypes.func.isRequired,
};

export default GroupColorSelect;
