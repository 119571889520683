import React from 'react';
import PropTypes from 'prop-types';

import Checkbox from 'components/Checkbox';
import withKeyDown from 'components/withKeyDown';
import { Wrapper, LevelItem, Round } from './styled';

const WrapperWithKeyDown = withKeyDown(Wrapper, 'Space');

const Level = ({ level: { checked, id, label }, selectLevel }) => {
  const onClick = () => selectLevel(id);

  return (
    <WrapperWithKeyDown
      aria-label={`set level ${label} to ${!checked}`}
      tabIndex={0}
      onClick={onClick}
    >
      <LevelItem grade={label}>
        <Round>{label}</Round>
      </LevelItem>
      <Checkbox tabIndex={-1} checked={checked} />
    </WrapperWithKeyDown>
  );
};

Level.propTypes = {
  level: PropTypes.object.isRequired,
  selectLevel: PropTypes.func.isRequired,
};

export default Level;
