import http from '../../../http';

import { formatStudents } from './formatters';

const FETCH_STUDENTS_REQUEST = 'rfc/students/FETCH_STUDENTS_REQUEST';
const FETCH_STUDENTS_SUCCESS = 'rfc/students/FETCH_STUDENTS_SUCCESS';
const FETCH_STUDENTS_FAILURE = 'rfc/students/FETCH_STUDENTS_FAILURE';
const FETCH_LIST_SUCCESS = 'rfc/students/FETCH_LIST_SUCCESS';

const initialState = {
  students: [],
  list: [],
  loading: false,
};

export default function reducer(state = initialState, action = {}) {
  switch (action.type) {
    case FETCH_STUDENTS_REQUEST:
      return { ...state, loading: true };
    case FETCH_STUDENTS_SUCCESS:
      return { ...state, ...{ students: action.payload, loading: false } };
    case FETCH_STUDENTS_FAILURE:
      return { ...state, ...{ error: action.payload, loading: false } };
    case FETCH_LIST_SUCCESS:
      return { ...state, ...{ list: action.payload } };
    default:
      return state;
  }
}

const requestStudents = () => ({ type: FETCH_STUDENTS_REQUEST });
const errorStudents = payload => ({ type: FETCH_STUDENTS_FAILURE, payload });
const recieveStudents = payload => ({ type: FETCH_STUDENTS_SUCCESS, payload });
const recieveList = payload => ({ type: FETCH_LIST_SUCCESS, payload });

export function fetchStudents(collectionId = 0) {
  return (dispatch) => {
    dispatch(requestStudents());

    return Promise.all([
      http.get(`en/api/v1/user/collections/${collectionId}/students.json`),
      http.get(`en/api/v1/user/completions/collections/${collectionId}/sets/students.json`),
    ])
      .then((values) => {
        const formatted = formatStudents(...values);
        dispatch(recieveStudents(formatted));
        dispatch(recieveList(values[0].data));
      })
      .catch((error) => { dispatch(errorStudents(error)); });
  };
}
