import React from 'react';
import PropTypes from 'prop-types';

import { Container, ResourceButton } from './styled';

const Item = ({ onResourceClick, resource }) => {
  const onClick = () => onResourceClick(resource.id);

  return (
    <Container>
      <ResourceButton onClick={onClick}>
        {resource.label || 'Resource'} / {resource.original_file_name}
      </ResourceButton>
    </Container>
  );
};

Item.propTypes = {
  onResourceClick: PropTypes.func.isRequired,
  resource: PropTypes.object.isRequired,
};

export default Item;
