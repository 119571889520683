import React from 'react';
import PropTypes from 'prop-types';
import ReactModal from 'react-modal';

import Button from 'components/Button';

import { Header, Content, CloseButton } from './styled';

const getParentSelector = () => document.querySelector('#root > div');

const defaultStyles = {
  overlay: {
    display: 'flex',
    alignItems: 'center',
    zIndex: 100,
    background: 'rgba(0,0,0,0.3)',
  },
  content: {
    position: 'static',
    margin: 'auto',
    padding: 0,
    minHeight: 100,
    border: 'none',
  },
};

const modalStyles = {
  sm: {
    ...defaultStyles,
    content: {
      ...defaultStyles.content,
      width: '30%',
    },
  },
  md: {
    ...defaultStyles,
    content: {
      ...defaultStyles.content,
      width: '50%',
    },
  },
  lg: {
    ...defaultStyles,
    content: {
      ...defaultStyles.content,
      width: '90%',
    },
  },
};


const Modal = ({
  isOpen,
  onRequestClose,
  parentSelector,
  headerTitle,
  size,
  children,
}) => (
  <ReactModal
    isOpen={isOpen}
    onRequestClose={onRequestClose}
    parentSelector={parentSelector}
    contentLabel="Modal"
    style={modalStyles[size]}
  >
    <Header>
      {headerTitle}
      <CloseButton>
        <Button
          icon="close"
          size="mdIcon"
          tabIndex={0}
          aria-label="close modal"
          color="transparent"
          onClick={onRequestClose}
        />
      </CloseButton>
    </Header>
    <Content>
      {children}
    </Content>
  </ReactModal>
);

Modal.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  children: PropTypes.node.isRequired,
  onRequestClose: PropTypes.func.isRequired,
  headerTitle: PropTypes.node,
  size: PropTypes.oneOf(['lg', 'md', 'mdIcon', 'sm']),
  parentSelector: PropTypes.func,
};

Modal.defaultProps = {
  headerTitle: null,
  parentSelector: getParentSelector,
  size: 'md',
};

export default Modal;
