import styled from 'styled-components';

export const Container = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
`;

export const Header = styled.div`
  border-left: 1px solid #cccccc;
`;

export const StudentList = styled.div`
  border-left: 1px solid #cccccc;
  overflow: auto;
  max-height: 100%;
`;
