import styled from 'styled-components';

export const Container = styled.div`
  display: block;
  padding: 10px 15px;
  text-transform: uppercase;
  background-color: ${({ theme }) => (theme.lightGrey)};
  color: ${({ theme }) => (theme.darkGrey)};
  border-top: 1px solid ${({ theme }) => (theme.primaryGrey)};
  border-bottom: 1px solid ${({ theme }) => (theme.primaryGrey)};
`;

export const Content = styled.div`
  display: flex;
  flex-direction: row;
  text-align: center;
  align-items: center;
`;

export const Text = styled.div`
  margin: 9px 0;
`;
