import React from 'react';
import PropTypes from 'prop-types';

import withKeyDown from 'components/withKeyDown';
import { Container, Item } from './styled';

const ContainerWithKeyDown = withKeyDown(Container, 'Space');

const Switcher = ({ toggle, value }) => (
  <ContainerWithKeyDown
    role="button"
    aria-label={`switch only show priority items to ${!value}`}
    onClick={toggle}
    tabIndex={0}
  >
    <Item value={!value}>off</Item>
    <Item value={value}>on</Item>
  </ContainerWithKeyDown>
);

Switcher.propTypes = {
  toggle: PropTypes.func.isRequired,
  value: PropTypes.bool.isRequired,
};

export default Switcher;
