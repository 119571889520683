import React from 'react';
import PropTypes from 'prop-types';

import ErrorMessage from 'components/ErrorMessage';
import { Container, TextInput } from './styled';

const Name = ({ input, meta: { error, touched } }) => (
  <Container>
    <label htmlFor="name">NAME</label>
    <TextInput
      {...input}
      aria-label={`Name: ${input.value || 'No name'}`}
      id="name"
      type="text"
      placeholder="Enter text..."
      value={input.value}
    />
    {error && touched && <ErrorMessage text={error} />}
  </Container>
);

Name.propTypes = {
  input: PropTypes.object.isRequired,
  meta: PropTypes.object.isRequired,
};

export default Name;
