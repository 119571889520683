import styled from 'styled-components';

export const Container = styled.div`
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 55px;
  height: 55px;
  border-bottom: 1px solid #494c4f;
  cursor: pointer;
  user-select: none;

  &:last-child {
    border-bottom: none;
  }

  &:first-child {
    border-bottom: none;
  }
`;

export const Icon = styled.img`
  width: 35px;
  height: 35px;

  $:active {
    transform: scale(0.95);
  }
`;

export const Label = styled.span`
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #FC831A;
  border-radius: 50%;
  width: 25px;
  height: 25px;
  color: #fff;
  font-weight: 600;
  font-size: 12px;
  top: 6px;
  right: 4px;
`;

export const Clickable = styled.div`
  position: relative;
  width: 50px;
  height: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 3px;
  background-color: ${props => (props.isActive ? '#fff' : '#54585B')};

  &:after {
    content: '';
    position: absolute;
    right: -13px;
    top: 0;
    width: ${props => (props.isActive ? '15px' : 0)};
    height: 50px;
    background-color: ${props => (props.isActive ? '#fff' : '#54585B')};
  }

  &:hover {
    background-color: #fff;
  }
`;
