import styled from 'styled-components';
import { Link } from 'react-router-dom';

export const StyledRate = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 4%;
  box-sizing: border-box;
  margin: 0;
  background-color: ${({ theme }) => theme.primaryWhite};
  border: 1px solid ${({ theme }) => theme.primaryGrey};
  margin-left: -1px;
  margin: ${({ index }) => (index === 0 ? '0 8px 0 0' : null)};
  text-align: center;
  position: relative;
  cursor: ${({ effect }) => (effect ? 'pointer' : null)};

  &:hover {
    background-color: ${({ effect, theme }) => (effect ? theme.lightGrey : null)};
  }

  &:focus {
    position: relative;
    z-index: 99;
  }

  &:active {
    background-color: ${({ effect }) => (effect ? '#c2c2c2' : null)};
  }
`;

export const RowContent = styled.div`
  display: flex;
  flex-direction: row;
  height: 70px;
  box-sizing: border-box;
  flex-shrink: 0;
  margin-top: -1px;
`;

export const TaskName = styled(Link)`
  box-sizing: border-box;
  text-decoration: none;
  display: flex;
  flex: 1;
  background-color: ${({ theme }) => theme.primaryWhite};
  border: 1px solid ${({ theme }) => theme.primaryGrey};
  flex-shrink: 0;
  min-width: 50%;
  margin-right: 8px;
  color: ${({ theme }) => theme.darkGrey};

  &:last-of-type {

  }

  &:hover {
    background-color: ${({ theme }) => theme.lightGrey};
  }

  &:focus {
    position: relative;
    z-index: 99;
  }

  &:active {
    background-color: #c2c2c2;
  }
`;

export const Caption = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-left: 20px;
  padding-right: 5px;
  overflow: hidden;
`;

export const Title = styled.h3`
  margin: 0;
  font-size: 14px;
  font-weight: 600;
  overflow: hidden;
  max-height: 5ex;
  line-height: 2.5ex;
  text-overflow: ellipsis;
`;

export const SubTitle = styled.h4`
  margin: 0;
  margin-left: 10px;
  font-size: 16px;
  font-weight: normal;
  max-height: 2.5ex;
  line-height: 2.5ex;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
`;

export const Rate = styled.span`
  margin-top: 10px;
  font-size: 14px;
  font-weight: normal;
`;

export const Scale = styled.h3`
  width: 85%;
  height: ${({ height }) => height || '0'};
  background-color: ${({ grade, theme }) => theme.grades[grade] || 'black'};
  margin: 4px auto;
`;
