import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  height: calc(100% - 70px);
  justify-content: space-between;
`;

export const TableContainer = styled.div`
  width: 100%;
  height: calc(100% - 70px);
`;
