import styled from 'styled-components';
import { NavLink } from 'react-router-dom';
import Button from 'components/Button';

export const StyledButton = styled(Button).attrs({
  tabIndex: -1,
  color: 'transparent',
})`
  padding: 10px 15px;
  text-transform: none;
  font-size: initial;
  font-weight: initial;

  &:focus {
    outline-style: none;
  }
`;

export const StyledNavLink = styled(NavLink).attrs({
  activeStyle: {
    backgroundColor: '#ffffff',
  },
})`
  display: flex;
  justify-content: center;
  text-decoration: none;
  padding: 7px;
  margin-top: 10px;
  margin-left: 5px;
  border: 1px solid ${({ theme }) => theme.primaryGrey};
  border-bottom: none;
  width: 150px;
`;
