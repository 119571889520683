import React from 'react';
import PropTypes from 'prop-types';

import Button from 'components/Button';

import RosterList from './components/RosterList';
import { Container, Header, EmptyRoster, ButtonsBlock } from './styled';

const rosterList = [
  {
    firstname: 'Ben',
    lastname: 'Abertany',
  },
  {
    firstname: 'Jacob',
    lastname: 'Borgman',
  },
  {
    firstname: 'Nichole',
    lastname: 'Connelly',
  },
  {
    firstname: 'Christopher',
    lastname: 'Dallman',
  },
  {
    firstname: 'Patty',
    lastname: 'Dansford',
  },
];

const Roster = props => (
  <Container isOpened={props.isOpened}>
    <Header>
      My Students
      <ButtonsBlock>
        <Button tabIndex={props.isOpened ? 0 : -1}>Browse groups</Button>
        <Button tabIndex={props.isOpened ? 0 : -1}>Create</Button>
      </ButtonsBlock>
    </Header>
    {
      rosterList.length ?
        <RosterList roster={rosterList} /> :
        <EmptyRoster>The roster is empty.</EmptyRoster>
    }
  </Container>
);


Roster.propTypes = {
  isOpened: PropTypes.bool,
};

Roster.defaultProps = {
  isOpened: false,
};

export default Roster;
