import styled from 'styled-components';

export const Container = styled.div`
  width: 100%;
  margin-bottom: 25px;
`;

export const Title = styled.div`
  margin-bottom: 10px;
  font-size: 13px;
  font-weight: 600;
  color: #666;
`;
