import React from 'react';
import PropTypes from 'prop-types';

import { Container, Row, Title } from './styled';

const Information = ({ student, task }) => (
  <Container>
    <Row>
      <Title>Student</Title>
      {student.last_name}, {student.first_name}&nbsp;{student.middle_name}
    </Row>
    <Row>
      <Title>SKB</Title>
      {task.category} - {task.title}
    </Row>
  </Container>
);

Information.propTypes = {
  student: PropTypes.object.isRequired,
  task: PropTypes.object.isRequired,
};

export default Information;
