import { createSelector } from 'reselect';
import update from 'immutability-helper';
import findIndex from 'lodash/findIndex';

import { formatArtifact } from './formatters';

export const getTasksList = createSelector(
  (activity, scale, tasks) => tasks.tasks,
  (activity, scale) => scale.showPriority,
  activity => activity.activity.data_collections_sets_items,
  (tasksList, showPriority, setItems) => {
    let result = [...tasksList];
    setItems.forEach((item) => {
      let index = findIndex(tasksList, task => +task.id === +item);
      while (index !== -1) {
        result = update(result, { [index]: { checked: { $set: true } } });
        index = findIndex(tasksList, task => +task.id === +item, index + 1);
      }
    });
    return showPriority ? result.filter(item => item.separator || (item.priority && +item.priority)) : result;
  },
);

export const getActivityStudents = createSelector(
  (activity, students) => students.students,
  activity => activity.activity.students_enrollments,
  (students, enrollments) => enrollments.map(id => ({
    artifacts: [],
    ...students[findIndex(students, student => +student.student_enrollment_id === +id)],
  })),
);

export const getActivityTasks = createSelector(
  (tasks, activity) => activity.activity.data_collections_sets_items,
  tasks => tasks.tasks,
  tasks => tasks.sets.byId,
  tasks => tasks.sets.listing,
  (taskIds, tasksList, setsById, setsListing) => {
    const tasks = taskIds.reduce((result, id) => {
      let index = findIndex(tasksList, task => +task.id === +id);
      while (index !== -1) {
        result.push({ ...tasksList[index] });
        index = findIndex(tasksList, task => +task.id === +id, index + 1);
      }
      return result;
    }, []);
    return setsById.reduce((result, id) => {
      const chunk = tasks.filter(task => +task.data_collection_sets_parent_id === +id);
      if (chunk.length) {
        result.push({ separator: `${setsListing[id].label}` });
        chunk.forEach(task => result.push(task));
      }
      return result;
    }, []);
  },
);

export const getArtifacts = createSelector(
  activity => activity.activity.data_collections_activities_artifacts,
  activity => activity.data_collections_activities_artifacts,
  (byId, listing) => byId.map(id => ({ ...formatArtifact(listing[id]) })),
);
