import React, { Component } from 'react';
import PropTypes from 'prop-types';

import { Color, Label } from './styled';

class GroupColor extends Component {
  static propTypes = {
    color: PropTypes.object.isRequired,
    onInputChange: PropTypes.func.isRequired,
    selectedColorKey: PropTypes.string.isRequired,
  }

  constructor(props) {
    super(props);

    this.handleColorChange = this.handleColorChange.bind(this);
  }

  shouldComponentUpdate(nextProps) {
    return (nextProps.selectedColorKey !== this.props.selectedColorKey);
  }

  handleColorChange() {
    this.props.onInputChange('color', this.props.color.key);
  }

  render() {
    const selected = this.props.color.key === this.props.selectedColorKey;
    return (
      <Label
        color={this.props.color.code}
        selected={selected}
      >
        <Color
          name="color"
          type="radio"
          defaultChecked={selected}
          aria-checked={selected}
          aria-label={this.props.color.title}
          tabindex="0"
          onClick={this.handleColorChange}
        />
      </Label>
    );
  }
}

export default GroupColor;
