import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import { getAssessmentsList } from 'redux/modules/assessments-list';
import Preloader from 'components/Preloader';
import List from './components/List';
import { Container, LogoImage, Row, Text, Wrapper } from './styled';

class AssessmentsList extends PureComponent {
  static propTypes = {
    getAssessmentsList: PropTypes.func.isRequired,
    list: PropTypes.arrayOf(PropTypes.object).isRequired,
    loading: PropTypes.bool.isRequired,
    profile: PropTypes.object.isRequired,
  }

  componentDidMount() {
    this.props.getAssessmentsList();
  }

  render() {
    const { list, loading, profile } = this.props;

    return (
      <Container role="main" aria-label="Assessment list">
        <Wrapper>
          <Row role="img" aria-label="Logo container">
            <LogoImage aria-label="Ready for kindergarten logo" />
          </Row>
          <Text>
            <h3>{profile.first_name} {profile.last_name}</h3>
            <h4>Welcome</h4>
            <p>Get started by selecting an assessment from the list below.</p>
          </Text>
          {loading ? <Preloader loading={loading} /> : <List list={list} />}
        </Wrapper>
      </Container>
    );
  }
}

const mapStateToProps = ({ assessments: { list, loading }, auth }) => ({
  list,
  loading,
  profile: auth.user,
});

export default connect(mapStateToProps, { getAssessmentsList })(AssessmentsList);
