export const formatGroupCreate = (group, studentsIds) => ({
  ...group,
  data_collections_student_groups_student_enrollments: studentsIds.map(id => ({
    student_enrollment_id: id,
  })),
});

export const formatGroupEdit = (group, studentsIds) => {
  const formatted = {};

  formatted.id = group.id;
  formatted.label = group.label;
  formatted.color = group.color;
  formatted.data_collection_parent_id = group.data_collection_parent_id;
  formatted.data_collections_student_groups_student_enrollments = studentsIds.map(id => ({
    student_enrollment_id: id,
  }));

  return formatted;
};
