import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  flex-shrink: 0;
  height: 70px;
  padding: 10px 20px;
  box-sizing: border-box;
  background-color: #fff;
  justify-content: space-between;
  color: #666;
  cursor: pointer;
  align-items: center;
  border-top: 1px solid #c8c8c8;
  border-bottom: 1px solid #c8c8c8;
`;

export const TextBlock = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
  justify-content: center;
`;

export const ButtonBlock = styled.div`
  display: flex;
  color: #666;
`;

export const TaskName = styled.div`
  display: flex;
  font-size: 12px;
  font-weight: 600;
  margin-bottom: 2px;
  text-transform: uppercase;
`;

export const TaskCategory = styled.div`
  display: flex;
  font-size: 16px;
`;
