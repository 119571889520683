import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';

import Button from 'components/Button';
import Modal from 'components/Modal';
import NotesList from 'components/NotesList';
import Searchbar from 'components/Searchbar';

import { Container, Footer } from './styled';

class BrowseNotesModal extends PureComponent {
  static propTypes = {
    addSelected: PropTypes.func.isRequired,
    onRequestClose: PropTypes.func.isRequired,
    notes: PropTypes.array.isRequired,
  }

  constructor(props) {
    super(props);

    this.onSearchChange = this.onSearchChange.bind(this);
    this.add = this.add.bind(this);
    this.close = this.close.bind(this);
    this.selectNote = this.selectNote.bind(this);
  }

  state = {
    search: '',
    selectedNotes: [],
  }

  onSearchChange(event) {
    this.setState({ search: event.target.value });
  }

  selectNote(id) {
    const selected = [...this.state.selectedNotes];

    if (!selected.includes(id)) {
      selected.push(id);
    } else {
      const index = selected.indexOf(id);
      selected.splice(index, 1);
    }

    this.setState({ selectedNotes: selected });
  }

  add() {
    const { addSelected, notes, onRequestClose } = this.props;
    let comment = '';
    this.state.selectedNotes.forEach((item) => {
      const { description } = notes.filter(note => +note.id === +item)[0];
      comment = `${comment + description}\n`;
    });

    this.setState({ selectedNotes: [] });
    addSelected(comment);
    onRequestClose();
  }

  close() {
    this.setState({ selectedNotes: [] });
    this.props.onRequestClose();
  }

  render() {
    const { search, selectedNotes } = this.state;

    return (
      <Modal {...this.props} headerTitle={<Searchbar onChange={this.onSearchChange} />} size="lg">
        <Container>
          <NotesList search={search} selected={selectedNotes} onNoteSelect={this.selectNote} />
        </Container>
        <Footer>
          <Button color="#70a823" onClick={this.add}>Add Note</Button>
          <Button color="#ffffff" textColor="#777777" border="1px solid #cccccc" onClick={this.close}>cancel</Button>
        </Footer>
      </Modal>
    );
  }
}

const mapStateToProps = ({ notes }) => ({
  notes: notes.notes,
});

export default withRouter(connect(mapStateToProps)(BrowseNotesModal));
