import React from 'react';
import PropTypes from 'prop-types';

import { Container, Icon, Text } from './styled';

const Button = ({ children, ...rest }) => (
  <Container {...rest}>
    <Text>{children}</Text>
    {rest.icon &&
      <Icon
        icon={rest.icon}
        content={children}
        iconSize={rest.size}
        iconAlign={rest.iconAlign}
      />
    }
  </Container>
);


Button.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]),
  iconAlign: PropTypes.oneOf(['left', 'right']),
  size: PropTypes.oneOf(['lg', 'md', 'mdIcon', 'sm']),
  icon: PropTypes.string,
  color: PropTypes.string,
  textColor: PropTypes.string,
  border: PropTypes.string,
  onClick: PropTypes.func,
};

Button.defaultProps = {
  color: '#03699A',
  textColor: '#fff',
  onClick: () => {},
  iconAlign: 'left',
  border: 'none',
  size: 'md',
  icon: '',
  children: null,
};

export default Button;
