import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  position: relative;
  height: 155px;
  box-sizing: border-box;
  flex: 1;
  list-style-type: none;
  padding: ${props => (props.withBorder ? '10px' : 0)};
  margin: 15px;
  flex-direction: row;
  border: ${props => (props.withBorder ? '1px solid #ccc' : 0)};
  overflow: hidden;
`;

export const ArtifactContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;
  width: 250px;
  flex-shrink: 0;
  height: 100%;
  cursor: ${({ downloadable }) => (downloadable ? 'pointer' : 'auto')};
`;

export const ArtifactImage = styled.img`
  background: #eee;
  height: 100%;
  max-height: 155px;
`;

export const Description = styled.div`
  display: flex;
  flex-direction: column;
  min-width: 0;
  width: 100%;
  word-wrap: break-word;
  margin-left: 30px;
`;

export const Comment = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  font-size: 16px;
  overflow-y: auto;
  color: ${({ theme }) => theme.darkGrey};
  word-wrap: break-word;
  height: 90px;
  h4 {
    font-weight: 600;
    margin: 0;
    color: #000;
    line-height: 1;
  }
`;

export const Info = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  flex: 1;
  font-size: 12px;
  color: ${({ theme }) => theme.darkGrey};

  h4 {
    font-weight: 600;
    margin: 0;
    color: #000;
    line-height: 1;
  }
`;

export const DeleteButton = styled.div`
  position: absolute;
  top: 0;
  right: 0;
  padding: 0 7px;
`;
