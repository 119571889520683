import styled from 'styled-components';

export const Container = styled.div`
  padding: 0 15px;
  max-height: 90vh;
  overflow-y: auto;
`;

export const Styled = styled.div`

`;
