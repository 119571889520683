const getFileBase64 = file => new Promise((resolve) => {
  const fileReader = new FileReader();

  fileReader.onload = () => {
    resolve(fileReader.result);
  };

  fileReader.readAsDataURL(file);
});

export default getFileBase64;
