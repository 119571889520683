import styled from 'styled-components';

const Container = styled.div`
  display: flex;
  flex-direction: row;
  box-sizing: border-box;
  padding: 10px 0;
  border-top: 1px solid ${({ theme }) => theme.primaryGrey};
  overflow-x: auto;
`;

export default Container;
