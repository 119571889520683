import React from 'react';
import PropTypes from 'prop-types';

import { Container, Label, Error } from './styled';
import TextInput from 'components/TextInput';

const GroupName = ({ groupName, onInputChange, errorMessage }) => (
  <Container>
    <Label>Name</Label>
    <TextInput
      aria-label="Group name"
      value={groupName}
      placeholder="Enter text..."
      onChange={event => onInputChange('label', event.target.value)}
    />
    {errorMessage && <Error>{errorMessage}</Error>}
  </Container>
);

GroupName.propTypes = {
  errorMessage: PropTypes.string.isRequired,
  groupName: PropTypes.string.isRequired,
  onInputChange: PropTypes.func.isRequired,
};

export default GroupName;
