import { normalize, denormalize, schema } from 'normalizr';

const student = new schema.Entity('students_enrollments');
const set = new schema.Entity('data_collections_sets_items');
const artifact = new schema.Entity('data_collections_activities_artifacts');
const activity = {
  students_enrollments: [student],
  data_collections_sets_items: [set],
  data_collections_activities_artifacts: [artifact],
};

export const normalizeActivity = item => normalize(item, activity);
export const normalizeArtifacts = artifacts => normalize(artifacts, [artifact]);
export const denormalizeActivity = currentState => denormalize(currentState.activity, activity, {
  data_collections_activities_artifacts: currentState.data_collections_activities_artifacts || {},
  data_collections_sets_items: currentState.data_collections_sets_items || {},
  students_enrollments: currentState.students_enrollments || {},
});
