import React from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';

import Modal from 'components/Modal';
import FullScreenImage from 'components/FullScreenImage';
import { Comment, Container, Header, Icon, Info, Subheader, Text } from './styled';

const onDelete = () => {
  console.log('delete');
};

const CommentsModal = ({ data, ...rest }) => (
  <Modal {...rest} headerTitle={<Header><Icon />comments</Header>}>
    <Container>
      {
        data &&
          <Subheader>
            <div>{data.comment}</div>
            <div>
              {data.createdBy}
              | {moment(data.createdAt, 'M/D/YYYY - h:mm A').format('M/D/YYYY | h:mm A')}
            </div>
          </Subheader>
      }
      {data.comments && data.comments.map(item => (
        <Comment key={item.imageUrl}>
          <Text>
            <FullScreenImage url={item.imageUrl} info={item} onDelete={onDelete} onEdit={onDelete} />
            {item.text}
          </Text>
          {false && <Info>{item.author} | {item.date} | {item.time}</Info>}
        </Comment>
      ))}
    </Container>
  </Modal>
);

CommentsModal.propTypes = {
  data: PropTypes.object.isRequired,
};

export default CommentsModal;
