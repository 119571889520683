import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import { makeGetScoredStudents } from 'redux/modules/assessments-overview/selectors';
import { Container, Content, Score, StyledButton, TaskClass } from './styled';

class Item extends PureComponent {
  static propTypes = {
    onScoreClick: PropTypes.func.isRequired,
    scored: PropTypes.number.isRequired,
    studentsAmount: PropTypes.number.isRequired,
    task: PropTypes.object.isRequired,
  }

  constructor(props) {
    super(props);

    this.onScoreClick = this.onScoreClick.bind(this);
  }

  onScoreClick() {
    this.props.onScoreClick(this.props.task.id);
  }

  handleKeyDown = (event) => {
    const keyCodes = {
      up: 38,
      down: 40,
    };

    const prevTaskItem = event.target.previousElementSibling || '';
    const nextTaskItem = event.target.nextElementSibling || '';

    if (event.keyCode === keyCodes.down && nextTaskItem) {
      event.preventDefault();
      nextTaskItem.focus();
    }

    if (event.keyCode === keyCodes.up && prevTaskItem) {
      event.preventDefault();
      prevTaskItem.focus();
    }
  }

  render() {
    const { task, scored, studentsAmount } = this.props;
    const { label, taskClass } = task;

    return (
      <Container
        tabIndex={0}
        aria-label={`${label}. ${scored} of ${studentsAmount} rated`}
        onKeyDown={this.handleKeyDown}
      >
        <Content>
          <TaskClass>{taskClass}</TaskClass>
          <div>{label}</div>
        </Content>
        <Score>
          <div>{scored}/{studentsAmount} Rated</div>
          <StyledButton
            aria-label="Rate the task"
            size="sm"
            color="#70a823"
            onClick={this.onScoreClick}
          >
            Rate
          </StyledButton>
        </Score>
      </Container>
    );
  }
}

const makeMapStateToProps = () => {
  const getScoredStudents = makeGetScoredStudents();
  const mapStateToProps = ({ activity, assessmentsOverview, students }, { task }) => ({
    scored: getScoredStudents(activity, assessmentsOverview, students, task),
  });
  return mapStateToProps;
};

export default connect(makeMapStateToProps)(Item);
