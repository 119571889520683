import React from 'react';
import PropTypes from 'prop-types';
import { Container, Content, Text } from './styled';

const Title = ({
  children,
  className,
  text,
  ...rest
}) => (
  <Container className={className} {...rest}>
    <Content>
      <Text>{text}</Text>
      {children}
    </Content>
  </Container>
);

Title.defaultProps = {
  children: '',
  className: '',
};

Title.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.object),
    PropTypes.object,
    PropTypes.string,
  ]),
  className: PropTypes.string,
  text: PropTypes.string.isRequired,
};

export default Title;
