import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  justify-content: space-between;
  height: 70px;
  min-height: 70px;
  background: ${({ theme }) => (theme.primaryWhite)};
  padding: 15px 25px;
  box-sizing: border-box;
  border-bottom: 1px solid ${({ theme }) => (theme.primaryGrey)};
`;

export const TextBlock = styled.div`
  display: flex;
  color: ${({ theme }) => (theme.darkGrey)};
  flex-direction: column;
  justify-content: space-around;
`;

export const Time = styled.div`
  display: flex;
  font-size: 16px;
  text-transform: uppercase;
`;

export const ScoredBy = styled.div`
  display: flex;
  font-size: 12px;
  font-weight: 600;
`;

export const ButtonsBlock = styled.div`
  display: flex;
  width: 40%;
  justify-content: flex-end;
  align-items: center;

  button {
    margin: 0 15px;

    &:last-child {
      margin-right: 0;
    }
  }
`;

export const ArtifactsButton = styled.div`
  button {
    width: 60px;
  }
`;

