import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import update from 'immutability-helper';
import find from 'lodash/find';
import queryString from 'query-string';

import { compareWithSelected } from 'redux/modules/scale/helpers';
import { filterSelectedStudentsByGroup, filterStudentsByGroup } from 'redux/modules/students/formatters';
import { getStudentsScores } from 'redux/modules/students/selectors';
import { fetchActivities } from 'redux/modules/activities';

import Preloader from 'components/Preloader';
import Subheader from 'components/Subheader';
import Button from 'components/Button';

import TaskMaterialsModal from './components/TaskMaterialsModal';
import ShortDescription from './components/ShortDescription';
import FullDescription from './components/FullDescription';
import ExpandToggle from './components/ExpandToggle';
import Table from './components/Table';

import {
  Container,
  Title,
  ExpandContent,
  Scrollable,
  Footer,
} from './styled';

class TaskRates extends Component {
  static propTypes = {
    history: PropTypes.object.isRequired,
    fetchActivities: PropTypes.func.isRequired,
    scale: PropTypes.array.isRequired,
    match: PropTypes.object.isRequired,
    task: PropTypes.object.isRequired,
    tableData: PropTypes.array.isRequired,
    selectedStudentsDefault: PropTypes.array.isRequired,
    selectedGroupId: PropTypes.number.isRequired,
    studentsInGroup: PropTypes.array.isRequired,
    activityStudents: PropTypes.array.isRequired,
    activity: PropTypes.object.isRequired,
    loading: PropTypes.bool.isRequired,
  }

  constructor(props) {
    super(props);

    this.toggle = this.toggle.bind(this);
    this.openModal = this.openModal.bind(this);
    this.closeModal = this.closeModal.bind(this);
    this.goBack = this.goBack.bind(this);
    this.toCreateActivity = this.toCreateActivity.bind(this);
    this.selectStudent = this.selectStudent.bind(this);
  }

  state = {
    isExpanded: false,
    isModalOpened: false,
    selectedStudents: this.props.selectedStudentsDefault,
  }

  componentDidMount() {
    this.props.fetchActivities(this.props.match.params.collectionId);
  }

  selectStudent(id) {
    const selected = [...this.state.selectedStudents];
    if (id) {
      if (!selected.includes(id)) {
        selected.push(id);
      } else {
        const index = selected.indexOf(id);
        selected.splice(index, 1);
      }

      this.setState(update(this.state, {
        selectedStudents: { $set: selected },
      }));
    }
  }

  closeModal() {
    this.setState({ isModalOpened: false });
  }

  openModal() {
    this.setState({ isModalOpened: true });
  }

  toggle() {
    this.setState({ isExpanded: !this.state.isExpanded });
  }

  goBack() {
    const { collectionId } = this.props.match.params;

    if (this.props.activityStudents.length) {
      this.props.history.push(`/collections/${collectionId}/activities/${this.props.activity.id}/detail`);
    } else {
      this.props.history.push(`/collections/${collectionId}/assessments/overview`);
    }
  }

  toCreateActivity() {
    const { collectionId } = this.props.match.params;
    const { selectedGroupId, studentsInGroup } = this.props;

    const students = filterSelectedStudentsByGroup(this.state.selectedStudents, selectedGroupId, studentsInGroup);
    const selected = queryString.stringify(
      { selectedStudents: students },
      { arrayFormat: 'bracket' },
    );
    this.props.history.push(`/collections/${collectionId}/activities/create/?${selected}`);
  }

  render() {
    let tableDataWithSelected = [];

    if (!this.props.activityStudents.length) {
      const filtered = filterStudentsByGroup(
        this.props.tableData,
        this.props.selectedGroupId,
        this.props.studentsInGroup,
      );

      tableDataWithSelected = filtered.map((item) => {
        const student = { ...item };
        student.checked = this.state.selectedStudents.includes(student.student_enrollment_id);
        return student;
      });
    } else {
      tableDataWithSelected = this.props.tableData.filter(item => (
        this.props.activityStudents.includes(item.student_enrollment_id)
      ));
    }

    return (
      <Container>
        <Preloader loading={this.props.loading} />
        <Subheader module={this.props.activityStudents.length ? 'activities' : 'assessments'}>
          <Button icon="back" size="lg" color="transparent" onClick={this.goBack}>
            {
              this.props.activityStudents.length
                ? `${this.props.activity.label} - ${this.props.activity.end_date}`
                : 'task detail'
            }
          </Button>
        </Subheader>
        <Title>{this.props.task.group}</Title>
        <ExpandToggle isExpanded={this.state.isExpanded} toggle={this.toggle} task={this.props.task} />
        <Scrollable>
          <ExpandContent>
            {this.state.isExpanded
              ? <FullDescription
                openModal={this.openModal}
                grades={this.props.scale}
                description={this.props.task.description}
              />
              : <ShortDescription
                openModal={this.openModal}
                onChange={this.selectGrade}
                grades={this.props.scale}
                description={this.props.task.description}
              />
            }
          </ExpandContent>
          <Table
            data={tableDataWithSelected}
            selectedStudents={this.state.selectedStudents}
            grades={this.props.scale}
            activeColumns={this.state.activeColumns}
            selectStudent={this.selectStudent}
            activityStudents={this.props.activityStudents}
          />
        </Scrollable>
        {!this.props.activityStudents.length &&
          <Footer>
            With selected:
            <Button icon="plus" color="#70a823" onClick={this.toCreateActivity}>create activity</Button>
          </Footer>
        }
        <TaskMaterialsModal
          isOpen={this.state.isModalOpened}
          onRequestClose={this.closeModal}
          task={this.props.task}
        />
      </Container>
    );
  }
}

const mapStateToProps = ({
  students,
  scale,
  tasks,
  grades,
  activities,
  app,
}, props) => {
  let tableData = [];

  const currentTask = find(tasks.taskShortcuts, item => (+item.id === +props.match.params.taskId)) || tasks;
  const searchParam = queryString.parse(props.location.search).activity;
  const activity = activities.activitiesListing[searchParam] || {};

  if (students.students.length) {
    tableData = getStudentsScores({
      students,
      grades,
      currentTask,
      scale,
    });
  }

  const task = find(tasks.tasks, { id: +props.match.params.taskId });

  return {
    scale: compareWithSelected(
      scale.scale.filter(item => item.parent_id === +task.assessment_items_scales_directory_parent_id),
      scale.selectedLevels,
    ),
    task: find(tasks.taskShortcuts, item => (+item.id === +props.match.params.taskId)) || { description: '' },
    selectedGroupId: app.selectedGroupId,
    studentsInGroup: app.selectedStudentsIds,
    selectedStudentsDefault: students.list.map(item => item.student_enrollment_id),
    activityStudents: activity.students_enrollments || [],
    tableData,
    activity,
    loading: activities.loading,
  };
};

export default withRouter(connect(mapStateToProps, { fetchActivities })(TaskRates));
