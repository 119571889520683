import styled from 'styled-components';

export const Container = styled.div`
  height: 100%;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
`;

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-self: center;
  width: 90%;
  height: 95%;
  max-width: 1000px;
  margin-bottom: 30px;
`;

export const PageTitle = styled.h4`
  color: ${({ theme }) => theme.darkGrey};
  font-size: 18px;
  font-weight: 400;
  margin: 10px 0;
  text-align: center;
`;

export const ImageWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: ${({ theme }) => theme.lightGrey};
  height: 100%;
  width: 100%;
  max-width: 100%;
  max-height: calc(100% - 100px);

  @media (max-width: 576px) {
    max-height: calc(100% - 190px);
  }
`;

export const PreviewImage = styled.img`
  max-width: 100%;
  max-height: 100%;
  object-fit: contain;
`;

export const Error = styled.span`
  color: ${({ theme }) => theme.error};
  font-size: 16px;
  margin: 10px 0;
  height: 20px;
`;

export const ActionPanel = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;

  @media (max-width: 576px) {
    flex-direction: column;
  }
`;

export const ButtonWrapper = styled.div`
  height: 40px;
  max-width: 270px;
  width: 100%;
  margin: 0 40px;

  &:last-child {
    margin: 0;
  }

  &:first-child {
    margin: 0;
  }

  @media (max-width: 576px) {
    margin: 5px 0;
  }
`;
