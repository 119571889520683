import styled from 'styled-components';
import { Link } from 'react-router-dom';
import Button from 'components/Button';

export const StyledButton = styled(Button)`
  padding: 0 30px;
`;

export const StyledLink = styled(Link)`
  margin: 10px;
  text-decoration: none;
`;
