const groupColors =
  [
    {
      id: 0,
      code: '#f66955',
      title: 'light red',
      key: 'light-red',
    },
    {
      id: 1,
      code: '#f39c11',
      title: 'orange',
      key: 'orange',
    },
    {
      id: 2,
      code: '#00c0ef',
      title: 'sky blue',
      key: 'skyblue',
    },
    {
      id: 3,
      code: '#0073b6',
      title: 'dark blue',
      key: 'dark-blue',
    },
    {
      id: 4,
      code: '#3c8dbc',
      title: 'gray blue',
      key: 'gray-blue',
    },
    {
      id: 5,
      code: '#00a65a',
      title: 'green',
      key: 'green',
    },
    {
      id: 6,
      code: '#3acccc',
      title: 'blue green',
      key: 'blue-green',
    },
    {
      id: 7,
      code: '#006633',
      title: 'dark green',
      key: 'dark-green',
    },
    {
      id: 8,
      code: '#01ff70',
      title: 'light green',
      key: 'light-green',
    },
    {
      id: 9,
      code: '#cc3300',
      title: 'dark red',
      key: 'dark-red',
    },
    {
      id: 10,
      code: '#f012be',
      title: 'mov',
      key: 'mov',
    },
    {
      id: 11,
      code: '#9a33cc',
      title: 'purple',
      key: 'purple',
    },
  ];

export default groupColors;
