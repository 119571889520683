import styled from 'styled-components';

export const Container = styled.div`
  width: 100%;
  display: flex;
  flex: 1;
  align-items: center;
  min-height: 70px;
`;

export const Thumbnail = styled.div`
  display: flex;
  flex-shrink: 0;
  justify-content: center;
  align-items: center;
  width: 50px;
  height: 50px;
  background-color: ${props => (props.theme.grades[props.grade])}
`;

export const Round = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 30px;
  height: 30px;
  border-radius: 50%;
  background-color: rgba(0,0,0,0.6);
  color: ${({ theme }) => (theme.primaryWhite)};
`;

export const Text = styled.div`
  display: flex;
  flex: 1;
  margin-left: 25px;
  flex-direction: column;
  justify-content: space-around;
  color: ${({ theme }) => (theme.darkGrey)};
`;

export const Title = styled.div`
  display: flex;
`;

export const Description = styled.div`
  display: flex;
`;
