import React from 'react';
import PropTypes from 'prop-types';

import BrowseNotesModal from 'components/Modals/BrowseNotesModal';

import { Container, TitleColumn, StyledButton, TextArea } from './styled';

const Comment = ({
  isOpen,
  commentValue,
  openModal,
  closeModal,
  handleChange,
  addSelected,
}) => (
  <Container>
    <TitleColumn>
      Note (Comment)
      <StyledButton onClick={openModal}>Browse Notes</StyledButton>
    </TitleColumn>
    <TextArea
      aria-label="Add comment text area"
      placeholder="Add a comment..."
      value={commentValue}
      onChange={handleChange}
    />

    <BrowseNotesModal
      isOpen={isOpen}
      onRequestClose={closeModal}
      addSelected={addSelected}
    />
  </Container>
);

Comment.propTypes = {
  commentValue: PropTypes.string.isRequired,
  isOpen: PropTypes.bool.isRequired,
  openModal: PropTypes.func.isRequired,
  closeModal: PropTypes.func.isRequired,
  handleChange: PropTypes.func.isRequired,
  addSelected: PropTypes.func.isRequired,
};

export default Comment;
