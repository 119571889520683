import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Route, Switch, withRouter } from 'react-router-dom';
import { connect } from 'react-redux';

import Header from 'components/Header';
import Preloader from 'components/Preloader';

import Activities from 'modules/activities';
import Assessments from 'modules/assessments';
import Groups from 'modules/groups';
import Students from 'modules/students';
import Rating from 'modules/rating';

import { fetchData } from 'redux/modules/app';

import { Content } from './styled';

class WithCollectionId extends Component {
  static propTypes = {
    fetchData: PropTypes.func.isRequired,
    match: PropTypes.object.isRequired,
    loading: PropTypes.bool.isRequired,
  };

  componentDidMount() {
    this.props.fetchData(this.props.match.params.collectionId);
  }

  componentWillUpdate(nextProps) {
    const oldCollectionId = this.props.match.params.collectionId;
    const newCollectionId = nextProps.match.params.collectionId;

    if (oldCollectionId !== newCollectionId) {
      this.props.fetchData(newCollectionId);
    }
  }

  render() {
    const { loading } = this.props;

    if (loading) {
      return (
        <Content>
          <Header />
          <Preloader loading={loading} />
        </Content>
      );
    }

    return (
      <Content>
        <Header />
        <Switch>
          <Route path="/collections/:collectionId/assessments" component={Assessments} />
          <Route path="/collections/:collectionId/activities" component={Activities} />
          <Route path="/collections/:collectionId/groups" component={Groups} />
          <Route path="/collections/:collectionId/students" component={Students} />
          <Route path="/collections/:collectionId/rating" component={Rating} />
        </Switch>
      </Content>
    );
  }
}

const mapStateToProps = ({ app: { loading } }) => ({
  loading,
});

export default connect(mapStateToProps, { fetchData })(withRouter(WithCollectionId));
