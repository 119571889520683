import http from '../../../http';
import formatAssessmentsOverview from './formatters';
import normalizeTasksStudents from './normalizers';

const FETCH_ASSESSMENTS_SUCCESS = 'rfc/assessments-overview/FETCH_ASSESSMENTS_SUCCESS';
const FETCH_ASSESSMENTS_FAILURE = 'rfc/assessments-overview/FETCH_ASSESSMENTS_FAILURE';
const FETCH_ASSESSMENTS_REQUEST = 'rfc/assessments-overview/FETCH_ASSESSMENTS_REQUEST';

const receiveAssessments = (groupTasks, tasksStudents) => ({
  type: FETCH_ASSESSMENTS_SUCCESS,
  payload: { groupTasks, tasksStudents },
});
const failedAssessments = payload => ({ type: FETCH_ASSESSMENTS_FAILURE, payload });
const requestAssessments = () => ({ type: FETCH_ASSESSMENTS_REQUEST });

const initialState = {
  groupTasks: [],
  tasksStudents: null,
  error: '',
  loading: true,
};

export default function reducer(state = initialState, action = {}) {
  switch (action.type) {
    case FETCH_ASSESSMENTS_SUCCESS:
      return {
        ...state,
        ...{
          groupTasks: action.payload.groupTasks,
          tasksStudents: action.payload.tasksStudents,
          loading: false,
        },
      };
    case FETCH_ASSESSMENTS_FAILURE:
      return { ...state, ...{ error: action.payload, loading: false } };
    case FETCH_ASSESSMENTS_REQUEST:
      return { ...state, ...{ loading: true } };
    default:
      return state;
  }
}

export const fetchAssessmentsOverview = collectionId => (dispatch, getState) => {
  dispatch(requestAssessments());

  http.get(`en/api/v1/user/collections/${collectionId}/overview.json`)
    .then((response) => {
      const tasksStudents = normalizeTasksStudents(response.data);
      const assessments = formatAssessmentsOverview(getState().tasks, getState().students.students, response.data);
      dispatch(receiveAssessments(assessments, tasksStudents));
    })
    .catch(error => dispatch(failedAssessments(error)));
};
