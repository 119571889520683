import React from 'react';
import PropTypes from 'prop-types';

import withKeyDown from 'components/withKeyDown';

import { Container, Item } from './styled';

const ItemWithKeyDown = withKeyDown(Item, 'Both');

const Row = ({
  rowIndex,
  data,
  columns,
  isExpanded,
}) => {
  const onClick = (cell) => {
    const handler = data[rowIndex].disabled && !isExpanded ?
      null :
      cell.onClick || (cell.component && cell.component.props.onClick);
    if (handler) {
      handler(data[rowIndex]);
    }
  };

  const hasProperty = Object.prototype.hasOwnProperty;

  return (
    <Container disabled={data[rowIndex].disabled} isExpanded={isExpanded}>
      {columns.map((column, columnIndex) => (
        <ItemWithKeyDown
          key={columnIndex}
          tabIndex={(column.clickable || hasProperty.call(data[rowIndex][column.field], 'checked')) ? 0 : -1}
          isClickable={column.clickable}
          onClick={() => onClick(column)}
          columnWidth={column.width}
          primaryWidth={column.primaryWidth}
          aria-label="table cell"
          isExpanded={isExpanded}
        >
          {(typeof data[rowIndex][column.field] !== 'object') ?
            data[rowIndex][column.field] :
            React.cloneElement(column.component, data[rowIndex][column.field])
          }
        </ItemWithKeyDown>
      ))}
    </Container>
  );
};

Row.propTypes = {
  data: PropTypes.arrayOf(PropTypes.object).isRequired,
  columns: PropTypes.arrayOf(PropTypes.object).isRequired,
  rowIndex: PropTypes.number.isRequired,
  isExpanded: PropTypes.bool.isRequired,
};

export default Row;
