import React, { Component } from 'react';
import PropTypes from 'prop-types';

import Button from 'components/Button';
import CommentsModal from 'components/Modals/CommentsModal';

import ButtonRating from 'modules/students/pages/Profile/components/ButtonRating';
import { Container, TextBlock, Time, ScoredBy, ArtifactsButton, ButtonsBlock } from './styled';

class Item extends Component {
  constructor(props) {
    super(props);

    this.openModal = this.openModal.bind(this);
    this.closeModal = this.closeModal.bind(this);
  }

  state = {
    isModalOpened: false,
  };

  openModal() {
    this.setState({
      isModalOpened: true,
    });
  }

  closeModal() {
    this.setState({
      isModalOpened: false,
    });
  }

  render() {
    const { comments } = this.props.data;
    const isShowButton = comments.comment || (comments.comments && comments.comments.length);
    return (
      <Container>
        <TextBlock>
          <Time>
            {this.props.data.time}
          </Time>
          <ScoredBy>
            Scored by: {this.props.data.scoredBy}
          </ScoredBy>
        </TextBlock>
        <ButtonsBlock>
          {isShowButton ?
            <ArtifactsButton>
              <Button
                icon="file"
                iconAlign="right"
                aria-label="open task comments"
                onClick={this.openModal}
              >
                {comments.comments.length ? comments.comments.length : ''}
              </Button>
            </ArtifactsButton>
            : null
          }
          <ButtonRating grade={this.props.data.rating} aria-label="rating">
            {this.props.data.rating}
          </ButtonRating>
          { /* <Button icon="delete" aria-label="delete row" color="transparent" /> */ }
        </ButtonsBlock>
        <CommentsModal
          isOpen={this.state.isModalOpened}
          onRequestClose={this.closeModal}
          data={this.props.data.comments}
        />
      </Container>
    );
  }
}

Item.propTypes = {
  data: PropTypes.shape({
    time: PropTypes.string.isRequired,
    scoredBy: PropTypes.string.isRequired,
    comments: PropTypes.object.isRequired,
    rating: PropTypes.string.isRequired,
  }).isRequired,
};

export default Item;
