import moment from 'moment';

export const formatNoteForPost = note => ({
  ...note,
  observation_date: note.observation_date.unix(),
});

export const formatNotes = list => list.reverse().map((note) => {
  const date = moment.unix(+note.observation_date);

  return {
    ...note,
    date: date.format('M/D/YYYY'),
    time: date.format('h:mm A'),
  };
});
