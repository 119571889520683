import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  height: calc(100% - 80px);
  flex-shrink: 0;
  overflow-y: auto;
`;

export const Title = styled.div`
  width: 150px;
  font-weight: 600;
  flex-shrink: 0;
  align-self: center;
`;

export const ButtonsBlock = styled.div`
  display: flex;
  margin-top: 10px;
  padding: 0 30px;
  flex-shrink: 0;

  button {
    margin-right: 20px;
  }
`;

export const DatePickerContainer = styled.div`
  display: flex;
  padding: 0 30px;
  height: 36px;
  margin-top: 15px;
  flex-shrink: 0;
`;
