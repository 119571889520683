import styled from 'styled-components';
import { Link } from 'react-router-dom';
import Button from 'components/Button';
import Separator from './components/Separator';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
`;

export const Content = styled.ul`
  margin: 0;
  padding: 0;
  overflow-y: auto;
`;

export const StyledButton = styled(Button)`
  text-transform: uppercase;
`;

export const StyledLink = styled(Link)`
  text-decoration: none;
  margin-left: auto;
`;

export const StyledSeparator = styled(Separator)`
  background-color: ${({ theme }) => theme.lightGrey};
  border-top: none;
`;
