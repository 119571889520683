import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import { selectStudent } from 'redux/modules/activity';
import StudentsListTable from 'components/StudentsListTable';
import Title from 'components/Title';
import SelectGroup from 'components/SelectGroup';
import { Container, List } from './styled';

class Students extends PureComponent {
  static propTypes = {
    selectedStudents: PropTypes.arrayOf(PropTypes.number).isRequired,
    groupStudents: PropTypes.arrayOf(PropTypes.number).isRequired,
    selectStudent: PropTypes.func.isRequired,
  }

  render() {
    const { groupStudents, selectedStudents } = this.props;

    return (
      <Container>
        <Title tabIndex={0} id="activity-students-groups" text="Select Students">
          <SelectGroup />
        </Title>
        <List id="activity-students-list" tabIndex={0} aria-label="Students list">
          <StudentsListTable
            groupStudents={groupStudents}
            selectedStudents={selectedStudents}
            isExpanded={false}
            onStudentClick={this.props.selectStudent}
            onStudentSelect={this.props.selectStudent}
          />
        </List>
      </Container>
    );
  }
}

const mapStateToProps = ({ activity, app }) => ({
  groupStudents: app.selectedStudentsIds,
  selectedStudents: activity.activity.students_enrollments,
});

export default connect(mapStateToProps, { selectStudent })(Students);
