import styled from 'styled-components';

export const Form = styled.form`
  position: relative;
  width: 100%;
  input {
    margin-bottom: 20px;
  }

  button {
    width: 100%;
    height: 58px;
  }
`;

export const Errors = styled.div`
  position: absolute;
  font-size: 18px;
  width: 100%;
  display: flex;
  justify-content: center;
  color: #f00;
  bottom: -35px;
`;
