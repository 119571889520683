import React from 'react';
import PropTypes from 'prop-types';
import Container from './styled';

const BaseFooter = ({ children, className }) => (
  <Container className={className}>
    {children}
  </Container>
);

BaseFooter.defaultProps = {
  children: '',
  className: '',
};

BaseFooter.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.object),
    PropTypes.object,
    PropTypes.string,
  ]),
  className: PropTypes.string,
};

export default BaseFooter;
