import React, { Component } from 'react';
import PropTypes from 'prop-types';

import Checkbox from 'components/Checkbox';
import { Container, Content, TaskClass } from './styled';

export default class Item extends Component {
  static propTypes = {
    chooseStudents: PropTypes.bool.isRequired,
    onChange: PropTypes.func.isRequired,
    task: PropTypes.object.isRequired,
  }

  constructor(props) {
    super(props);

    this.handleKeyDown = this.handleKeyDown.bind(this);
    this.onCheckboxClick = this.onCheckboxClick.bind(this);
  }

  shouldComponentUpdate(nextProps) {
    return (nextProps.task !== this.props.task);
  }

  onCheckboxClick() {
    this.props.onChange(this.props.task.id);
  }

  handleKeyDown(event) {
    const keyCodes = {
      tab: 9,
      space: 32,
      up: 38,
      down: 40,
    };

    const prevParentTaskItem = event.target.parentElement.previousElementSibling;
    const nextParentTaskItem = event.target.parentElement.nextElementSibling;
    let prevTaskItem = '';
    let nextTaskItem = '';
    if (prevParentTaskItem) {
      prevTaskItem = prevParentTaskItem.tagName === 'LABEL' ?
        prevParentTaskItem.firstElementChild : prevParentTaskItem;
    }
    if (nextParentTaskItem) {
      nextTaskItem = nextParentTaskItem.tagName === 'LABEL' ?
        nextParentTaskItem.firstElementChild : nextParentTaskItem;
    }

    if (event.keyCode === keyCodes.space) {
      event.preventDefault();
      this.props.onChange(this.props.task.id);
    }

    if (event.keyCode === keyCodes.down && nextTaskItem) {
      event.preventDefault();
      nextTaskItem.focus();
    }

    if (event.keyCode === keyCodes.up && prevTaskItem) {
      event.preventDefault();
      prevTaskItem.focus();
    }

    if (event.keyCode === keyCodes.tab) {
      event.preventDefault();
      if (this.props.chooseStudents) {
        document.getElementById('activity-students-groups').focus();
      } else {
        document.getElementById('activity-description').focus();
      }
    }

    if (event.shiftKey && event.keyCode === keyCodes.tab) {
      event.preventDefault();
      document.getElementById('task-list').focus();
    }
  }

  render() {
    const {
      id,
      checked,
      label,
      taskClass,
    } = this.props.task;

    return (
      <label htmlFor={`task${id}`}>
        <Container
          tabIndex={0}
          role="checkbox"
          aria-checked={checked}
          aria-label={`${label} - ${checked ? 'checked' : 'unchecked'}`}
          onKeyDown={this.handleKeyDown}
        >
          <Checkbox id={`task${id}`} checked={checked} onClick={this.onCheckboxClick} />
          <Content>
            <TaskClass>{taskClass}</TaskClass>
            <div>{label}</div>
          </Content>
        </Container>
      </label>
    );
  }
}
