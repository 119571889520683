import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import List from './components/List';
import { Container, Header, NoNotifications } from './styled';

const Notifications = props => (
  <Container isOpened={props.isOpened}>
    <Header>
      Notifications
    </Header>
    {
      props.notifications.length ?
        <List notifications={props.notifications} /> :
        <NoNotifications>You have no notifications.</NoNotifications>
    }
  </Container>
);

Notifications.propTypes = {
  isOpened: PropTypes.bool,
  notifications: PropTypes.arrayOf(PropTypes.object).isRequired,
};

Notifications.defaultProps = {
  isOpened: false,
};

const mapStateToProps = state => ({ notifications: state.notifications });

export default connect(mapStateToProps)(Notifications);
