import { createSelector } from 'reselect';
import toNumber from 'lodash/toNumber';
import intersectionBy from 'lodash/intersectionBy';

import { selectStudentsFromGroup } from 'redux/modules/assessments-overview/formatters';

export const getPriorityTasks = createSelector(
  (groupTasks, scale) => scale.showPriority,
  groupTasks => groupTasks,
  (showPriority, groupTasks) => (
    showPriority ? groupTasks.filter(item => (typeof item === 'string' || item.task.priority)) : groupTasks
  ),
);

export const getSelectedAssessments = createSelector(
  (app, assessmentsOverview) => assessmentsOverview.groupTasks,
  app => app.selectedStudentsIds,
  app => app.selectedGroupId,
  (groupTasks, selectedStudentsIds, selectedGroupId) => (
    selectedGroupId !== -1 ? selectStudentsFromGroup(groupTasks, selectedStudentsIds, selectedGroupId) : groupTasks
  ),
);

export const getSeparators = createSelector(
  assessmentsOverview => assessmentsOverview.groupTasks,
  groupTasks => (
    groupTasks.filter(task => typeof task === 'string').map((separator, index) => ({ id: index, name: separator }))
  ),
);

export const makeGetScoredStudents = () => createSelector(
  (activity, assessmentsOverview, students, task) => task.id,
  (activity, assessmentsOverview, students) => students.list,
  (activity, assessmentsOverview) => assessmentsOverview.tasksStudents,
  activity => activity.activity.students_enrollments,
  (taskId, studentsList, tasksStudentsListing, studentsEnrollments) => {
    const taskEnrollments = [];

    studentsList.forEach((student) => {
      if (tasksStudentsListing[taskId] && tasksStudentsListing[taskId].includes(`${student.id}`)) {
        taskEnrollments.push(student.student_enrollment_id);
      }
    });

    return intersectionBy(studentsEnrollments, taskEnrollments, toNumber).length;
  },
);
