import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  padding-bottom: 30px;
`;

export const TextInput = styled.input`
  color: ${({ theme }) => theme.darkGrey};
  border: 1px solid ${({ theme }) => theme.primaryGrey};
  border-radius: 4px;
  padding: 10px;
  margin-top: 5px;
  font-size: initial;
`;
