import React from 'react';
import PropTypes from 'prop-types';

import { Container, Thumbnail, Round, Text, Description } from './styled';

const Item = ({ label, description }) => (
  <Container>
    <Thumbnail grade={label}>
      <Round>{label}</Round>
    </Thumbnail>
    <Text>
      <Description>{description}</Description>
    </Text>
  </Container>
);

Item.propTypes = {
  label: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
};

export default Item;
