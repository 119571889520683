import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';

import { downloadResource } from '../../http';
import { fetchResources, loadInitialState } from 'redux/modules/resources';
import Preloader from 'components/Preloader';
import Item from './components/Item';
import { Container, StyledTitle } from './styled';

class ResourcesList extends PureComponent {
  static propTypes = {
    fetchResources: PropTypes.func.isRequired,
    loading: PropTypes.bool.isRequired,
    loadInitialState: PropTypes.func.isRequired,
    match: PropTypes.object.isRequired,
    resources: PropTypes.array.isRequired,
  }

  static noResources = 'There are no materials for this task yet'

  constructor(props) {
    super(props);

    this.onResourceClick = this.onResourceClick.bind(this);
  }

  componentDidMount() {
    const { collectionId, taskId } = this.props.match.params;

    this.props.fetchResources(collectionId, taskId);
  }

  componentWillUnmount() {
    this.props.loadInitialState();
  }

  onResourceClick(resourceId) {
    const { collectionId, taskId } = this.props.match.params;

    downloadResource(collectionId, taskId, resourceId);
  }

  render() {
    const { loading, resources } = this.props;

    if (loading) {
      return <Preloader loading={loading} />;
    }

    return (
      <Container>
        {resources.length ?
          resources.map(item => <Item key={item.id} resource={item} onResourceClick={this.onResourceClick} />) :
          <StyledTitle tabIndex={0} aria-label={ResourcesList.noResources} text={ResourcesList.noResources} />
        }
      </Container>
    );
  }
}

const mapStateToProps = ({ resources }) => ({
  loading: resources.loading,
  resources: resources.resources,
});

export default withRouter(connect(mapStateToProps, { fetchResources, loadInitialState })(ResourcesList));
