import React from 'react';
import PropTypes from 'prop-types';

import Container from './styled';

const TaskDatesItem = ({ taskname, date }) => (
  <Container>
    <span>{taskname}</span>
    {date && <span>Due: {date}</span>}
  </Container>
);

TaskDatesItem.propTypes = {
  taskname: PropTypes.string,
  date: PropTypes.string,
};

TaskDatesItem.defaultProps = {
  taskname: '',
  date: '',
};

export default TaskDatesItem;
