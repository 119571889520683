import React from 'react';
import PropTypes from 'prop-types';
import { Field, reduxForm } from 'redux-form';

import { Form, Errors } from './styled';
import Button from 'components/Button';
import TextInput from 'components/TextInput';

const validate = (values) => {
  const errors = {};
  if (!values._username) {
    errors._username = 'You must supply a username';
    return errors;
  }
  if (!values._password) {
    errors._password = 'You must supply a password';
  }

  return errors;
};

const renderField = ({ input, label, type, meta: { touched, error } }) => (
  <div>
    <TextInput {...input} type={type} placeholder={label} aria-label={label} />
    {touched && error && <Errors>{error}</Errors>}
  </div>
);

const LoginForm = ({ loading, submitting, handleSubmit }) => (
  <Form onSubmit={handleSubmit}>
    <Field
      name="_username"
      type="text"
      component={renderField}
      label="Username"
    />
    <Field
      name="_password"
      type="password"
      component={renderField}
      label="Password"
    />
    <Button size="lg" type="submit" disabled={submitting || loading}>Login</Button>
  </Form>
);

LoginForm.propTypes = {
  loading: PropTypes.bool.isRequired,
  submitting: PropTypes.bool.isRequired,
  handleSubmit: PropTypes.func.isRequired,
};

export default reduxForm({
  form: 'login',
  validate,
})(LoginForm);
