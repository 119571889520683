import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { withRouter } from 'react-router';
import { isMobile } from 'react-device-detect';

import { logout } from 'redux/modules/auth';

import { checkRulesetAccess } from 'redux/modules/auth/helpers';

import withKeyDown from 'components/withKeyDown';
import NavbarItem from './components/NavbarItem';
import Notifications from './components/Notifications';
import Roster from './components/Roster';
import { Navbar, OutsideOverlay } from './styled';

const OutsideOverlayWithKeyDown = withKeyDown(OutsideOverlay, 'Both');

class Sidebar extends Component {
  static propTypes = {
    actions: PropTypes.shape({
      logout: PropTypes.func.isRequired,
    }).isRequired,
    history: PropTypes.object.isRequired,
    notificationsCount: PropTypes.number.isRequired,
    isRulesetAccess: PropTypes.bool.isRequired,
  }

  constructor(props) {
    super(props);

    this.close = this.close.bind(this);
    this.itemHandler = this.itemHandler.bind(this);
    this.logout = this.logout.bind(this);
    this.toAssessments = this.toAssessments.bind(this);
    this.toggleNotification = this.toggleNotification.bind(this);
    this.toggleRoster = this.toggleRoster.bind(this);
    this.toUpload = this.toUpload.bind(this);
  }

  state = {
    isNotificationsOpened: false,
    isRosterOpened: false,
  }

  logout() {
    this.props.actions.logout();
    this.props.history.push('/login');
  }

  toAssessments() {
    this.props.history.push('/collections/list');
  }

  toggleNotification() {
    this.setState({ isNotificationsOpened: !this.state.isNotificationsOpened, isRosterOpened: false });
  }

  toggleRoster() {
    this.setState({ isNotificationsOpened: false, isRosterOpened: !this.state.isRosterOpened });
  }

  close() {
    this.setState({ isRosterOpened: false, isNotificationsOpened: false });
  }

  itemHandler() {
    this.setState({ ...this.state, isNotificationsOpened: false });
  }

  toUpload() {
    this.props.history.push('/scoresheets/upload');
  }

  render() {
    const { isNotificationsOpened, isRosterOpened } = this.state;
    const { notificationsCount, isRulesetAccess } = this.props;

    return (
      <div>
        <Notifications isOpened={isNotificationsOpened} />
        <Roster isOpened={isRosterOpened} />
        <OutsideOverlayWithKeyDown
          isOpened={isNotificationsOpened || isRosterOpened}
          onClick={this.close}
          tabIndex={(isNotificationsOpened || isRosterOpened) ? 1 : -1}
          aria-label="close sidebar slider"
        />
        <Navbar role="navigation" aria-label="sidebar">
          <NavbarItem icon="assessments" handleClick={this.toAssessments} />
          <NavbarItem
            amount={notificationsCount}
            icon="notifications"
            handleClick={this.toggleNotification}
            isActive={isNotificationsOpened}
          />
          {/* isMobile: Upload page access only for Mobile and Tablet */}
          {/* isRulesetAccess: Upload page access only for ruleset value */}
          {isMobile && isRulesetAccess && <NavbarItem icon="upload" handleClick={this.toUpload} />}
          {/* <NavbarItem icon="groups" handleClick={this.toggleRoster} isActive={this.state.isRosterOpened} /> */}
          {/* <NavbarItem icon="settings" handleClick={this.itemHandler} /> */}
          <NavbarItem icon="exit" handleClick={this.logout} />
        </Navbar>
      </div>
    );
  }
}

const mapStateToProps = ({ notifications, auth }) => ({
  notificationsCount: notifications.length,
  isRulesetAccess: checkRulesetAccess(auth.user.tenants.ruleset),
});

const mapDispatchToProps = dispatch => ({
  actions: bindActionCreators({ logout }, dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(Sidebar));
