import React from 'react';
import PropTypes from 'prop-types';

import Button from 'components/Button';
import { Container, ButtonContainer, ButtonGroup } from './styled';

const BottomBar = ({
  onCancel,
  onSave,
  onDelete,
  isSaveDisabled,
}) => (
  <Container>
    <ButtonGroup>
      <ButtonContainer>
        <Button
          disabled={isSaveDisabled}
          aria-label="Save group"
          color="#70a823"
          onClick={onSave}
        >
          Save
        </Button>
      </ButtonContainer>
      <ButtonContainer>
        <Button
          aria-label={`Cancel group ${onDelete ? 'editing' : 'creation'}`}
          color="#70a823"
          onClick={onCancel}
        >
          Cancel
        </Button>
      </ButtonContainer>
    </ButtonGroup>
    {onDelete && (
      <ButtonGroup>
        <ButtonContainer>
          <Button
            aria-label="Delete group"
            color="#555555"
            icon="deleteWhite"
            onClick={onDelete}
          >
            Delete
          </Button>
        </ButtonContainer>
      </ButtonGroup>)
    }
  </Container>
);

BottomBar.propTypes = {
  onCancel: PropTypes.func.isRequired,
  onDelete: PropTypes.func,
  onSave: PropTypes.func.isRequired,
  isSaveDisabled: PropTypes.bool.isRequired,
};

BottomBar.defaultProps = {
  onDelete: null,
};

export default BottomBar;
