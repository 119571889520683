const ethnicities = [
  {
    id: 1,
    value: 'White',
  },
  {
    id: 2,
    value: 'Black or African American',
  },
];

export default ethnicities;
