import styled from 'styled-components';

const TextInput = styled.input`
  width: 100%;
  box-sizing: border-box;
  color: #333;
  border: none;
  border-radius: 4px;
  padding: 20px;
  box-shadow:inset 0px 0px 2px 1px #ccc;

  &:hover {
    background-color: #f1f1f1;
  }

  &:focus {
    background-color: #f1f1f1;
    outline-style: solid;
    outline-width: 2px;
  }
`;

export default TextInput;
