import styled from 'styled-components';
import Button from 'components/Button';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  overflow: hidden;
`;

export const Form = styled.form`
  overflow-y: auto;
  color: ${({ theme }) => theme.darkGrey};
  padding: 15px;
`;

export const Row = styled.div`
  display: flex;
  overflow: hidden;
`;

export const StyledButton = styled(Button)`
  margin-right: 15px;
  padding: 0 40px;
  width: 140px;
`;

export const TextArea = styled.textarea.attrs({
  rows: 13,
})`
  color: ${({ theme }) => theme.darkGrey};
  border: 1px solid ${({ theme }) => theme.primaryGrey};
  border-radius: 4px;
  padding: 10px;
  resize: none;
`;

export const TextInput = styled.input.attrs({
  maxLength: 128,
})`
  color: ${({ theme }) => theme.darkGrey};
  border: 1px solid ${({ theme }) => theme.primaryGrey};
  border-radius: 4px;
  padding: 10px;
`;
