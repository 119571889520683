import styled from 'styled-components';

export const Container = styled.div`
  box-sizing: border-box;
  display: flex;
  height: 100%;
  width: 100%;
  padding: 5px 0;
  padding-left: 25px;
  border-bottom: 1px solid ${({ theme }) => (theme.primaryGrey)};
  background: ${({ theme }) => (theme.primaryWhite)};
  height: 70px;
  color: ${({ theme }) => (theme.darkGrey)};
  cursor: pointer;
`;

export const TextBlock = styled.div`
  display: flex;
  box-sizing: border-box;
  flex-direction: column;
  height: 100%;
  width: 65%;
  padding: 8px 0;
  justify-content: space-around;
`;

export const Title = styled.span`
  font-size: 16px;
`;

export const Category = styled.span`
  font-size: 14px;
  font-weight: 600;
`;

export const ButtonsBlock = styled.div`
  width: 35%;
  padding: 0 10px;
  display: flex;
  justify-content: flex-end;
  align-items: center;

  button {
    margin: 0 10px;
  }
`;

export const ArtifactsButton = styled.div`
  button {
    width: 60px;
  }
`;
