import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';

import { fetchAssessmentsOverview } from 'redux/modules/assessments-overview';
import { getAvailableLevelsDefault } from 'redux/modules/scale/helpers';
import { getSelectedAssessments, getSeparators } from 'redux/modules/assessments-overview/selectors';
import Preloader from 'components/Preloader';
import Subheader from './components/Subheader';
import Table from './components/Table';
import Container from './styled';

class AssessmentsOverview extends PureComponent {
  static propTypes = {
    fetchAssessmentsOverview: PropTypes.func.isRequired,
    list: PropTypes.array.isRequired,
    loading: PropTypes.bool.isRequired,
    match: PropTypes.object.isRequired,
    selectedAssessments: PropTypes.array.isRequired,
    separators: PropTypes.arrayOf(PropTypes.object).isRequired,
  }

  componentDidMount() {
    this.props.fetchAssessmentsOverview(this.props.match.params.collectionId);
  }

  render() {
    const {
      list,
      loading,
      selectedAssessments,
      separators,
    } = this.props;

    if (loading) {
      return (
        <Container>
          <Preloader loading={loading} />
          <Subheader />
        </Container>
      );
    }

    return (
      <Container>
        <Subheader />
        <Table
          assessments={selectedAssessments}
          list={list}
          separators={separators}
        />
      </Container>
    );
  }
}

const mapStateToProps = ({ app, assessmentsOverview, scale }) => ({
  list: getAvailableLevelsDefault(scale.selectedLevels),
  loading: assessmentsOverview.loading,
  selectedAssessments: getSelectedAssessments(app, assessmentsOverview),
  separators: getSeparators(assessmentsOverview),
});

export default withRouter(connect(mapStateToProps, { fetchAssessmentsOverview })(AssessmentsOverview));
