import styled from 'styled-components';

export const Label = styled.label`
  background-color: ${props => (props.color ? props.color : 'white')};
  box-shadow: ${props => (props.selected ?
    `inset 0px 0px 0px 6px #333, inset 0px 0px 0px 24px ${props.color}` :
    `inset 0px 0px 0px 24px ${props.color}`)};
  width: 42px;
  height: 42px;
  margin-right: 8px;
  margin-bottom: 8px;
  position: relative;
  cursor: pointer;

  &:last-child {
    margin-right: 0;
  }

  &::after {
    content: '';
    background-color: rgba(0, 0, 0, 0.2);
    height: 100%;
    width: 100%;
    position: absolute;
    top: 0;
    left: 0;
    opacity: ${props => (props.selected ? '0.2' : '0')};
    transition: ease 0.15s;
  }

  &:hover::after {
    opacity: 0.2;
  }

  &:active::after {
    opacity: 1;
  }
`;

export const Color = styled.input`
  opacity: 0;
`;

Color.defaultProps = {
  selected: false,
};
