import styled from 'styled-components';

export const Container = styled.div`
  width: 100%;
  height: 100%;
  overflow-y: auto;
  display: flex;
  flex-direction: column;
  background: ${({ theme }) => (theme.lightGrey)};
`;

export const Header = styled.div`
  display: flex;
  box-sizing: border-box;
  height: 70px;
  min-height: 70px;
  padding: 0 25px;
  background: ${({ theme }) => (theme.lightGrey)};
  justify-content: space-between;
  align-items: center;
  border-bottom: 1px solid ${({ theme }) => (theme.primaryGrey)};
  text-transform: uppercase;
  font-size: 16px;
  color: ${({ theme }) => (theme.darkGrey)};
  font-weight: 600;
`;

export const Task = styled.div`
  display: flex;
  color: ${({ theme }) => (theme.darkGrey)};
  flex-direction: column;
  justify-content: space-around;
  height: 70px;
  min-height: 70px;
  padding: 15px 25px;
  box-sizing: border-box;
  background: ${({ theme }) => (theme.primaryWhite)};
  border-bottom: 1px solid ${({ theme }) => (theme.primaryGrey)};
`;

export const Title = styled.span`
  display: flex;
  font-size: 16px;
`;

export const Category = styled.span`
  display: flex;
  font-size: 14px;
  font-weight: 600;
`;

export const List = styled.div`
  display: flex;
  flex-direction: column;
`;
