import styled from 'styled-components';

export const Container = styled.div`

`;

export const Item = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  padding: 20px;
  position: relative;
  box-sizing: border-box;
  width: 100%;
  height: 70px;
  border-bottom: 2px solid #F4F4F4;
  color: #7E7E7E;
`;
