import update from 'immutability-helper';
import moment from 'moment';
import omit from 'lodash/omit';
import findIndex from 'lodash/findIndex';

import http from '../../../http';
import { setShouldActivitiesListUpdate } from 'redux/modules/activities';
import { formatActivity, formatActivityForPost, formActivitySetsAndStudents } from './formatters';
import { denormalizeActivity, normalizeActivity, normalizeArtifacts } from './normalizers';
import getFileBase64 from '../../../services/getFileBase64';

const API_REQUEST = 'rfc/activity/detail/API_REQUEST';
const FETCH_ACTIVITY_SUCCESS = 'rfc/activity/detail/FETCH_ACTIVITY_SUCCESS';
const FETCH_ACTIVITY_FAILURE = 'rfc/activity/detail/FETCH_ACTIVITY_FAILURE';
const EDIT_ACTIVITY_SUCCESS = 'rfc/activity/detail/EDIT_ACTIVITY_SUCCESS';
const EDIT_ACTIVITY_FAILURE = 'rfc/activity/detail/EDIT_ACTIVITY_FAILURE';
const DELETE_ACTIVITY_SUCCESS = 'rfc/activity/detail/DELETE_ACTIVITY_SUCCESS';
const DELETE_ACTIVITY_FAILURE = 'rfc/activity/detail/DELETE_ACTIVITY_FAILURE';
const COPY_ACTIVITY_SUCCESS = 'rfc/activity/copy/COPY_ACTIVITY_SUCCESS';
const COPY_ACTIVITY_FAILURE = 'rfc/activity/copy/COPY_ACTIVITY_FAILURE';
const CREATE_ACTIVITY_SUCCESS = 'rfc/activity/create/CREATE_ACTIVITY_SUCCESS';
const CREATE_ACTIVITY_FAILURE = 'rfc/activity/create/CREATE_ACTIVITY_FAILURE';
const UPLOAD_ARTIFACT_SUCCESS = 'rfc/activity/artifacts/UPLOAD_ARTIFACT_SUCCESS';
const UPLOAD_ARTIFACT_FAILURE = 'rfc/activity/artifacts/UPLOAD_ARTIFACT_FAILURE';
const DELETE_ARTIFACT_SUCCESS = 'rfc/activity/artifacts/DELETE_ARTIFACT_SUCCESS';
const DELETE_ARTIFACT_FAILURE = 'rfc/activity/artifacts/DELETE_ARTIFACT_FAILURE';
const TASK_SELECT = 'rfc/activity/TASK_SELECT';
const STUDENT_SELECT = 'rfc/activity/STUDENT_SELECT';
const INPUT_FIELD_CHANGE = 'rfc/activity/INPUT_FIELD_CHANGE';
const DESCRIPTION_SAVE = 'rfc/activity/DESCRIPTION_SAVE';
const CREATE_ACTIVITY_STATE = 'rfc/activity/create/CREATE_ACTIVITY_STATE';
const DEFAULT_ACTIVITY_STATE_REQUEST = 'rfc/activity/DEFAULT_ACTIVITY_STATE_REQUEST';
const SHOULD_GO_TO_ACTIVITIES_LIST_SET = 'rfc/activity/SHOULD_GO_TO_ACTIVITIES_LIST_SET';

const initialState = {
  activity: {
    label: '',
    description: '',
    notes: '',
    end_date: moment(),
    closed: 0,
    students_amount: 0,
    students_enrollments: [],
    data_collections_sets_items: [],
    data_collections_activities_artifacts: [],
  },
  students_enrollments: {},
  data_collections_sets_items: {},
  data_collections_activities_artifacts: {},
  createdId: null,
  copiedId: null,
  shouldGoToActivitiesList: false,
  error: '',
  loading: true,
};

export default function reducer(state = initialState, action = {}) {
  switch (action.type) {
    case FETCH_ACTIVITY_SUCCESS:
      return update(state, {
        $set: {
          students_enrollments: action.payload.entities.students_enrollments || {},
          data_collections_sets_items: action.payload.entities.data_collections_sets_items || {},
          data_collections_activities_artifacts: action.payload.entities.data_collections_activities_artifacts || {},
          activity: action.payload.result,
          copiedId: null,
          createdId: null,
          shouldGoToActivitiesList: false,
          error: '',
          loading: false,
        },
      });
    case EDIT_ACTIVITY_SUCCESS:
      return update(state, {
        $merge: {
          ...action.payload.entities,
          activity: action.payload.result,
          createdId: action.payload.result.id,
          error: '',
          loading: false,
        },
      });
    case CREATE_ACTIVITY_SUCCESS:
      return update(state, {
        $merge: {
          ...action.payload.entities,
          activity: action.payload.result,
          createdId: action.payload.result.id,
          error: '',
          loading: false,
        },
      });
    case COPY_ACTIVITY_SUCCESS:
      return update(state, { copiedId: { $set: action.payload.id } });
    case DELETE_ARTIFACT_SUCCESS: {
      const index = findIndex(state.activity.data_collections_activities_artifacts, id => +id === +action.payload.id);
      return update(state, {
        activity: { data_collections_activities_artifacts: { $splice: [[index, 1]] } },
        data_collections_activities_artifacts: { $apply: artifacts => omit(artifacts, [`${action.payload.id}`]) },
        $merge: { error: '', loading: false },
      });
    }
    case UPLOAD_ARTIFACT_SUCCESS:
      return update(state, {
        activity: { data_collections_activities_artifacts: { $push: action.payload.result } },
        data_collections_activities_artifacts: {
          $merge: { ...action.payload.entities.data_collections_activities_artifacts },
        },
        $merge: { error: '', loading: false },
      });
    case DELETE_ACTIVITY_SUCCESS:
      return update(state, { shouldGoToActivitiesList: { $set: true } });
    case API_REQUEST:
      return update(state, { loading: { $set: true }, copiedId: { $set: null } });
    case FETCH_ACTIVITY_FAILURE:
      return update(state, { $merge: { error: action.payload, loading: false } });
    case EDIT_ACTIVITY_FAILURE:
      return update(state, { $merge: { error: action.payload, loading: false } });
    case DELETE_ACTIVITY_FAILURE:
      return update(state, { $merge: { error: action.payload, loading: false } });
    case CREATE_ACTIVITY_FAILURE:
      return update(state, { $merge: { error: action.payload, loading: false } });
    case COPY_ACTIVITY_FAILURE:
      return update(state, { $merge: { error: action.payload, loading: false } });
    case DELETE_ARTIFACT_FAILURE:
      return update(state, { $merge: { error: action.payload, loading: false } });
    case UPLOAD_ARTIFACT_FAILURE:
      return update(state, { $merge: { error: action.payload, loading: false } });
    case TASK_SELECT: {
      const index = findIndex(state.activity.data_collections_sets_items, id => +id === +action.payload.id);
      return index === -1 ?
        update(state, { activity: { data_collections_sets_items: { $push: [action.payload.id] } } }) :
        update(state, { activity: { data_collections_sets_items: { $splice: [[index, 1]] } } });
    }
    case STUDENT_SELECT: {
      const index = findIndex(state.activity.students_enrollments, id => +id === +action.payload.id);
      return index === -1 ?
        update(state, { activity: { students_enrollments: { $push: [action.payload.id] } } }) :
        update(state, { activity: { students_enrollments: { $splice: [[index, 1]] } } });
    }
    case INPUT_FIELD_CHANGE:
      return update(state, { activity: { [action.payload.field]: { $set: action.payload.value } } });
    case DESCRIPTION_SAVE:
      return update(state, { activity: { description: { $set: action.payload.value } } });
    case CREATE_ACTIVITY_STATE:
      return update(initialState, {
        activity: { students_enrollments: { $push: action.payload.students } },
        loading: { $set: false },
      });
    case DEFAULT_ACTIVITY_STATE_REQUEST:
      return { ...initialState };
    case SHOULD_GO_TO_ACTIVITIES_LIST_SET:
      return update(state, { shouldGoToActivitiesList: { $set: true } });
    default:
      return state;
  }
}

const failedActivity = payload => ({ type: FETCH_ACTIVITY_FAILURE, payload });
const receiveActivity = payload => ({ type: FETCH_ACTIVITY_SUCCESS, payload: normalizeActivity(payload) });

export const fetchActivity = (collectionId, activityId) => (dispatch) => {
  dispatch({ type: API_REQUEST });
  http.get(`/en/api/v1/user/collections/${collectionId}/activities/${activityId}`)
    .then(response => dispatch(receiveActivity(formatActivity(response.data))))
    .catch(response => dispatch(failedActivity(response)));
};

const failedEditActivity = payload => ({ type: EDIT_ACTIVITY_FAILURE, payload });
const successEditActivity = payload => ({
  type: EDIT_ACTIVITY_SUCCESS,
  payload: normalizeActivity(payload),
});

const sendActivityToAPI = (dispatch, collectionId, activityId, activity) => {
  dispatch(setShouldActivitiesListUpdate());
  http.post(`/en/api/v1/user/collections/${collectionId}/activities/${activityId}`, activity)
    .then(response => dispatch(successEditActivity(formatActivity(response.data))))
    .catch(response => dispatch(failedEditActivity(response)));
};

export const openCloseActivity = (collectionId, activityId) => (dispatch, getState) => {
  dispatch({ type: API_REQUEST });
  const activity = formatActivityForPost(update(
    denormalizeActivity(getState().activity),
    { closed: { $apply: closed => (closed ? 0 : 1) } },
  ));
  sendActivityToAPI(dispatch, collectionId, activityId, activity);
};

export const editActivity = (collectionId, activityId) => (dispatch, getState) => {
  dispatch({ type: API_REQUEST });
  const activity = formatActivityForPost(denormalizeActivity(formActivitySetsAndStudents(getState())));
  sendActivityToAPI(dispatch, collectionId, activityId, activity);
};

const failedCreateActivity = payload => ({ type: CREATE_ACTIVITY_FAILURE, payload });
const successCreateActivity = payload => ({ type: CREATE_ACTIVITY_SUCCESS, payload: normalizeActivity(payload) });

export const createActivity = collectionId => (dispatch, getState) => {
  dispatch({ type: API_REQUEST });
  dispatch(setShouldActivitiesListUpdate());
  const activity = formatActivityForPost(denormalizeActivity(formActivitySetsAndStudents(getState())));
  http.post(`/en/api/v1/user/collections/${collectionId}/activities.json`, activity)
    .then(response => dispatch(successCreateActivity(formatActivity(response.data))))
    .catch(response => dispatch(failedCreateActivity(response)));
};

const failedDeleteActivity = payload => ({ type: DELETE_ACTIVITY_FAILURE, payload });
const successDeleteActivity = () => ({ type: DELETE_ACTIVITY_SUCCESS });

export const deleteActivity = (collectionId, activityId) => (dispatch) => {
  dispatch({ type: API_REQUEST });
  dispatch(setShouldActivitiesListUpdate());
  http.delete(`/en/api/v1/user/collections/${collectionId}/activities/${activityId}`)
    .then(() => dispatch(successDeleteActivity()))
    .catch(response => dispatch(failedDeleteActivity(response)));
};

const failedUploadArtifact = payload => ({ type: UPLOAD_ARTIFACT_FAILURE, payload });
const successUploadArtifact = payload => ({ type: UPLOAD_ARTIFACT_SUCCESS, payload: normalizeArtifacts(payload) });

export const uploadActivityArtifact = (collectionId, activityId, file, comment) => async (dispatch) => {
  dispatch({ type: API_REQUEST });
  dispatch(setShouldActivitiesListUpdate());

  let url;
  if (activityId) {
    url = `en/api/v1/user/collections/${collectionId}/activities/${activityId}/artifacts.json`;
  } else {
    // test request, should be replaced by real one
    url = `en/api/v1/user/collections/${collectionId}/artifact.json`;
  }

  const base64 = await getFileBase64(file);
  http.post(url, { name: file.name, data: base64, comment })
    .then(response => dispatch(successUploadArtifact(response.data)))
    .catch(response => dispatch(failedUploadArtifact(response)));
};

const failedDeleteArtifact = payload => ({ type: DELETE_ARTIFACT_FAILURE, payload });
const successDeleteArtifact = id => ({ type: DELETE_ARTIFACT_SUCCESS, payload: { id } });

export const deleteArtifact = (collectionId, activityId, artifactId) => (dispatch) => {
  dispatch({ type: API_REQUEST });
  dispatch(setShouldActivitiesListUpdate());
  http.delete(`/en/api/v1/user/collections/${collectionId}/activities/${activityId}/artifacts/${artifactId}`)
    .then(() => dispatch(successDeleteArtifact(artifactId)))
    .catch(response => dispatch(failedDeleteArtifact(response)));
};

const failedCopyActivity = payload => ({ type: COPY_ACTIVITY_FAILURE, payload });
const successCopyActivity = id => ({ type: COPY_ACTIVITY_SUCCESS, payload: { id } });

export const copyActivity = collectionId => (dispatch, getState) => {
  dispatch({ type: API_REQUEST });
  dispatch(setShouldActivitiesListUpdate());
  const activity = formatActivityForPost(denormalizeActivity(formActivitySetsAndStudents(getState())), 'copy');
  http.post(`/en/api/v1/user/collections/${collectionId}/activities.json`, activity)
    .then(response => dispatch(successCopyActivity(response.data.id)))
    .catch(response => dispatch(failedCopyActivity(response)));
};

export const selectTask = id => ({ type: TASK_SELECT, payload: { id } });
export const selectStudent = student => ({ type: STUDENT_SELECT, payload: { id: student.student_enrollment_id } });
export const changeInputField = (field, value) => ({ type: INPUT_FIELD_CHANGE, payload: { field, value } });
export const saveDescription = value => ({ type: DESCRIPTION_SAVE, payload: { value } });
export const loadActivityToCreate = students => ({ type: CREATE_ACTIVITY_STATE, payload: { students } });
export const loadDefaultActivityState = () => ({ type: DEFAULT_ACTIVITY_STATE_REQUEST });
export const setShouldGoToActivitiesList = () => ({ type: SHOULD_GO_TO_ACTIVITIES_LIST_SET });
