import styled from 'styled-components';

export const Container = styled.div`
  padding: 15px;
  color: ${({ theme }) => theme.darkGrey};
`;

export const DropzoneWrapper = styled.div`
  margin-bottom: 10px;

  button {
    text-transform: uppercase;
  }

  div {
    margin-top: 8px;
    width: auto !important;
    height: auto !important;
    border: none !important;
  }
`;

export const Footer = styled.div`
  display: flex;
  box-sizing: border-box;

  button {
    width: 100px;
    margin-right: 15px;
  }
`;

export const Header = styled.div`
  display: flex;
  box-sizing: border-box;
  align-items: center;
  height: 50px;
  width: 100%;
  padding: 10px 15px;
  font-size: 16px;
  color: ${({ theme }) => theme.darkGrey};
  text-transform: uppercase;
`;

export const Comment = styled.div`
  padding: 0 0 15px 0;
  display: flex;
  flex-direction: column;
  margin-bottom: 10px;
`;

export const TextArea = styled.textarea.attrs({
  placeholder: 'Add a comment...',
  tabIndex: 0,
})`
  height: 150px;
  margin-top: 8px;
  padding: 10px;
  resize: none;
`;

export const Error = styled.div`
  margin-bottom: 6px;
  color: ${({ theme }) => theme.error};
`;
