import React from 'react';
import { Route, Switch } from 'react-router-dom';

import AssessmentsList from './pages/AssessmentsList';

const Collections = () => (
  <Switch>
    <Route path="/collections/list" component={AssessmentsList} />
  </Switch>
);

export default Collections;
