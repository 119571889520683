import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  border-right: 1px solid ${({ theme }) => theme.primaryGrey};
  max-width: 50%;
`;

export const Content = styled.form`
  display: block;
  padding: 15px;
  font-size: 12px;
  color: ${({ theme }) => theme.darkGrey};
  overflow-y: auto;
`;
