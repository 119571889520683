import { createSelector } from 'reselect';

const filters = {
  open: 'OPEN',
  closed: 'CLOSED',
};

const makeGetVisibleActivities = () => (
  createSelector(
    (activities, filter) => filter,
    activities => activities.activitiesListing,
    activities => activities.activitiesById,
    (filter, listing, byId) => {
      const activities = byId.map(id => listing[id]);
      switch (filter) {
        case filters.open:
          return activities.filter(activity => !activity.closed);
        case filters.closed:
          return activities.filter(activity => activity.closed);
        default: return activities;
      }
    },
  )
);

export default makeGetVisibleActivities;
