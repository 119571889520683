import React from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import find from 'lodash/find';
import queryString from 'query-string';

import Button from 'components/Button';

import { Container, Avatar, Image, TextInfo, Header } from './styled';
import Female from './images/Female.jpg';
import Male from './images/Male.jpg';

import { formatPersonalInfo } from 'redux/modules/students/formatters';

import ethnicities from 'configs/ethnicities';

const StudentsPersonalInfo = ({ personalInfo, match, history }) => {
  /*  const EditButtonWithKeyDown = withKeyDown(EditButton, 'Both'); */
  const toCreateActivity = () => {
    const { collectionId } = match.params;
    const selected = queryString.stringify(
      { selectedStudents: [personalInfo.student_enrollment_id] },
      { arrayFormat: 'bracket' },
    );
    history.push(`/collections/${collectionId}/activities/create/?${selected}`);
  };

  const GenderAvatar = personalInfo.gender && personalInfo.gender.toLowerCase() === 'male' ? Male : Female;
  const getEthnicity = (id) => {
    const etnicity = find(ethnicities, { id });
    return etnicity ? etnicity.value : '';
  };

  return (
    <Container>
      <Avatar>
        <Image src={GenderAvatar} alt="Avatar" />
        {
          /*  <EditButtonWithKeyDown
            aria-label="Edit profile avatar"
            onClick={edit}
          />  */
        }
      </Avatar>
      <TextInfo>
        <Header>{personalInfo.first_name} {personalInfo.middle_name} {personalInfo.last_name}</Header>
        <span>Gender: {personalInfo.gender}</span>
        <span>Ethnicity: {getEthnicity(personalInfo.race)} </span>
        <span>Special Ed: { /* personalInfo.special_education */ }</span>
        <span>Date of Birth: {personalInfo.dob_hr}</span>
        <span>Age: {`${personalInfo.current_age_months} Months | ${personalInfo.current_age_years} Years Old`}</span>
      </TextInfo>
      <Button icon="start" onClick={toCreateActivity}>Start an activity with this student</Button>
    </Container>
  );
};

const mapStateToProps = ({ students }, props) => {
  const currentStudent = find(students.list, item => item.id === +props.match.params.studentId) || {};

  return {
    personalInfo: formatPersonalInfo(currentStudent),
  };
};

StudentsPersonalInfo.propTypes = {
  personalInfo: PropTypes.object.isRequired,
  match: PropTypes.object.isRequired,
  history: PropTypes.object.isRequired,
};

export default withRouter(connect(mapStateToProps)(StudentsPersonalInfo));
