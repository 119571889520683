import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  flex-shrink: 0;
`;

export const Row = styled.div`
  display: flex;
  margin-top: 10px;
  padding: 0 30px;
  color: ${({ theme }) => theme.darkGrey};
`;

export const Title = styled.div`
  width: 150px;
  font-weight: 600;
  flex-shrink: 0;
  color: #000;
`;
