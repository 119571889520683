const formTaskClass = (hierarchy, hierarchyItemId) => (
  hierarchy[hierarchyItemId].node_parent_id ?
    `${formTaskClass(hierarchy, hierarchy[hierarchyItemId].node_parent_id)} / ${hierarchy[hierarchyItemId].label}` :
    `${hierarchy[hierarchyItemId].label}`
);

export const formatTasks = (hierarchy, sets, tasks) => {
  const formated = tasks.map(task => ({
    ...task,
    checked: false,
    taskClass: formTaskClass(hierarchy, task.assessment_item_heirarchy_id),
  }));
  return sets.result.reduce((result, setId) => {
    result.push({ separator: `${sets.entities.setItems[setId].label}` });
    formated.filter(task => task.data_collection_sets_parent_id === setId).forEach(task => result.push(task));
    return result;
  }, []);
};


export const formatShortcuts = (sets, tasks) => {
  const taskShortcuts = [];
  tasks.map((item) => {
    if (!item.id) {
      return null;
    }

    taskShortcuts.push({
      group: sets[item.data_collection_sets_parent_id].label,
      category: item.taskClass,
      title: item.label,
      id: item.id,
      scales_parent_id: item.assessment_items_scales_directory_parent_id,
      description: item.description,
    });
    return null;
  });

  return taskShortcuts;
};

export const watchPrioriry = (list, showPriority) => {
  if (!showPriority) {
    return list;
  }

  return list.filter(item => item.priority);
};
