import styled from 'styled-components';
import Button from 'components/Button';

export const Container = styled.div`
  display: flex;
  align-items: center;
  height: 70px;
  padding: 15px 25px;
  box-sizing: border-box;
  flex-shrink: 0;
  background: ${({ theme }) => theme.primaryWhite};
  border-bottom: 1px solid ${({ theme }) => theme.primaryGrey};
`;

export const ResourceButton = styled(Button)`
  width: 100%;
  text-align: left;
  padding: 8px 15px;
  font-size: 14px;
  font-weight: 600;
  text-transform: uppercase;
  color: ${({ theme }) => theme.primaryWhite};
  border: none;
  border-radius: 3px;
  background-color: ${({ theme }) => theme.primaryBlue};

  span {
    justify-content: flex-start;
  }
`;
