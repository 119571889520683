import moment from 'moment';

export function formatStudents(students, items) {
  const formatted = students.data.map((item) => {
    const student = {};
    let total = 0;
    const week = 0;

    items.data.filter(set => set.student_id === item.id).forEach((selected) => {
      total += +selected.completed_items;
    });
    student.id = item.id;
    student.student_enrollment_id = item.student_enrollment_id;
    student.name = {
      id: item.id,
      firstname: item.first_name,
      lastname: item.last_name,
      middlename: item.middle_name,
      gender: item.gender,
    };
    student.checked = false;
    student.last = moment(+(`${item.dob}000`)).format('M/D/YYYY');
    student.total = total;
    student.week = week;
    student.select = {
      id: item.id,
      checked: false,
    };

    return student;
  });

  return formatted;
}

export function filterSelectedStudentsByGroup(selectedStudentsIds, groupId, GroupList) {
  if (groupId === -1) {
    return selectedStudentsIds;
  }
  return selectedStudentsIds.filter(id => GroupList.includes(id));
}

export function filterStudentsByGroup(students, groupId, GroupList) {
  if (groupId === -1) {
    return students;
  }
  return students.filter(item => GroupList.includes(item.id));
}

export function formatPersonalInfo(student) {
  const formatted = { ...student };
  formatted.dob_hr = moment(+(`${formatted.dob}000`)).format('M/D/YYYY');

  if (formatted.gender) {
    formatted.gender = formatted.gender.charAt(0).toUpperCase() + formatted.gender.slice(1).toLowerCase();
  } else {
    formatted.gender = undefined;
  }

  return formatted;
}
