import React from 'react';
import PropTypes from 'prop-types';

import Female from './images/Female.jpg';
import Male from './images/Male.jpg';

import { Container, Avatar, Image, Info, Name, SDate, LabelDate } from './styled';

const renderDate = (date, withLabel) => {
  if (!date) {
    return null;
  }

  if (withLabel) {
    return <LabelDate>Last Scored: {date}</LabelDate>;
  }

  return <SDate> {date} </SDate>;
};

const Student = ({
  firstname,
  lastname,
  middlename,
  date,
  gender,
  withLabel,
}) => {
  const GenderAvatar = gender && gender.toLowerCase() === 'male' ? Male : Female;

  return (
    <Container>
      <Avatar>
        <Image src={GenderAvatar} alt="avatar" />
      </Avatar>
      <Info>
        <Name>
          {lastname}, {firstname}&nbsp;{middlename}
        </Name>
        {renderDate(date, withLabel)}
      </Info>
    </Container>
  );
};

Student.propTypes = {
  avatar: PropTypes.string,
  firstname: PropTypes.string,
  lastname: PropTypes.string,
  middlename: PropTypes.string,
  date: PropTypes.string,
  gender: PropTypes.string,
  withLabel: PropTypes.bool,
};

Student.defaultProps = {
  avatar: Male,
  firstname: '',
  lastname: '',
  middlename: '',
  date: '',
  gender: '',
  withLabel: false,
};

export default Student;
