import React from 'react';
import PropTypes from 'prop-types';

import { Loader } from './styled';

const Preloader = ({ loading }) => (loading ? <Loader><div><span><p /></span></div></Loader> : null);

Preloader.propTypes = {
  loading: PropTypes.bool.isRequired,
};

export default Preloader;
