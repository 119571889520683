import styled from 'styled-components';

export const Container = styled.div`

`;

export const NotificationItemStyled = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  padding: 10px;
  position: relative;
  box-sizing: border-box;
  width: 100%;
  height: 100px;
  border-bottom: 2px solid #F4F4F4;
  color: #7E7E7E;
`;

export const ItemDelete = styled.div`
  position: absolute;
  top: 10px;
  right: 14px;
  width: 10px;
  height: 10px;
  font-weight: 900;
  cursor: pointer;

  &:after {
    content: '\u2716';
    display: block;
    width: 100%;
    height: 100%;
    color: #cccccc;
  }

  &:active {
    transform: scale(0.9);
  }
`;

