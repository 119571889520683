import styled from 'styled-components';

export const Container = styled.div`
  width: 100%;
  height: 100%;
  flex: 1;
  overflow-y: auto;
  display: flex;
  justify-content: flex-start;
  flex-direction: column;
  background: ${({ theme }) => (theme.lightGrey)};
`;

export const Header = styled.div`
  box-sizing: border-box;
  height: 70px;
  min-height: 70px;
  padding-left: 25px;
  background: ${({ theme }) => (theme.lightGrey)};
  display: flex;
  align-items: center;
  border-bottom: 1px solid ${({ theme }) => (theme.primaryGrey)};
  text-transform: uppercase;
  font-size: 16px;
  color: ${({ theme }) => (theme.darkGrey)};
  font-weight: 600;
`;

export const Subheader = styled.div`
  box-sizing: border-box;
  height: 50px;
  min-height: 50px;
  padding-left: 25px;
  display: flex;
  align-items: center;
  background: ${({ theme }) => (theme.lightGrey)};
  border-bottom: 1px solid ${({ theme }) => (theme.primaryGrey)};
  font-weight: 600;
  color: ${({ theme }) => (theme.darkGrey)};
  text-transform: uppercase;
  font-size: 14px;
`;

export const ExpandBlock = styled.div`
  display: flex;
  flex-direction: column;
  background: ${({ theme }) => (theme.primaryWhite)};
  border-bottom: 1px solid ${({ theme }) => (theme.primaryGrey)};
`;

export const ExpandHeader = styled.div`
  display: flex;
  box-sizing: border-box;
  padding: 10px 25px;
  height: 70px;
  min-height: 70px;
  cursor: pointer;
  border-bottom: 1px solid ${({ theme }) => (theme.primaryGrey)};
  background: ${({ theme }) => (theme.primaryWhite)};

  button {
    height: 100%;
  }
`;

export const Text = styled.div`
  display: flex;
  width: 100%;
  flex-direction: column;
  justify-content: space-around;
`;

export const Title = styled.span`
  display: flex;
  font-size: 16px;
  color: ${({ theme }) => (theme.darkGrey)};
`;

export const Category = styled.span`
  display: flex;
  font-size: 14px;
  font-weight: 600;
  color: ${({ theme }) => (theme.darkGrey)};
`;

export const ExpandContent = styled.div`
  box-sizing: border-box;
  padding: 25px;
  background: ${({ theme }) => (theme.primaryWhite)};
`;
