import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import withKeyDown from 'components/withKeyDown';

import {
  Title,
  StyledRate,
  Rate,
  RowContent,
  TaskName,
  SubTitle,
  Scale,
  Caption,
} from './styled';

const StyledRateWithKeyDown = withKeyDown(StyledRate, 'Both');

const Row = ({
  row,
  list,
  index,
  onClick,
  match,
}) => {
  const rates = row.rates.filter((rate, i) => (
    list[i].checked ? rate : null
  ));

  return (
    <RowContent>
      <TaskName to={`/collections/${match.params.collectionId}/rating/task/${row.task.taskId}`}>
        <Caption>
          <Title>
            {index}. {row.task.title}
          </Title>
          <SubTitle>
            {row.task.subtitle}
          </SubTitle>
        </Caption>
      </TaskName>
      {rates.map((rateObject, i) => (
        <StyledRateWithKeyDown
          aria-label={`rating ${rateObject.grade} details`}
          key={rateObject.grade}
          index={i}
          onClick={() => onClick(rateObject, row.task.subtitle)}
          effect={rateObject.rate}
        >
          <Rate>{rateObject.rate}</Rate>
          <Scale grade={rateObject.grade} height={`${(rateObject.rate / row.studentsAmount) * 34}px`} />
        </StyledRateWithKeyDown>
      ))}
    </RowContent>
  );
};

Row.propTypes = {
  row: PropTypes.object.isRequired,
  index: PropTypes.string.isRequired,
  list: PropTypes.array.isRequired,
  match: PropTypes.object.isRequired,
  onClick: PropTypes.func.isRequired,
};

const mapStateToProps = ({ scale }) => ({
  list: scale.selectedLevels,
  showPriority: scale.showPriority,
});

export default withRouter(connect(mapStateToProps)(Row));
