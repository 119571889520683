import React from 'react';
import PropTypes from 'prop-types';
import queryString from 'query-string';
import { withRouter } from 'react-router-dom';

import Button from 'components/Button';
import Modal from 'components/Modal';
import Student from 'components/Student';
import { EmptyDiv, Header, Info, Rate, Round, StudentContainer, StudentList, StyledLink, Title } from './styled';

const AssessmentsScoreDetail = ({
  header,
  match,
  rate: { grade, studentsWithThisGrade, rate },
  ...rest
}) => {
  const students = rate > 1 ? 'STUDENTS' : 'STUDENT';
  const selected = queryString.stringify(
    { selectedStudents: studentsWithThisGrade && studentsWithThisGrade.map(student => student.student_enrollment_id) },
    { arrayFormat: 'bracket' },
  );

  const headerTitle = (
    <Header>
      <Rate grade={grade}>
        <Round>
          {grade}
        </Round>
      </Rate>
      {header}
    </Header>
  );

  return (
    <Modal headerTitle={headerTitle} {...rest}>
      <Title>
        <Info>
          <strong>{rate}</strong>&nbsp;{students} WITH A RATING OF&nbsp;<strong>{grade}</strong>
        </Info>
        <StyledLink tabIndex={-1} to={`/collections/${match.params.collectionId}/activities/create/?${selected}`}>
          <Button color="#70a823" icon="plus">create activity</Button>
        </StyledLink>
      </Title>
      <StudentList tabIndex={0}>
        {studentsWithThisGrade && studentsWithThisGrade.map(({ avatar, name, id }) => (
          <StudentContainer key={id}>
            <Student
              avatar={avatar}
              firstname={name.firstname}
              lastname={name.lastname}
              middlename={name.middlename}
              gender={name.gender}
            />
          </StudentContainer>
        ))}
      </StudentList>
      <EmptyDiv />
    </Modal>
  );
};

AssessmentsScoreDetail.propTypes = {
  header: PropTypes.string.isRequired,
  match: PropTypes.object.isRequired,
  rate: PropTypes.object.isRequired,
};

export default withRouter(AssessmentsScoreDetail);
