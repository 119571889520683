import styled from 'styled-components';

export const Container = styled.div`
  width: 100%;
  display: flex;
  padding-bottom: 20px;
`;

export const Item = styled.div`
  display: flex;
  flex-direction: column;
  width: 50px;
  height: 100px;
  font-size: 14px;
  background-color: ${({ color }) => color};
  border: 1px solid ${({ theme }) => theme.primaryGrey};
  border-right: none;

  &:last-child {
    border-right: 1px solid ${({ theme }) => theme.primaryGrey};
  }
`;

export const Title = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 50px;
  height: 50px;
  background-color: ${({ grade, theme }) => theme.grades[grade]}
`;

export const Value = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 50px;
  height: 50px;
  border-top: 1px solid ${({ theme }) => theme.primaryGrey};
  background-color: ${({ theme }) => theme.primaryWhite};
`;

export const Round = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 30px;
  height: 30px;
  line-height: 100%;
  border-radius: 50%;
  background-color: rgba(0,0,0,0.6);
  color: ${({ theme }) => theme.primaryWhite};
`;

export const ColoredRound = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 30px;
  height: 30px;
  line-height: 100%;
  border-radius: 50%;
  border: ${({ isSelected, theme }) => (!isSelected ? `1px solid ${theme.primaryGrey}` : 'none')};
  background-color: ${({ grade, isSelected, theme }) => (isSelected ? theme.grades[grade] : theme.lightGrey)};
  color: ${({ theme }) => theme.primaryWhite};
  cursor: pointer;
  box-shadow: inset 0 0 4px ${({ theme }) => theme.primaryGrey};

  &:hover {
    background-color: ${({ background, isSelected }) => (isSelected ? background : '#e6e6e6')};
  }
`;
