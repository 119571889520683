import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import { getPriorityTasks } from 'redux/modules/assessments-overview/selectors';
import Header from './components/Header';
import TableContent from './components/TableContent';
import { Container, HeaderContainer, ScrollDiv } from './styled';

class Table extends PureComponent {
  static propTypes = {
    assessments: PropTypes.array.isRequired, // eslint-disable-line react/no-unused-prop-types
    groupTasks: PropTypes.array.isRequired,
    list: PropTypes.array.isRequired,
    separators: PropTypes.arrayOf(PropTypes.object).isRequired,
  }

  constructor(props) {
    super(props);

    this.registerTableRef = this.registerTableRef.bind(this);
    this.registerSeparatorRef = this.registerSeparatorRef.bind(this);
    this.itemClicked = this.itemClicked.bind(this);
  }

  state = {
    isScrollVisible: false,
  }

  componentDidMount() {
    if (this.tableRef.clientWidth === this.tableRef.offsetWidth) {
      this.setState({ isScrollVisible: true });
    }
  }

  componentWillUpdate() {
    this.hashOfRefs = [];
  }

  componentWillUnmount() {
    this.tableRef = null;
    this.separatorRef = null;
  }

  hashOfRefs = [];
  separatorRef = null;
  tableRef = null;

  registerTableRef(ref) {
    this.tableRef = ref;
  }

  registerSeparatorRef(ref) {
    if (ref) {
      this.hashOfRefs.push(ref);
    }
  }

  itemClicked(item) {
    const node = this.hashOfRefs[item.id];

    if (node && node.scrollIntoView) {
      node.scrollIntoView();
      node.focus();
    }
  }

  render() {
    const { groupTasks, list, separators } = this.props;

    return (
      <Container>
        <HeaderContainer>
          <Header onDropdownItem={this.itemClicked} list={list} separators={separators} />
          {!this.state.isScrollVisible && <ScrollDiv />}
        </HeaderContainer>
        <TableContent
          registerTableRef={this.registerTableRef}
          registerSeparatorRef={this.registerSeparatorRef}
          tasks={groupTasks}
        />
      </Container>
    );
  }
}

const mapStateToProps = ({ scale }, { assessments }) => ({
  groupTasks: getPriorityTasks(assessments, scale),
});

export default connect(mapStateToProps)(Table);
