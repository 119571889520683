import React from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { Nav, NavItem } from './styled';

const isActive = module => (match, { pathname }) => pathname.indexOf(`/${module}/`) !== -1;

const Navigation = ({ match, studentsCount }) => (
  <Nav role="menu">
    <NavItem
      role="menuitem"
      tabIndex={0}
      isActive={isActive('students')}
      to={`/collections/${match.params.collectionId}/students/list`}
    >
      STUDENTS({studentsCount})
    </NavItem>
    <NavItem
      role="menuitem"
      tabIndex={0}
      isActive={isActive('groups')}
      to={`/collections/${match.params.collectionId}/groups/list`}
      color="#9933cc"
    >
      GROUPS
    </NavItem>
    <NavItem
      role="menuitem"
      tabIndex={0}
      isActive={isActive('activities')}
      to={`/collections/${match.params.collectionId}/activities/list`}
      color="#ff9933"
    >
      ACTIVITIES
    </NavItem>
  </Nav>
);

Navigation.propTypes = {
  match: PropTypes.object.isRequired,
  studentsCount: PropTypes.number.isRequired,
};

const mapStateToProps = ({ students }) => ({
  studentsCount: students.list.length,
});

export default withRouter(connect(mapStateToProps)(Navigation));
