import styled from 'styled-components';

export const ScrollWrapper = styled.div`
`;

export const Container = styled.div`
  width: 100%;
  max-height: 500px;
  overflow: auto;
  background: ${props => (props.type === 'header' ? '#01537a' : '#eee')};
  border: ${props => (props.type === 'header' ? 'none' : '1px solid #ccc')};
  box-sizing: border-box;
  position: relative;
  z-index: 10;
`;
