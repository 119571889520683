import styled from 'styled-components';

export const Container = styled.div`
  box-sizing: border-box;
  display: flex;
  width: 100%;
  height: 70px;
  flex-shrink: 0;
  align-items: center;
  justify-content: center;
  border-bottom: 1px solid #ccc;
  background-color: ${props => (props.disabled && !props.isExpanded ? props.theme.lightGrey : '')};
  opacity: ${props => (props.disabled && !props.isExpanded ? 0.5 : 1)};
  pointer-events: ${props => (props.disabled && !props.isExpanded ? 'none' : 'auto')};
`;

export const Item = styled.div`
  box-sizing: border-box;
  display: flex;
  width: ${props => (props.columnWidth ? `${props.columnWidth}px` : '100%')};
  max-width: ${props => (props.primaryWidth ? props.primaryWidth : 'invalid')};
  flex-shrink: ${props => (props.columnWidth || props.primaryWidth ? 0 : 1)};
  height: 69px;
  align-items: center;
  justify-content: ${props => (props.primaryWidth ? 'flex-start' : 'center')};
  border-right: ${props => (props.isExpanded ? '1px solid #ccc' : 'none')};
  color: #666;
  font-size: 18px;
  padding-left: ${props => (props.primaryWidth ? '15px' : '0')};
  overflow: hidden;
  cursor: ${props => (props.isClickable ? 'pointer' : 'default')};

  &:hover {
    background-color: ${props => (props.isClickable ? '#eee' : '')};
  }
`;
