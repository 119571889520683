import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import update from 'immutability-helper';
import { connect } from 'react-redux';

import {
  deleteArtifact,
  fetchActivity,
  loadDefaultActivityState,
  uploadActivityArtifact,
} from 'redux/modules/activity';

import { getArtifacts } from 'redux/modules/activity/selectors';
import NewArtifactModal from 'components/Modals/NewArtifactModal';
import ArtifactsList from 'components/ArtifactsList';
import Button from 'components/Button';
import Preloader from 'components/Preloader';
import Subheader from 'components/Subheader';
import { Container, StyledButton } from './styled';

class BrowseArtifacts extends PureComponent {
  static propTypes = {
    activity: PropTypes.object.isRequired,
    artifacts: PropTypes.arrayOf(PropTypes.object).isRequired,
    deleteArtifact: PropTypes.func.isRequired,
    fetchActivity: PropTypes.func.isRequired,
    history: PropTypes.object.isRequired,
    loadDefaultActivityState: PropTypes.func.isRequired,
    loading: PropTypes.bool.isRequired,
    match: PropTypes.object.isRequired,
    uploadActivityArtifact: PropTypes.func.isRequired,
  }

  constructor(props) {
    super(props);

    this.goBack = this.goBack.bind(this);
    this.onDeleteArtifact = this.onDeleteArtifact.bind(this);
    this.toggleAddArtifactModal = this.toggleAddArtifactModal.bind(this);
    this.uploadArtifact = this.uploadArtifact.bind(this);
  }

  state = {
    addArtifactModalOpen: false,
  }

  componentDidMount() {
    if (!this.props.activity.id) {
      const { activityId, collectionId } = this.props.match.params;

      this.props.fetchActivity(collectionId, activityId);
    }
  }

  componentWillUnmount() {
    this.props.loadDefaultActivityState();
  }

  onDeleteArtifact(artifactId) {
    const { collectionId, activityId } = this.props.match.params;

    this.props.deleteArtifact(collectionId, activityId, artifactId);
  }

  goBack() {
    this.props.history.goBack();
  }

  toggleAddArtifactModal() {
    this.setState(update(this.state, {
      addArtifactModalOpen: { $apply: addArtifactModalOpen => !addArtifactModalOpen },
    }));
  }

  uploadArtifact(file, comment) {
    const { activityId, collectionId } = this.props.match.params;

    this.props.uploadActivityArtifact(collectionId, activityId, file, comment);
  }

  render() {
    const { activity, artifacts, loading } = this.props;

    return (
      <Container>
        <Preloader loading={loading} />
        <Subheader module="activities">
          <Button
            icon="back"
            size="lg"
            color="transparent"
            onClick={this.goBack}
          >
            {activity.label} - {moment(activity.end_date).format('M/D/YYYY')}
          </Button>
          <StyledButton
            aria-label="Add activity artifact"
            size="lg"
            color="transparent"
            icon="plus"
            onClick={this.toggleAddArtifactModal}
          />
        </Subheader>
        <ArtifactsList downloadable artifacts={artifacts} deletable deleteArtifact={this.onDeleteArtifact} />
        <NewArtifactModal
          isOpen={this.state.addArtifactModalOpen}
          onRequestClose={this.toggleAddArtifactModal}
          uploadArtifact={this.uploadArtifact}
        />
      </Container>
    );
  }
}

const mapStateToProps = ({ activity }) => ({
  activity: activity.activity,
  artifacts: getArtifacts(activity),
  loading: activity.loading,
});

const mapDispatchToProps = {
  deleteArtifact,
  fetchActivity,
  loadDefaultActivityState,
  uploadActivityArtifact,
};

export default connect(mapStateToProps, mapDispatchToProps)(BrowseArtifacts);
