import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import { selectTask } from 'redux/modules/activity';
import { getTasksList } from 'redux/modules/activity/selectors';
import Title from 'components/Title';
import Item from './components/Item';
import Separator from './components/Separator';
import { Container, Content } from './styled';

class Tasks extends PureComponent {
  static propTypes = {
    chooseStudents: PropTypes.bool.isRequired,
    selectTask: PropTypes.func.isRequired,
    tasks: PropTypes.arrayOf(PropTypes.object).isRequired,
  }

  render() {
    const { chooseStudents, tasks } = this.props;

    return (
      <Container>
        <Title text="Tasks" />
        <Content id="task-list" tabIndex={0} aria-label="Tasks list">
          {tasks.map((task, index) => (task.separator ?
            <Separator
              key={index}
              text={task.separator}
              chooseStudents={chooseStudents}
            /> :
            <Item
              key={index}
              chooseStudents={chooseStudents}
              task={task}
              onChange={this.props.selectTask}
            />
          ))}
        </Content>
      </Container>
    );
  }
}

const mapStateToProps = ({ activity, scale, tasks }) => ({
  tasks: getTasksList(activity, scale, tasks),
});

export default connect(mapStateToProps, { selectTask })(Tasks);
