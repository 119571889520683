import React from 'react';
import { Route, Switch } from 'react-router-dom';

import Subheader from 'components/Subheader';
import NotesList from 'components/NotesList';
import ActivitiesTable from './components/ActivitiesTable';
import Artifacts from './components/Artifacts';
import Footer from './components/Footer';
import Navigation from './components/Navigation';
import Container from './styled';

const base = '/collections/:collectionId/activities/list';
const closedActivitiesTable = props => <ActivitiesTable filter="CLOSED" {...props} />;
const openActivitiesTable = props => <ActivitiesTable filter="OPEN" {...props} />;
const displayNotesList = props => <NotesList clickable {...props} />;

const ActivitiesList = () => (
  <Container role="main">
    <Subheader module="activities">
      <div>Activities</div>
    </Subheader>
    <Navigation />
    <Switch>
      <Route path={`${base}/open`} render={openActivitiesTable} />
      <Route path={`${base}/closed`} render={closedActivitiesTable} />
      <Route path={`${base}/notes`} render={displayNotesList} />
      <Route path={`${base}/artifacts`} component={Artifacts} />
    </Switch>
    <Footer />
  </Container>
);

export default ActivitiesList;
