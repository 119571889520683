import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import { selectLevel, togglePriority } from 'redux/modules/scale';
import { getSelectedLevels } from 'redux/modules/scale/selectors';
import Modal from 'components/Modal';
import Level from './components/Level';
import Switcher from './components/Switcher';
import { Content, Header, LevelsList, Title } from './styled';

const LevelSettingsModal = ({
  list,
  selectLevel: checkLevel,
  showPriority,
  togglePriority: toggle,
  ...rest
}) => (
  <Modal {...rest} headerTitle={<Header>Settings</Header>}>
    <Content>
      <Title>
        Scale Settings
        <div>Show or hide the scale items displayed on overview screens.</div>
      </Title>
      <LevelsList>
        {list.map(item => <Level key={item.label} level={item} selectLevel={checkLevel} />)}
      </LevelsList>
      <Title>
        Priority Items
        <div>Display only priority items.</div>
      </Title>
      <Switcher toggle={toggle} value={showPriority} />
    </Content>
  </Modal>
);

LevelSettingsModal.propTypes = {
  list: PropTypes.array.isRequired,
  selectLevel: PropTypes.func.isRequired,
  showPriority: PropTypes.bool.isRequired,
  togglePriority: PropTypes.func.isRequired,
};

const mapStateToProps = ({ scale }) => ({
  list: getSelectedLevels(scale),
  showPriority: scale.showPriority,
});

export default connect(mapStateToProps, { selectLevel, togglePriority })(LevelSettingsModal);
