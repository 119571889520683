import styled from 'styled-components';
import Select from 'react-select';

const StyledSelect = styled(Select).attrs({
  backspaceRemoves: false,
  clearable: false,
  deleteRemoves: false,
})`
  flex: ${({ name }) => (name === 'month' ? '1' : 'none')};
  display: block;
  width: ${({ name }) => (name === 'month' ? 'auto' : '170px')};
  text-align: left;
  margin-right: 10px;

  div, span {
    color: ${({ theme }) => theme.darkGrey} !important;
    border-radius: 0;
  }
`;

export default StyledSelect;
