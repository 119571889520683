import React from 'react';
import PropTypes from 'prop-types';

import Attachment from '../Attachment';
import Container from './styled';

const AttachmentsPreview = ({ files, onRemove }) => (
  <Container>
    {files.map(({ name, preview }, index) => (
      <Attachment
        key={index}
        name={name}
        preview={preview}
        onRemove={() => onRemove(index)}
      />
    ))}
  </Container>
);

AttachmentsPreview.propTypes = {
  files: PropTypes.array.isRequired,
  onRemove: PropTypes.func.isRequired,
};

export default AttachmentsPreview;
