import React from 'react';

import SelectGroup from 'components/SelectGroup';

import { Container, Title } from './styled';

const Subheader = () => (
  <Container>
    <Title>ASSESSMENT OVERVIEW</Title>
    <SelectGroup />
  </Container>
);

export default Subheader;
