import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import { createNote, loadStateToCreateNote } from 'redux/modules/notes';
import CreateEditNote from 'modules/activities/components/CreateEditNote';

class CreateNote extends PureComponent {
  static propTypes = {
    createNote: PropTypes.func.isRequired,
    loadStateToCreateNote: PropTypes.func.isRequired,
    match: PropTypes.object.isRequired,
  }

  constructor(props) {
    super(props);

    this.createNote = this.createNote.bind(this);
  }

  componentDidMount() {
    this.props.loadStateToCreateNote();
  }

  createNote(note) {
    this.props.createNote(this.props.match.params.collectionId, note);
  }

  render() {
    return <CreateEditNote onRequestSave={this.createNote} />;
  }
}

export default connect(null, { createNote, loadStateToCreateNote })(CreateNote);
