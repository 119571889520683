import styled from 'styled-components';
import Button from 'components/Button';
import Dropzone from 'react-dropzone';

export const Container = styled.div`
  display: flex;
  margin-top: 10px;
  padding: 0 30px;
  flex-shrink: 0;
`;

export const TitleColumn = styled.div`
  width: 150px;
  font-weight: 600;
  flex-shrink: 0;
`;

export const StyledDropzone = styled(Dropzone)`

`;

export const StyledButton = styled(Button)`
  background: ${({ theme }) => theme.lightGrey};
  text-transform: none;
  font-size: 12px;
  font-weight: 400;
  color: #000;
  border: 1px solid ${({ theme }) => theme.primaryGrey};
  width: 120px;
  padding: 8px 0;
  margin-top: 10px;
  height: auto;
  border-radius: 5px;
`;

export const BrowsedImages = styled.div`
  display: flex;
  flex: 1;
  height: 190px;
  flex-direction: column;
  box-sizing: border-box;
  flex-shrink: 0;
  border: 1px solid ${({ theme }) => theme.primaryGrey};
  overflow-y: auto;
`;
