import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  margin-bottom: 15px;
  margin-right: 10px;

  &:last-child {
    margin-bottom: 0;
  }
`;

export const Thumbnail = styled.img`
  height: 60px;
  border: 5px solid ${({ theme }) => theme.primaryGrey};
`;

export const Details = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  margin-left: 10px;
`;
