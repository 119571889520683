import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { Redirect, withRouter } from 'react-router';
import { connect } from 'react-redux';
import { submit } from 'redux-form';

import { setShouldGoToActivitiesList } from 'redux/modules/activity';
import BaseFooter from 'components/BaseFooter';
import { StyledButton, Label } from './styled';

class Footer extends PureComponent {
  static defaultProps = {
    createdId: null,
  }

  static propTypes = {
    createdId: PropTypes.number,
    match: PropTypes.object.isRequired,
    mode: PropTypes.string.isRequired,
    onSaveClick: PropTypes.func.isRequired,
    setShouldGoToActivitiesList: PropTypes.func.isRequired,
    shouldGoToActivitiesList: PropTypes.bool.isRequired,
    submit: PropTypes.func.isRequired,
  }

  constructor(props) {
    super(props);

    this.next = this.next.bind(this);
    this.save = this.save.bind(this);
    this.saveAndStart = this.saveAndStart.bind(this);
  }

  next() {
    this.props.submit('activityDescription');
  }

  save() {
    this.props.onSaveClick();
    this.props.setShouldGoToActivitiesList();
  }

  saveAndStart() {
    this.props.onSaveClick();
  }

  render() {
    const {
      createdId,
      match: { params: { collectionId } },
      mode,
      shouldGoToActivitiesList,
    } = this.props;

    if (shouldGoToActivitiesList && createdId) {
      return <Redirect to={`/collections/${collectionId}/activities/list`} />;
    }

    if (createdId) {
      return <Redirect to={`/collections/${collectionId}/activities/${createdId}/detail`} />;
    }

    switch (mode) {
      case 'description':
        return (
          <BaseFooter>
            <StyledButton
              aria-label="Next to choose students"
              onClick={this.next}
            >
              Next
            </StyledButton>
            <Label role="presentation">Choose Students</Label>
          </BaseFooter>
        );
      case 'students':
        return (
          <BaseFooter>
            <StyledButton
              aria-label="Save and start activity"
              onClick={this.saveAndStart}
            >
              Save and start
            </StyledButton>
            <StyledButton
              aria-label="Save activity"
              onClick={this.save}
            >
              Save
            </StyledButton>
          </BaseFooter>
        );
      default: return <BaseFooter />;
    }
  }
}

const mapStateToProps = ({ activity }) => ({
  createdId: activity.createdId,
  shouldGoToActivitiesList: activity.shouldGoToActivitiesList,
});

export default withRouter(connect(mapStateToProps, { setShouldGoToActivitiesList, submit })(Footer));
