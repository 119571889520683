import styled from 'styled-components';
import { NavLink } from 'react-router-dom';

export const Nav = styled.ul`
  list-style: none;
  margin: 0;
  overflow: hidden;
  position: relative;
  display: flex;
  padding: 0;
`;

export const NavItem = styled(NavLink)`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100px;
  color: white;
  text-align: center;
  padding: 0 12px;
  text-decoration: none;
  position: relative;
  border-left: 1px solid #005078;
  border-right: 1px solid rgba(255,255,255,0.1);

  &.active, &:hover {
    background: ${props => props.color || '#3399ff'};
  }

  &:hover:before, &.active:before {
    content: '';
    position: absolute;
    height: 100%;
    width: 100%;
    top: 0;
    left: 0;
    background: linear-gradient(0deg, rgba(0,0,0,0), rgba(0,0,0,0.03) 65%, rgba(0,0,0,0.1));
  }

  &:hover:after, &.active:after {
    content: '';
    position: absolute;
    left: 8px;
    bottom: 12px;
    width: 85%;
    border-bottom: 5px solid white;
  }
`;
