import React from 'react';
import PropTypes from 'prop-types';

import withKeyDown from 'components/withKeyDown';
import ExitIcon from './images/icons/exit-icon.png';
import GroupsIcon from './images/icons/groups-icon.png';
import SettingsIcon from './images/icons/settings-icon.png';
import AssessmentsIcon from './images/icons/assessments-icon.png';
import NotificationsIcon from './images/icons/notifications-icon.png';
import UploadIcon from './images/icons/upload-icon.png';
import { Container, Clickable, Icon, Label } from './styled';

const icons = {
  exit: ExitIcon,
  groups: GroupsIcon,
  settings: SettingsIcon,
  assessments: AssessmentsIcon,
  notifications: NotificationsIcon,
  upload: UploadIcon,
};

const ClickableWithKeyDown = withKeyDown(Clickable, 'Both');

const NavbarItem = ({
  icon,
  handleClick,
  amount,
  isActive,
}) => (
  <Container>
    <ClickableWithKeyDown isActive={isActive} tabIndex={0} onClick={() => handleClick()}>
      <Icon src={icons[icon]} alt={icon} />
      {amount !== 0 && <Label>{amount}</Label>}
    </ClickableWithKeyDown>
  </Container>
);

NavbarItem.propTypes = {
  icon: PropTypes.string.isRequired,
  handleClick: PropTypes.func.isRequired,
  amount: PropTypes.number,
  isActive: PropTypes.bool,
};

NavbarItem.defaultProps = {
  amount: 0,
  isActive: false,
};

export default NavbarItem;
