import styled from 'styled-components';

const Container = styled.div`
  display: flex;
  flex-direction: row;
  flex: none;
  background-color: ${({ theme }) => (theme.primaryDark)};
  justify-content: flex-start;
  align-items: center;
`;

export default Container;
