import React from 'react';
import PropTypes from 'prop-types';

import Error from './styled';

const ErrorMessage = ({ text }) => <Error>{text}</Error>;

ErrorMessage.propTypes = {
  text: PropTypes.string.isRequired,
};

export default ErrorMessage;
