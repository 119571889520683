import styled from 'styled-components';

const headerHeight = 70;

export const Header = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
  height: ${headerHeight}px;
  min-height: ${headerHeight}px;
  padding: 0 20px;
  background-color: ${({ theme }) => theme.primaryTurquoise};
`;

export const HeaderText = styled.h3`
  color: ${({ theme }) => theme.primaryWhite};
  font-size: 20px;
  font-weight: 700;
  margin: 0;
`;

export const Content = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  height: 100%;
  overflow: hidden;
`;

export const Container = styled.div`
  display: flex;
  width: 100%;
  height: calc(100% - ${headerHeight}px);
  flex-direction: column;
  overflow: hidden;
`;
