import React from 'react';
import PropTypes from 'prop-types';

import ErrorMessage from 'components/ErrorMessage';
import { CustomField, StyledLabel } from './styled';

const RenderCustomField = ({
  input,
  label,
  meta: { touched, error },
  type: Component,
}) => (
  <CustomField>
    <StyledLabel htmlFor={label}>{label}</StyledLabel>
    <Component
      {...input}
      aria-label={`${label}: ${input.value || `No ${label}`}`}
      id={label}
      type="text"
      value={input.value}
    />
    {error && touched && <ErrorMessage text={error} />}
  </CustomField>
);

RenderCustomField.propTypes = {
  input: PropTypes.object.isRequired,
  label: PropTypes.string.isRequired,
  meta: PropTypes.object.isRequired,
  type: PropTypes.func.isRequired,
};

export default RenderCustomField;
