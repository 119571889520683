import styled from 'styled-components';

export const Container = styled.div`
  position: fixed;
  z-index: 98;
  transform: translate3d(${props => (props.isOpened ? '75px' : '-400px')}, 0, 0);
  width: 400px;
  background-color: #fff;
  height: 100vh;
  transition: 0.5s;
  border: 1px solid #F4F4F4;
`;

export const Header = styled.div`
  background-color: #F1F1F1;
  height: 60px;
  display: flex;
  align-items: center;
  color: #575757;
  padding: 0 20px;
  font-size: 14x;
  font-weight: 600;
  text-transform: uppercase;
  border-bottom: 2px solid #F4F4F4;
`;

export const NoNotifications = styled.span`
  margin-top: 15px;
  display: flex;
  justify-content: center;
  color: #575757;
`;
