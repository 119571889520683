import update from 'immutability-helper';

import http from '../../../http';
import { formatNoteForPost, formatNotes } from './formatters';

const API_REQUEST = 'rfc/activities/list/notes/API_REQUEST';
const FETCH_NOTES_SUCCESS = 'rfc/activities/list/notes/FETCH_NOTES_SUCCESS';
const FETCH_NOTES_FAILURE = 'rfc/activities/list/notes/FETCH_NOTES_FAILURE';
const CREATE_NOTE_SUCCESS = 'rfc/activities/note/create/CREATE_NOTE_SUCCESS';
const CREATE_NOTE_FAILURE = 'rfc/activities/note/create/CREATE_NOTE_FAILURE';
const EDIT_NOTE_SUCCESS = 'rfc/activities/note/edit/EDIT_NOTE_SUCCESS';
const EDIT_NOTE_FAILURE = 'rfc/activities/note/edit/EDIT_NOTE_FAILURE';
const CREATE_NOTE_STATE = 'rfc/activities/note/create/CREATE_NOTE_STATE';

const initialState = {
  notes: [],
  createdId: null,
  error: '',
  loading: true,
};

export default function reducer(state = initialState, action = {}) {
  switch (action.type) {
    case API_REQUEST:
      return update(state, { $merge: { loading: true } });
    case FETCH_NOTES_SUCCESS:
      return update(state, {
        $set: {
          notes: action.payload,
          createdId: null,
          error: '',
          loading: false,
        },
      });
    case CREATE_NOTE_SUCCESS:
      return update(state, { $merge: { createdId: action.payload.id, error: '', loading: false } });
    case EDIT_NOTE_SUCCESS:
      return update(state, { $merge: { createdId: action.payload.id, error: '', loading: false } });
    case FETCH_NOTES_FAILURE:
      return update(state, { $merge: { error: action.payload, loading: false } });
    case CREATE_NOTE_FAILURE:
      return update(state, { $merge: { error: action.payload, loading: false } });
    case EDIT_NOTE_FAILURE:
      return update(state, { $merge: { error: action.payload, loading: false } });
    case CREATE_NOTE_STATE:
      return update(state, { $merge: { createdId: null, loading: false } });
    default:
      return state;
  }
}

const errorNotes = payload => ({ type: FETCH_NOTES_FAILURE, payload });
const receiveNotes = payload => ({ type: FETCH_NOTES_SUCCESS, payload });

export const fetchNotes = (collectionId = 0) => (dispatch) => {
  dispatch({ type: API_REQUEST });
  http.get(`/en/api/v2/collections/${collectionId}/notes.json`)
    .then(response => dispatch(receiveNotes(formatNotes(response.data))))
    .catch(response => dispatch(errorNotes(response)));
};

const failedCreateNote = payload => ({ type: CREATE_NOTE_FAILURE, payload });
const successCreateNote = payload => ({ type: CREATE_NOTE_SUCCESS, payload });

export const createNote = (collectionId = 0, note) => (dispatch) => {
  dispatch({ type: API_REQUEST });
  http.post(`/en/api/v2/collections/${collectionId}/notes.json`, formatNoteForPost(note))
    .then(response => dispatch(successCreateNote(response.data)))
    .catch(response => dispatch(failedCreateNote(response)));
};

const failedEditNote = payload => ({ type: EDIT_NOTE_FAILURE, payload });
const successEditNote = payload => ({ type: EDIT_NOTE_SUCCESS, payload });

export const editNote = (collectionId = 0, noteId = 0, note) => (dispatch) => {
  dispatch({ type: API_REQUEST });
  http.put(`/en/api/v2/collections/${collectionId}/notes/${noteId}`, formatNoteForPost(note))
    .then(response => dispatch(successEditNote(response.data)))
    .catch(response => dispatch(failedEditNote(response)));
};

export const loadStateToCreateNote = () => ({ type: CREATE_NOTE_STATE });
