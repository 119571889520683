import React from 'react';
import PropTypes from 'prop-types';

import Modal from 'components/Modal';
import ResourcesList from 'components/ResourcesList';
import { Container, Header, Icon, Subheader, TaskCategory, TaskName, Title } from './styled';

const TaskMaterialsModal = ({ task: { category, group, title }, ...rest }) => (
  <Modal {...rest} headerTitle={<Header><Icon />Resources/materials</Header>}>
    <Container>
      <Subheader>{group}</Subheader>
      <Title>
        <TaskName>{category}</TaskName>
        <TaskCategory>{title}</TaskCategory>
      </Title>
      <ResourcesList />
    </Container>
  </Modal>
);

TaskMaterialsModal.propTypes = {
  task: PropTypes.object.isRequired,
};

export default TaskMaterialsModal;
