import React from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router';

import Tab from './components/Tab';
import Container from './styled';

const tabs = [
  { text: 'Open Activities', to: 'open' },
  { text: 'Closed Activities', to: 'closed' },
  { text: 'Notes', to: 'notes' },
  { text: 'Artifacts', to: 'artifacts' },
];

const Navigation = ({ location }) => (
  <Container>
    {tabs.map(tab => <Tab key={tab.to} location={location} text={tab.text} to={tab.to} />)}
  </Container>
);

Navigation.propTypes = {
  location: PropTypes.object.isRequired,
};

export default withRouter(Navigation);
