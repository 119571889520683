import React from 'react';
import PropTypes from 'prop-types';

import ArtifactItem from 'components/ArtifactItem';

import { Container } from './styled';

const ImagesList = ({ images, onDelete }) => (
  <Container>
    {images.map((item, index) => (
      <ArtifactItem artifact={item} withBorder key={index} onDelete={() => onDelete(index)} />
    ))}
  </Container>
);

ImagesList.propTypes = {
  images: PropTypes.array.isRequired,
  onDelete: PropTypes.func.isRequired,
};

export default ImagesList;
