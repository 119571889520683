import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { Route, Redirect, Switch, withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { isMobile } from 'react-device-detect';

import { unsetShouldRedirectToLogin } from 'redux/modules/app';
import Sidebar from 'components/Sidebar';
import Collections from 'modules/collections';
import ScoreSheets from 'modules/scoreSheets';

import { checkRulesetAccess } from 'redux/modules/auth/helpers';

import WithCollectionId from './components/WithCollectionId';

import { Container } from './styled';

const Root = () => <Redirect to="/collections/list" />;

class Main extends PureComponent {
  static propTypes = {
    location: PropTypes.object.isRequired,
    shouldRedirectToLogin: PropTypes.bool.isRequired,
    unsetShouldRedirectToLogin: PropTypes.func.isRequired,
    isRulesetAccess: PropTypes.bool.isRequired,
  }

  componentWillUnmount() {
    this.props.unsetShouldRedirectToLogin();
  }

  render() {
    const { isRulesetAccess, shouldRedirectToLogin, location } = this.props;

    if (shouldRedirectToLogin) {
      return <Redirect to={{ pathname: '/login', state: { from: location } }} />;
    }

    return (
      <Container>
        <Sidebar />
        <Switch>
          <Route exact path="/" component={Root} />
          <Route exact path="/collections/list" component={Collections} />
          <Route path="/collections/:collectionId" component={WithCollectionId} />
          {/* isMobile: Upload page access only for Mobile and Tablet */}
          {/* isRulesetAccess: Upload page access only for ruleset value */}
          {isMobile && isRulesetAccess && <Route path="/scoresheets" component={ScoreSheets} />}
        </Switch>
      </Container>
    );
  }
}

const mapStateToProps = ({ auth, app: { shouldRedirectToLogin } }) => ({
  shouldRedirectToLogin,
  isRulesetAccess: checkRulesetAccess(auth.user.tenants.ruleset),
});

export default connect(mapStateToProps, { unsetShouldRedirectToLogin })(withRouter(Main));
