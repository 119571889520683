import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import queryString from 'query-string';
import update from 'immutability-helper';
import { withRouter } from 'react-router';
import { connect } from 'react-redux';

import { changeInputField, saveDescription } from 'redux/modules/activity';
import Button from 'components/Button';
import Preloader from 'components/Preloader';
import Subheader from 'components/Subheader';
import AddEditModal from './components/AddEditModal';
import Description from './components/Description';
import Footer from './components/Footer';
import Students from './components/Students';
import Tasks from './components/Tasks';
import { Container, Row } from './styled';

class CreateEditActivity extends PureComponent {
  static propTypes = {
    changeInputField: PropTypes.func.isRequired,
    description: PropTypes.string.isRequired,
    history: PropTypes.object.isRequired,
    loading: PropTypes.bool.isRequired,
    location: PropTypes.object.isRequired,
    onRequestSave: PropTypes.func.isRequired,
    saveDescription: PropTypes.func.isRequired,
  }

  constructor(props) {
    super(props);

    this.toggleChooseStudents = this.toggleChooseStudents.bind(this);
    this.toggleAddEditModal = this.toggleAddEditModal.bind(this);
    this.onModalRequestSave = this.onModalRequestSave.bind(this);
    this.goBack = this.goBack.bind(this);
  }

  state = {
    addEditModalShown: false,
    chooseStudents: false,
    mode: 'description',
  }

  componentDidMount() {
    const { pathname, search } = this.props.location;

    if (queryString.parse(search).mode === 'students' && !(/create/.test(pathname))) {
      this.toggleChooseStudents();
    }
  }

  onModalRequestSave(value) {
    this.props.saveDescription(value);
    this.setState(update(this.state, {
      addEditModalShown: { $apply: addEditModalShown => !addEditModalShown },
    }));
  }

  toggleChooseStudents() {
    const mode = this.state.mode === 'description' ? 'students' : 'description';
    this.setState(update(this.state, {
      $merge: { mode },
      chooseStudents: { $apply: chooseStudents => !chooseStudents },
    }));
  }

  toggleAddEditModal() {
    this.setState(update(this.state, {
      addEditModalShown: { $apply: addEditModalShown => !addEditModalShown },
    }));
  }

  goBack() {
    return this.state.chooseStudents ?
      this.toggleChooseStudents() :
      this.props.history.goBack();
  }

  render() {
    const { description, loading } = this.props;
    const { addEditModalShown, chooseStudents, mode } = this.state;

    return (
      <Container>
        <Preloader loading={loading} />
        <Subheader module="activities">
          <Button
            aria-label="Back"
            icon="back"
            size="lg"
            color="transparent"
            onClick={this.goBack}
          >
            <div>Create activity</div>
          </Button>
        </Subheader>
        <Row>
          <Tasks chooseStudents={chooseStudents} />
          {
            chooseStudents ?
              <Students /> :
              <Description
                description={description}
                onInputFieldChange={this.props.changeInputField}
                toggleAddEditModal={this.toggleAddEditModal}
                toggleChooseStudents={this.toggleChooseStudents}
              />
          }
        </Row>
        <Footer mode={mode} onSaveClick={this.props.onRequestSave} />
        <AddEditModal
          isOpen={addEditModalShown}
          onRequestClose={this.toggleAddEditModal}
          onModalRequestSave={this.onModalRequestSave}
          description={description}
        />
      </Container>
    );
  }
}

const mapStateToProps = ({ activity }) => ({
  description: activity.activity.description,
  loading: activity.loading,
});

export default withRouter(connect(mapStateToProps, { changeInputField, saveDescription })((CreateEditActivity)));
