import React from 'react';
import { Route, Switch } from 'react-router-dom';

import TaskRates from './pages/TaskRates';
import RatingDetail from './pages/RatingDetail';

const Rating = () => (
  <Switch>
    <Route exact path="/collections/:collectionId/rating/task/:taskId" component={TaskRates} />
    <Route path="/collections/:collectionId/rating/task/:taskId/student/:studentId" component={RatingDetail} />
  </Switch>
);

export default Rating;
