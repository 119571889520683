import styled from 'styled-components';
import { Link } from 'react-router-dom';
import Button from 'components/Button';
import HeaderBackground from './images/bg-header.png';

export const Content = styled.div`
  height: 70px;
  background: ${props => props.theme.primaryBlue} url(${HeaderBackground}) left / contain no-repeat;
  aling-items: center;
  box-sizing: border-box;
  display: flex;
  flex-shrink: 0;
  width: 100%;
  justify-content: space-between;

  @media (max-width: 675px) {
    background-size: cover;
  }
`;

export const LevelSettings = styled.div`
  height: 100%;
  display: flex;
  align-items: center;
  margin: 0 10px;
`;

export const AssessmentMain = styled(Link)`
  padding: 16px;
  display: flex;
  align-items: center;
  text-decoration: none;
  color: white;

  &:active {
    background-color:rgba(0, 80, 120, 0.3);
  }
`;

export const SettingsButton = styled(Button)`
  padding: 8px;
`;
