import styled from 'styled-components';
import Button from 'components/Button';

export const Artifacts = styled.div`
  display: ${({ studentExpanded }) => (studentExpanded ? 'flex' : 'none')};
  flex-direction: row;
  border-bottom: 1px solid ${({ theme }) => theme.primaryGrey};
  padding: 0 20px;
  overflow-y: auto;
`;

export const Icon = styled(Button)`
  margin-left: 15px;
  font-size: 16px;

  &:focus {
    outline-style: none;
  }
`;

export const Row = styled.div`
  display: flex;
  align-items: center;
  padding: 10px 15px;
  cursor: ${({ clickable }) => (clickable ? 'pointer' : 'auto')};
  border-bottom: ${({ studentExpanded, theme }) => (studentExpanded ? 'none' : `1px solid ${theme.primaryGrey}`)};
`;

export const Score = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  flex: none;
  margin-left: auto;
`;

export const StudentWrapper = styled.div`
  display: flex;
  margin-left: 15px;
`;

export const StyledButton = styled(Button)`
  text-transform: uppercase;
  margin-left: 15px;
  font-size: 16px;
`;

export const Wrapper = styled.div`
  margin: 15px 0;
`;
