import styled from 'styled-components';
import CloudIcon from './images/cloud.png';

export const Container = styled.div`
  padding-bottom: 15px;
  color: ${({ theme }) => theme.darkGrey};
  max-height: 70vh;
  overflow-y: auto;
`;

export const Header = styled.div`
  display: flex;
  align-items: center;
  height: 50px;
  width: 100%;
  text-transform: uppercase;
  font-size: 20px;
  color: ${({ theme }) => theme.darkGrey};
  background-color: ${({ theme }) => theme.lightGrey};
  border-bottom: 1px solid ${({ theme }) => theme.primaryGrey};
`;

export const Subheader = styled.div`
  display: flex;
  flex-direction: column;
  padding: 15px;
  padding-bottom: 0;
`;

export const Icon = styled.div`
  display: block;
  position: relative;
  width: 50px;
  height: 50px;
  margin-right: 15px;
  background: ${({ theme }) => theme.primaryGreen};

  &:after {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: url(${CloudIcon});
    background-repeat: no-repeat;
    background-size: 40%;
    background-position: 50%;
  }
`;

export const Comment = styled.div`
  display: flex;
  flex-direction: column;
  padding: 15px;
  box-sizing: border-box;
  border-bottom: 1px solid ${({ theme }) => theme.primaryGrey};
`;

export const Text = styled.div`
  display: flex;
  align-items: flex-start;
`;

export const Info = styled.div`
  display: flex;
  margin-top: 15px;
  font-size: 12px;
`;
