import styled from 'styled-components';
import AcrobatIcon from './images/acrobat.png';

export const Container = styled.div`
  padding-bottom: 15px;
  color: ${({ theme }) => theme.darkGrey};
`;

export const Header = styled.div`
  display: flex;
  align-items: center;
  height: 50px;
  width: 100%;
  text-transform: uppercase;
  font-size: 20px;
  color: ${({ theme }) => theme.darkGrey};
  background-color: ${({ theme }) => theme.lightGrey};
  border-bottom: 1px solid ${({ theme }) => theme.primaryGrey};
`;

export const Icon = styled.div`
  display: block;
  position: relative;
  width: 50px;
  height: 50px;
  margin-right: 15px;
  background: ${({ theme }) => theme.primaryBlue};

  &:after {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: url(${AcrobatIcon});
    background-repeat: no-repeat;
    background-size: 70%;
    background-position: 50%;
  }
`;

export const Subheader = styled.div`
  display: flex;
  align-items: center;
  box-sizing: border-box;
  padding-left: 20px;
  height: 50px;
  width: 100%;
  background: ${({ theme }) => theme.lightGrey};
  border-bottom: 1px solid ${({ theme }) => theme.primaryGrey};
  color: ${({ theme }) => theme.darkGrey};
  text-transform: uppercase;
  font-size: 14px;
  font-weight: 600;
`;

export const TaskCategory = styled.div`
  display: flex;
`;

export const TaskName = styled.div`
  display: flex;
  font-size: 12px;
  font-weight: 600;
  margin-bottom: 2px;
  text-transform: uppercase;
`;

export const Title = styled.div`
  display: flex;
  flex-direction: column;
  height: 70px;
  box-sizing: border-box;
  padding: 10px 20px;
  justify-content: center;
  border-bottom: 1px solid ${({ theme }) => theme.primaryGrey};
`;
