import styled from 'styled-components';

export const Container = styled.section`
  display: flex;
  flex-shrink: 0;
  width: 100%;
  height: auto !important;
  flex-direction: column;
`;

export const Styled = styled.div`

`;
