import { createSelector } from 'reselect';
import { getLabelById } from 'redux/modules/scale/helpers';
import { getArtifact } from 'redux/modules/grades/helpers';

export const getLevel = createSelector(
  data => data.grades,
  data => data.tasks,
  data => data.scale,
  data => data.currentStudent,
  data => data.props,
  (grades, tasks, scale, student, props) => {
    const artifact = getArtifact(
      grades.list,
      +props.match.params.taskId,
      student,
    );

    const levelId = artifact ? artifact.assessment_items_scales_entries_id : null;
    return getLabelById(scale, levelId);
  },
);

export const getSelectedLevels = createSelector(
  scale => scale.selectedLevels,
  selectedLevels => selectedLevels.slice(1),
);
