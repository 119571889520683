import moment from 'moment';
import { getLabelById } from 'redux/modules/scale/helpers';

function formatComments(comments) {
  if (!comments) {
    return [];
  }

  return comments.map(item => ({
    ...item,
    text: item.original_file_name,
    imageUrl: item.s3_file_name,
    author: `${item.userRelatedByCreatedBy.first_name} ${item.userRelatedByCreatedBy.last_name}`,
    date: moment(+(`${item.created_at}000`)).format('M/D/YYYY'),
    time: moment(+(`${item.created_at}000`)).format('h:mm A'),
  }));
}

function formatArtifacts(artifact) {
  if (!artifact) {
    return {};
  }

  return {
    comment: artifact.comment,
    createdAt: moment(+(`${artifact.created_at}000`)).format('M/D/YYYY | h:mm A'),
    createdBy: `
      ${artifact.userRelatedByCreatedBy.first_name}
      ${artifact.userRelatedByCreatedBy.last_name}
    `,
    comments: formatComments(artifact.dataCollectionsStudentScoreArtifactsHistories ||
      artifact.dataCollectionsStudentScoreArtifacts),
  };
}

export const formatHistory = (history, scale) => history.map(item => ({
  id: item.id,
  time: moment(+(`${item.observed_at}000`)).format('M/D/YYYY - h:mm A'),
  scoredBy: `${item.userRelatedByCreatedBy.first_name} ${item.userRelatedByCreatedBy.last_name}`,
  rating: getLabelById(scale, item.assessment_items_scales_entries_id),
  comments: formatArtifacts(item),
}));

export const formatter = () => {};
