import styled from 'styled-components';

export const Container = styled.div`
  color: ${({ theme }) => theme.darkGrey};
  height: 600px;
  max-height: 70vh;
  overflow-y: auto;
`;

export const Footer = styled.div`
  display: flex;
  margin-left: 15px;
  padding: 15px 0;
  box-sizing: border-box;

  button {
    width: 120px;
    margin-right: 15px;
  }
`;
