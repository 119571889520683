import React from 'react';
import PropTypes from 'prop-types';

import BrowseArtifactsModal from 'components/Modals/BrowseArtifactsModal';
import ImagesList from './components/ImagesList';
import { Container, TitleColumn, StyledDropzone, StyledButton, BrowsedImages } from './styled';

const Artifacts = ({
  addSelected,
  assignInnerRef,
  closeModal,
  deleteFromImages,
  deleteFromSavedImages,
  handleKeyDown,
  isOpen,
  modalInput: { images, savedImages },
  onDrop,
  openModal,
}) => (
  <Container>
    <TitleColumn>
      Artifacts
      <StyledDropzone
        innerRef={assignInnerRef}
        role="button"
        aria-label="input file dropzone"
        multiple
        tabIndex={0}
        onDrop={onDrop}
        onKeyDown={handleKeyDown}
      >
        <StyledButton>Browse Device</StyledButton>
      </StyledDropzone>
      <StyledButton onClick={openModal}>Browse Artifacts</StyledButton>
    </TitleColumn>
    <BrowsedImages>
      <ImagesList onDelete={deleteFromSavedImages} images={savedImages} />
      <ImagesList onDelete={deleteFromImages} images={images} />
    </BrowsedImages>

    <BrowseArtifactsModal
      onRequestClose={closeModal}
      isOpen={isOpen}
      addSelected={addSelected}
    />
  </Container>
);

Artifacts.propTypes = {
  addSelected: PropTypes.func.isRequired,
  assignInnerRef: PropTypes.func.isRequired,
  closeModal: PropTypes.func.isRequired,
  deleteFromImages: PropTypes.func.isRequired,
  deleteFromSavedImages: PropTypes.func.isRequired,
  handleKeyDown: PropTypes.func.isRequired,
  isOpen: PropTypes.bool.isRequired,
  modalInput: PropTypes.object.isRequired,
  onDrop: PropTypes.func.isRequired,
  openModal: PropTypes.func.isRequired,
};

export default Artifacts;
