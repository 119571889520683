import React from 'react';
import PropTypes from 'prop-types';

import Button from 'components/Button';
import withKeyDown from 'components/withKeyDown';
import { Container, TextBlock, TaskName, TaskCategory, ButtonBlock } from './styled';

const ContainerWithKeyDown = withKeyDown(Container, 'Both');

const ExpandToggle = ({ task: { category, title }, isExpanded, toggle }) => (
  <ContainerWithKeyDown tabIndex={0} onClick={toggle}>
    <TextBlock>
      <TaskName>{category}</TaskName>
      <TaskCategory>{title}</TaskCategory>
    </TextBlock>
    <ButtonBlock>
      <Button
        icon={isExpanded ? 'collapse' : 'expand'}
        tabIndex={-1}
        iconAlign="right"
        color="transparent"
        textColor="#C8C8C8"
      >
        {isExpanded ? 'hide' : 'show more'}
      </Button>
    </ButtonBlock>
  </ContainerWithKeyDown>
);

ExpandToggle.propTypes = {
  isExpanded: PropTypes.bool.isRequired,
  task: PropTypes.object.isRequired,
  toggle: PropTypes.func.isRequired,
};

export default ExpandToggle;
