import http from '../../../http';

import { formatGroupCreate, formatGroupEdit } from './formatters';

import { setShouldGroupsListUpdate } from '../groups-list';

const API_REQUEST = 'rfc/groups/detail/API_REQUEST';
const EDIT_GROUP_SUCCESS = 'rfc/groups/detail/EDIT_GROUP_SUCCESS';
const EDIT_GROUP_FAILURE = 'rfc/groups/detail/EDIT_GROUP_FAILURE';
const CREATE_GROUP_SUCCESS = 'rfc/groups/detail/CREATE_GROUP_SUCCESS';
const CREATE_GROUP_FAILURE = 'rfc/groups/detail/CREATE_GROUP_FAILURE';
const DELETE_GROUP_SUCCESS = 'rfc/groups/detail/DELETE_GROUP_SUCCESS';
const DELETE_GROUP_FAILURE = 'rfc/groups/detail/DELETE_GROUP_FAILURE';
const CLEAR_SHOULD_GO_BACK = 'rfc/groups/detail/CLEAR_SHOULD_GO_BACK';

const initialState = {
  loading: false,
  error: '',
  shouldGoBack: false,
};

export default function reducer(state = initialState, action = {}) {
  switch (action.type) {
    case API_REQUEST:
      return { ...state, loading: true };
    case CLEAR_SHOULD_GO_BACK:
      return { ...state, shouldGoBack: false };
    case EDIT_GROUP_SUCCESS:
      return {
        ...state,
        error: '',
        loading: false,
        shouldGoBack: true,
      };
    case EDIT_GROUP_FAILURE:
      return { ...state, error: action.payload, loading: false };
    case CREATE_GROUP_SUCCESS:
      return {
        ...state,
        error: '',
        loading: false,
        shouldGoBack: true,
      };
    case CREATE_GROUP_FAILURE:
      return { ...state, error: action.payload, loading: false };
    case DELETE_GROUP_SUCCESS:
      return {
        ...state,
        error: '',
        loading: false,
        shouldGoBack: true,
      };
    case DELETE_GROUP_FAILURE:
      return { ...state, error: action.payload, loading: false };
    default:
      return state;
  }
}

export function clearShouldGoBack() {
  return (dispatch) => {
    dispatch({ type: CLEAR_SHOULD_GO_BACK });
  };
}

const successEditGroup = () => ({ type: EDIT_GROUP_SUCCESS });
const errorEditGroup = payload => ({ type: EDIT_GROUP_FAILURE, payload });

export function editGroup(collectionId, groupId, group, studentsIds) {
  const formattedGroup = formatGroupEdit(group, studentsIds);
  return (dispatch) => {
    dispatch({ type: API_REQUEST });
    http.put(`en/api/v1/user/collections/${collectionId}/groups/${groupId}`, formattedGroup)
      .then(() => dispatch(setShouldGroupsListUpdate()))
      .then(() => dispatch(successEditGroup()))
      .catch(error => dispatch(errorEditGroup(error)));
  };
}

const successCreateGroup = () => ({ type: CREATE_GROUP_SUCCESS });
const errorCreateGroup = payload => ({ type: CREATE_GROUP_FAILURE, payload });

export function createGroup(collectionId, group, studentsIds) {
  return (dispatch) => {
    dispatch({ type: API_REQUEST });
    http.post(`en/api/v1/user/collections/${collectionId}/groups.json`, group)
      .then((response) => {
        if (studentsIds.length) {
          const formattedGroup = formatGroupCreate(group, studentsIds);

          return (
            http.put(`en/api/v1/user/collections/${collectionId}/groups/${response.data.id}`, formattedGroup)
          );
        }

        return Promise.resolve();
      })
      .then(() => dispatch(setShouldGroupsListUpdate()))
      .then(() => dispatch(successCreateGroup()))
      .catch(error => dispatch(errorCreateGroup(error)));
  };
}

const successDeleteGroup = () => ({ type: DELETE_GROUP_SUCCESS });
const errorDeleteGroup = payload => ({ type: DELETE_GROUP_FAILURE, payload });

export function deleteGroup(collectionId, groupId) {
  return (dispatch) => {
    dispatch({ type: API_REQUEST });
    http.delete(`en/api/v1/user/collections/${collectionId}/groups/${groupId}`)
      .then(() => dispatch(setShouldGroupsListUpdate()))
      .then(() => dispatch(successDeleteGroup()))
      .catch(error => dispatch(errorDeleteGroup(error)));
  };
}
