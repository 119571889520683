import http from '../../../http';
import update from 'immutability-helper';

import { formatScale } from './formatters';
import levels from './data';

const FETCH_SCALE_REQUEST = 'rfc/scale/FETCH_SCALE_REQUEST';
const FETCH_SCALE_SUCCESS = 'rfc/scale/FETCH_SCALE_SUCCESS';
const FETCH_SCALE_FAILURE = 'rfc/scale/FETCH_SCALE_FAILURE';

const SELECT_LEVEL = 'rfc/scale/SELECT_LEVEL';
const TOGGLE_SWITCHER = 'rfc/scale/TOGGLE_SWITCHER';

const initialState = {
  scale: [],
  selectedLevels: levels(),
  showPriority: false,
  error: '',
  loading: false,
};

function changeSelected(state, action) {
  const newState = update(state, {
    selectedLevels: {
      [action.id]: {
        checked: { $set: !state.selectedLevels[action.id].checked },
      },
    },
  });

  return newState;
}

export default function reducer(state = initialState, action = {}) {
  switch (action.type) {
    case FETCH_SCALE_REQUEST:
      return { ...state, loading: true };
    case FETCH_SCALE_SUCCESS:
      return { ...state, ...{ scale: action.payload, loading: false } };
    case FETCH_SCALE_FAILURE:
      return { ...state, ...{ error: action.payload, loading: false } };
    case SELECT_LEVEL:
      return changeSelected(state, action);
    case TOGGLE_SWITCHER:
      return { ...state, showPriority: !state.showPriority };
    default:
      return state;
  }
}

const requestScale = () => ({ type: FETCH_SCALE_REQUEST });
const errorScale = payload => ({ type: FETCH_SCALE_FAILURE, payload });
const recieveScale = payload => ({ type: FETCH_SCALE_SUCCESS, payload });

export const selectLevel = id => ({ type: SELECT_LEVEL, id });
export const togglePriority = () => ({ type: TOGGLE_SWITCHER });

export function fetchScale(collectionId = 0) {
  return (dispatch) => {
    dispatch(requestScale());

    return http.get(`en/api/v1/user/collections/${collectionId}/scales.json`)
      .then((response) => {
        dispatch(recieveScale(formatScale(response)));
      })
      .catch((error) => { dispatch(errorScale(error)); });
  };
}
