import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import update from 'immutability-helper';
import { connect } from 'react-redux';
import { Redirect } from 'react-router';

import { fetchActivity, loadDefaultActivityState } from 'redux/modules/activity';
import { fetchAssessmentsOverview } from 'redux/modules/assessments-overview';
import Button from 'components/Button';
import Preloader from 'components/Preloader';
import Subheader from 'components/Subheader';
import Detail from './components/Detail';
import Footer from './components/Footer';
import Students from './components/Students';
import Tasks from './components/Tasks';
import { Column, Container, Row, StyledButton } from './styled';

class ActivityDetail extends PureComponent {
  static defaultProps = {
    copiedId: null,
    tasksStudents: null,
  }

  static propTypes = {
    activity: PropTypes.object.isRequired,
    copiedId: PropTypes.number,
    fetchActivity: PropTypes.func.isRequired,
    fetchAssessmentsOverview: PropTypes.func.isRequired,
    history: PropTypes.object.isRequired,
    loadDefaultActivityState: PropTypes.func.isRequired,
    loadingActivity: PropTypes.bool.isRequired,
    loadingAssessmentsOverview: PropTypes.bool.isRequired,
    match: PropTypes.object.isRequired,
    tasksStudents: PropTypes.object,
  }

  constructor(props) {
    super(props);

    this.onScoreClick = this.onScoreClick.bind(this);
    this.toogleDetail = this.toogleDetail.bind(this);
    this.goBack = this.goBack.bind(this);
  }

  state = {
    detailOpened: false,
  }

  componentDidMount() {
    const { activityId, collectionId } = this.props.match.params;

    this.props.fetchActivity(collectionId, activityId);
    this.props.fetchAssessmentsOverview(collectionId);
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.copiedId) {
      const { collectionId } = this.props.match.params;

      this.props.fetchActivity(collectionId, nextProps.copiedId);
    }
  }

  componentWillUnmount() {
    this.props.loadDefaultActivityState();
  }

  onScoreClick(id) {
    const { activityId, collectionId } = this.props.match.params;

    this.props.history.push(`/collections/${collectionId}/rating/task/${id}?mode=activities&activity=${activityId}`);
  }

  toogleDetail() {
    this.setState(update(this.state, {
      detailOpened: { $apply: detailOpened => !detailOpened },
    }));
  }

  goBack() {
    this.props.history.push(`/collections/${this.props.match.params.collectionId}/activities/list/open`);
  }

  render() {
    const {
      activity,
      copiedId,
      loadingActivity,
      loadingAssessmentsOverview,
    } = this.props;
    const { detailOpened } = this.state;

    if (loadingAssessmentsOverview) {
      return (
        <Container>
          <Preloader loading={loadingAssessmentsOverview} />
        </Container>
      );
    }

    if (copiedId) {
      return <Redirect to={`../${copiedId}/detail`} />;
    }

    return (
      <Container>
        <Preloader loading={loadingActivity} />
        <Subheader module="activities">
          <Button
            aria-label="Back to activities list"
            icon="back"
            size="lg"
            color="transparent"
            onClick={this.goBack}
          >
            {activity.label} - {moment(activity.end_date).format('M/D/YYYY')}
          </Button>
          <StyledButton
            aria-label={detailOpened ? 'collapse detail' : 'expand detail'}
            icon={detailOpened ? 'collapseWhite' : 'expandWhite'}
            color="transparent"
            onClick={this.toogleDetail}
          />
        </Subheader>
        <Detail activity={activity} detailOpened={detailOpened} />
        <Row>
          <Column>
            <Students />
          </Column>
          <Column>
            <Tasks studentsAmount={activity.students_amount} onScoreClick={this.onScoreClick} />
          </Column>
        </Row>
        <Footer />
      </Container>
    );
  }
}

const mapStateToProps = ({ activity, assessmentsOverview }) => ({
  activity: activity.activity,
  copiedId: activity.copiedId,
  loadingActivity: activity.loading,
  loadingAssessmentsOverview: assessmentsOverview.loading,
  tasksStudents: assessmentsOverview.tasksStudents,
});

const mapDispatchToProps = {
  fetchActivity,
  fetchAssessmentsOverview,
  loadDefaultActivityState,
};

export default connect(mapStateToProps, mapDispatchToProps)(ActivityDetail);
