import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  width: 100%;
  background: ${({ theme }) => theme.lightGrey};
`;

export const TasksTitle = styled.div`
  box-sizing: border-box;
  display: block;
  flex-direction: column;
  justify-content: space-around;
  flex: 1;
  margin: 0;
  margin-right: 8px;
  background: ${({ theme }) => theme.primaryDark};
  height: 70px;
  min-width: 50%;
  flex-shrink: 0;
  font-size: 13px;
`;

export const TitleWrapper = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  justify-content: center;
  margin: 0;
  padding-left: 20px;
  height: 70px;
  font-size: 13px;
`;

export const Title = styled.h3`
  margin-top: 0;
  margin-bottom: 2px;
`;

export const SubTitle = styled.h4`
  margin: 0;
  font-weight: normal;
`;

export const RateItem = styled.div`
  width: calc(4% - 1px);
  box-sizing: border-box;
  height: 70px;
  background-color: ${({ color, theme }) => theme.grades[color] || 'black'};
  text-align: center;
  margin: ${({ color }) => (color === 'R' ? '0 8px 0 0' : '0')};
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const Round = styled.h3`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 30px;
  height: 30px;
  line-height: 100%;
  border-radius: 50%;
  font-size: 14px;
  font-weight: 500;
  background-color: rgba(0, 0, 0, 0.6);
  color: theme.primaryWhite;
`;
