import React, { Component } from 'react';
import PropTypes from 'prop-types';
import findIndex from 'lodash/findIndex';

import StyledTitle from './styled';

export default class Separator extends Component {
  static propTypes = {
    chooseStudents: PropTypes.bool.isRequired,
    text: PropTypes.string.isRequired,
  }

  constructor(props) {
    super(props);

    this.handleKeyDown = this.handleKeyDown.bind(this);
  }

  shouldComponentUpdate(nextProps) {
    return (nextProps.text !== this.props.text);
  }

  handleKeyDown(event) {
    const keyCodes = {
      tab: 9,
      up: 38,
      down: 40,
    };

    const item = event.target;
    const prevParentItem = item.previousElementSibling;
    const nextParentItem = item.nextElementSibling;
    const prevTaskItem = prevParentItem ? prevParentItem.firstElementChild : '';
    const nextTaskItem = nextParentItem ? nextParentItem.firstElementChild : '';

    if (event.keyCode === keyCodes.down && nextTaskItem) {
      event.preventDefault();
      nextTaskItem.focus();
    }

    if (event.keyCode === keyCodes.up && prevTaskItem) {
      event.preventDefault();
      prevTaskItem.focus();
    }

    if (event.keyCode === keyCodes.tab) {
      event.preventDefault();
      const separators = document.getElementsByName('separator-activity-task');
      const indexItem = findIndex(separators, separator => separator.innerText === item.innerText);
      if (separators[indexItem + 1]) {
        separators[indexItem + 1].focus();
      } else if (this.props.chooseStudents) {
        document.getElementById('activity-students-groups').focus();
      } else {
        document.getElementById('activity-description').focus();
      }
    }

    if (event.shiftKey && event.keyCode === keyCodes.tab) {
      event.preventDefault();
      const separators = document.getElementsByName('separator-activity-task');
      const indexItem = findIndex(separators, separator => separator.innerText === item.innerText);
      if (separators[indexItem - 1]) {
        separators[indexItem - 1].focus();
      } else {
        document.getElementById('task-list').focus();
      }
    }
  }

  render() {
    const { text } = this.props;

    return (
      <StyledTitle
        tabIndex={0}
        aria-label={text}
        text={text}
        name="separator-activity-task"
        onKeyDown={this.handleKeyDown}
      />
    );
  }
}
