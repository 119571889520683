import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import { getActivityTasks } from 'redux/modules/activity/selectors';
import Title from 'components/Title';
import Item from './components/Item';
import Separator from './components/Separator';
import { Container, Content, StyledButton, StyledLink, StyledSeparator } from './styled';

class Tasks extends PureComponent {
  static propTypes = {
    onScoreClick: PropTypes.func.isRequired,
    studentsAmount: PropTypes.number.isRequired,
    tasks: PropTypes.arrayOf(PropTypes.object).isRequired,
  }

  render() {
    const { tasks, studentsAmount, onScoreClick } = this.props;

    return (
      <Container
        id="tasks-list"
        tabIndex={0}
        aria-label="Tasks list"
      >
        <Title text="Tasks">
          <StyledLink aria-label="Edit tasks list" to="./edit">
            <StyledButton
              tabIndex={-1}
              aria-label="Edit tasks list"
              size="sm"
              icon="whitePen"
              color="#70a823"
            >
              Edit
            </StyledButton>
          </StyledLink>
        </Title>
        <Content>
          {tasks.length ?
            tasks.map((task, index) => (task.separator ?
              <Separator key={index} text={task.separator} /> :
              <Item key={index} task={task} studentsAmount={studentsAmount} onScoreClick={onScoreClick} />
            )) : <StyledSeparator text="No tasks for this activity" />
          }
        </Content>
      </Container>
    );
  }
}

const mapStateToProps = ({ activity, tasks }) => ({
  tasks: getActivityTasks(tasks, activity),
});

export default connect(mapStateToProps)(Tasks);
