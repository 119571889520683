import styled from 'styled-components';

const Container = styled.div`
  display: flex;
  background-color: ${({ theme }) => theme.darkGrey};
  flex: none;
  padding: 0 10px;
`;

export default Container;
