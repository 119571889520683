import styled from 'styled-components';

const color = ({ module, theme }) => {
  switch (module) {
    case 'assessments': return theme.primaryGreen;
    case 'activities': return theme.primaryOrange;
    case 'students': return theme.primaryLightBlue;
    case 'groups': return theme.primaryPurple;
    default: return theme.primaryBlue;
  }
};

export const Container = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  background-color: ${color};
  color: ${({ theme }) => (theme.primaryWhite)};
  text-transform: uppercase;
  height: 70px;
  box-sizing: border-box;
  padding: 15px;
  justify-content: space-between;
  flex-shrink: 0;
`;

export const Styled = styled.div`

`;
