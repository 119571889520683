import React from 'react';
import PropTypes from 'prop-types';

import { Arrow, Container, EndDate, Mark, Name, Text, StyledLink } from './styled';

const ListItem = ({ assessment: { end_date: date, id, name } }) => (
  <StyledLink aria-label={name} to={`./${id}/assessments/overview`}>
    <Container role="treeitem" aria-label={name}>
      <Mark role="presentation" aria-label="for view" />
      <Text>
        <Name>
          {name}
        </Name>
        <EndDate>
          End date: {date}
        </EndDate>
      </Text>
      <Arrow role="presentation" />
    </Container>
  </StyledLink>
);

ListItem.propTypes = {
  assessment: PropTypes.object.isRequired,
};

export default ListItem;
