import React from 'react';
import PropTypes from 'prop-types';

import Modal from 'components/Modal';
import Scale from 'components/Scale';

import { Container } from './styled';

const ScaleModal = ({ scale, ...rest }) => (
  <Modal {...rest} headerTitle={null}>
    <Container>
      <Scale grades={scale} />
    </Container>
  </Modal>
);

ScaleModal.propTypes = {
  scale: PropTypes.array.isRequired,
};


export default ScaleModal;
