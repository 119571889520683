import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  flex-shrink: 0;
  flex-grow: 0;
  height: 200px;
`;

export const Task = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  padding: 20px;
  width: 50%;
  box-sizing: border-box;
  background-color: #fff;
  color: #666;
  flex-shrink: 0;
`;

export const TaskTitle = styled.div`
  margin-bottom: 20px;
  text-transform: uppercase;
  font-size: 13px;
  font-weight: 600;
`;

export const TaskDescription = styled.div`
  position: relative;
  height: 80px;
  display: block;
  line-height: 1.3;
  overflow: hidden;
`;

export const StyledLink = styled.div`
  display: flex;
  justify-self: flex-end;
  position: absolute;
  bottom: 20px;
`;

export const ScaleContainer = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  width: 50%;
  height: 100%;
  box-sizing: border-box;
  padding: 20px;
  background-color: #f1f1f1;
  overflow-y: auto;
`;
