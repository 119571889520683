import update from 'immutability-helper';

import http from '../../http';

const API_REQUEST = 'rfc/resources/API_REQUEST';
const FETCH_RESOURCES_SUCCESS = 'rfc/resources/FETCH_RESOURCES_SUCCESS';
const FETCH_RESOURCES_FAILURE = 'rfc/resources/FETCH_RESOURCES_FAILURE';
const INITIAL_STATE_REQUEST = 'rfc/resources/INITIAL_STATE_REQUEST';

const initialState = {
  resources: [],
  error: '',
  loading: true,
};

export default function reducer(state = initialState, action = {}) {
  switch (action.type) {
    case API_REQUEST:
      return update(state, { $merge: { loading: true } });
    case FETCH_RESOURCES_SUCCESS:
      return update(state, {
        $set: {
          resources: action.payload,
          error: '',
          loading: false,
        },
      });
    case FETCH_RESOURCES_FAILURE:
      return update(state, { $merge: { error: action.payload, loading: false } });
    case INITIAL_STATE_REQUEST:
      return initialState;
    default:
      return state;
  }
}

const errorResources = payload => ({ type: FETCH_RESOURCES_FAILURE, payload });
const receiveResources = payload => ({ type: FETCH_RESOURCES_SUCCESS, payload });

export const fetchResources = (collectionId = 0, taskId = 0) => (dispatch) => {
  dispatch({ type: API_REQUEST });
  http.get(`en/api/v1/user/collections/${collectionId}/items/${taskId}/resources.json`)
    .then(response => dispatch(receiveResources(response.data)))
    .catch(response => dispatch(errorResources(response)));
};

export const loadInitialState = () => ({ type: INITIAL_STATE_REQUEST });
