import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import { editActivity, fetchActivity, loadDefaultActivityState } from 'redux/modules/activity';
import CreateEditActivity from 'modules/activities/components/CreateEditActivity';
import Preloader from 'components/Preloader';

class EditActivity extends PureComponent {
  static propTypes = {
    editActivity: PropTypes.func.isRequired,
    fetchActivity: PropTypes.func.isRequired,
    loadDefaultActivityState: PropTypes.func.isRequired,
    loading: PropTypes.bool.isRequired,
    match: PropTypes.object.isRequired,
  }

  constructor(props) {
    super(props);

    this.editActivity = this.editActivity.bind(this);
  }

  componentDidMount() {
    const { activityId, collectionId } = this.props.match.params;
    this.props.fetchActivity(collectionId, activityId);
  }

  componentWillUnmount() {
    this.props.loadDefaultActivityState();
  }

  editActivity() {
    const { activityId, collectionId } = this.props.match.params;
    this.props.editActivity(collectionId, activityId);
  }

  render() {
    const { loading } = this.props;

    if (loading) {
      return (
        <Preloader loading={loading} />
      );
    }

    return <CreateEditActivity onRequestSave={this.editActivity} />;
  }
}

const mapStateToProps = ({ activity }) => ({
  loading: activity.loading,
});

const mapDispatchToProps = {
  fetchActivity,
  loadDefaultActivityState,
  editActivity,
};

export default connect(mapStateToProps, mapDispatchToProps)(EditActivity);
