import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import groupListSelector, { getGroupsNames } from 'redux/modules/groups-list/selectors';
import { selectGroup } from 'redux/modules/app';
import { SelectStudentsGroup, SelectContainer } from './styled';
import 'react-select/dist/react-select.css';

class SelectGroup extends PureComponent {
  static propTypes = {
    groups: PropTypes.arrayOf(PropTypes.object).isRequired,
    groupsByName: PropTypes.arrayOf(PropTypes.object).isRequired,
    selectedGroupId: PropTypes.number.isRequired,
    selectGroup: PropTypes.func.isRequired,
  }

  constructor(props) {
    super(props);

    this.onSelectGroup = this.onSelectGroup.bind(this);
  }

  onSelectGroup(option) {
    const groupStudentsIds = option.value !== -1 ? this.props.groups[option.value].studentsIds : 0;
    this.props.selectGroup(option.value, groupStudentsIds);
  }

  render() {
    const { groupsByName, selectedGroupId } = this.props;

    return (
      <SelectContainer
        onClick={e => e.stopPropagation()}
        aria-label="Choose students group"
      >
        <SelectStudentsGroup
          aria-label="Choose students group"
          backspaceRemoves={false}
          clearable={false}
          deleteRemoves={false}
          value={selectedGroupId}
          options={groupsByName}
          onChange={this.onSelectGroup}
        />
      </SelectContainer>
    );
  }
}

const mapStateToProps = ({ app, groups }) => ({
  groups: groupListSelector(groups),
  groupsByName: getGroupsNames(groups),
  selectedGroupId: app.selectedGroupId,
});

export default connect(mapStateToProps, { selectGroup })(SelectGroup);
