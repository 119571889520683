import React from 'react';
import PropTypes from 'prop-types';

import Item from './components/Item';

import { Container, Header } from './styled';

const TasksList = ({ data }) => (
  <Container>
    {data.length ?
      data.map((section, sectionIndex) => (
        <div key={sectionIndex}>
          <Header>{section.sectionTitle}</Header>
          {
            section.sectionData.map((item, itemIndex) => (
              <Item
                key={itemIndex}
                taskname={item.taskname}
                date={item.date}
              />
            ))
          }
        </div>
      )) :
      <div>
        <Header>Task Due Dates</Header>
        <Item taskname="No task due dates available" />
      </div>
    }
  </Container>
);

TasksList.propTypes = {
  data: PropTypes.arrayOf(PropTypes.object),
};

TasksList.defaultProps = {
  data: {
    group: '',
    data: [],
  },
};

export default TasksList;
