import React from 'react';
import PropTypes from 'prop-types';

import withKeyDown from 'components/withKeyDown';
import { Container, Item, Title, Value, Round, ColoredRound } from './styled';

const ColoredRoundWithKeyDown = withKeyDown(ColoredRound, 'Space');

const Changer = ({ score, selected, onScoreChange }) => (
  <Container>
    {score.map((item, index) => (
      <Item key={index}>
        <Title grade={item.label}>
          <Round>{item.label}</Round>
        </Title>
        <Value>
          <ColoredRoundWithKeyDown
            aria-label={`select ${item.label} level`}
            onClick={() => onScoreChange(item.label)}
            tabIndex={0}
            value={item.value}
            grade={item.label}
            isSelected={selected === item.label}
          >
            {selected === item.label ? item.label : null}
          </ColoredRoundWithKeyDown>
        </Value>
      </Item>
    ))}
  </Container>
);

Changer.propTypes = {
  score: PropTypes.arrayOf(PropTypes.object).isRequired,
  selected: PropTypes.string.isRequired,
  onScoreChange: PropTypes.func.isRequired,
};

export default Changer;
