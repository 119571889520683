import styled from 'styled-components';
import Title from 'components/Title';

export const Container = styled.ul`
  margin: 0;
  padding: 0;
  height: 100%;
  overflow-y: auto;
`;

export const StyledTitle = styled(Title)`
  padding: 10px 15px;
  text-transform: none;
  background-color: ${({ theme }) => theme.lightGrey};
  border-top: none;
`;
