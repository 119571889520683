import { normalize, schema } from 'normalizr';

const student = new schema.Entity('students_enrollments');
const set = new schema.Entity('data_collections_sets_items');
const artifact = new schema.Entity('data_collections_activities_artifacts');
const activity = {
  students_enrollments: [student],
  data_collections_sets_items: [set],
  data_collections_activities_artifacts: [artifact],
};
const activities = new schema.Entity('activitiesListing', activity);

const normalizeActivities = list => normalize(list, [activities]);

export default normalizeActivities;
