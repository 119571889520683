import styled from 'styled-components';

import CheckedIcon from './images/checked.png';

export const Container = styled.label`
  position: relative;
  box-sizing: border-box;
  width: 30px;
  height: 30px;
  border: 1px solid #bbb;
  border-radius: 4px;
  cursor: pointer;
  overflow: hidden;
  flex-shrink: 0;
  background: ${props => (props.isChecked ? `url(${CheckedIcon}) no-repeat center center` : 'none')};
  background-size: 80%;

  &:before {
    content: '';
    position: absolute;
    width: 32px;
    height: 32px;
    top: -1px;
    left: -1px;
  }

  &:hover {
    border: 1px solid #a8a8a8;
    box-shadow: inset 0 0 15px #ddd;
  }
`;

export const Input = styled.input`
  cursor: pointer;
  width: 100%;
  height: 100%;
  opacity: 0;
`;
