import styled from 'styled-components';
import Button from 'components/Button';

export const Container = styled.div`
  display: flex;
  margin-top: 10px;
  padding: 0 30px;
  flex-shrink: 0;
`;

export const TitleColumn = styled.div`
  width: 150px;
  font-weight: 600;
  flex-shrink: 0;
`;

export const StyledButton = styled(Button)`
  background: #F2F2F2;
  text-transform: none;
  font-size: 12px;
  font-weight: 400;
  color: #000;
  border: 1px solid #ccc;
  width: 120px;
  padding: 8px 0;
  margin-top: 10px;
  height: auto;
  border-radius: 5px;
`;

export const TextArea = styled.textarea`
  height: 80px;
  flex: 1;
  padding: 10px;
  resize: none;
  border: 1px solid #ccc;
  color: ${({ theme }) => theme.darkGrey};
`;
