/* eslint-disable react/prefer-stateless-function */
import React, { Component } from 'react';
import { Container, Text, Wrapper } from './styled';

class ScoreSheetsHistory extends Component {
  render() {
    return (
      <Container role="main" aria-label="Score Sheets History">
        <Wrapper>
          <Text>
            <h4>History page</h4>
          </Text>
        </Wrapper>
      </Container>
    );
  }
}

export default ScoreSheetsHistory;
