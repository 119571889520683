export function formatScale(scale) {
  const formatted = scale.data.map((item, index) => {
    const scaleItem = {
      id: index,
      level_id: +item.id,
      parent_id: +item.assessment_items_scales_directory_parent_id,
      label: item.symbol.toUpperCase(),
      description: item.description,
      checked: true,
    };

    return scaleItem;
  });

  return formatted;
}

export function format() {

}
