import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';

import { StyledButton, Text } from './styled';

export default class DescriptionView extends PureComponent {
  static propTypes = {
    description: PropTypes.string.isRequired,
    toggleAddEditModal: PropTypes.func.isRequired,
  }

  render() {
    const { description, toggleAddEditModal } = this.props;

    return (
      <div>
        <label htmlFor="description">DESCRIPTION</label>
        <Text
          tabIndex={0}
          aria-label={`Description: ${description || 'No description'}`}
          id="description"
        >
          {description}
        </Text>
        <StyledButton
          aria-label="Open description input field"
          type="button"
          size="sm"
          icon="plusBlue"
          color="#ffffff"
          textColor="#036a9b"
          onClick={toggleAddEditModal}
        >
          Add/Edit Description
        </StyledButton>
      </div>
    );
  }
}
