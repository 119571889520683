import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import { uploadArtifact } from 'redux/modules/artifacts';
import NewArtifactModal from 'components/Modals/NewArtifactModal';

class OpenedNewArtifactModal extends PureComponent {
  static propTypes = {
    history: PropTypes.object.isRequired,
    match: PropTypes.object.isRequired,
    uploadArtifact: PropTypes.func.isRequired,
  }

  constructor(props) {
    super(props);

    this.onRequestClose = this.onRequestClose.bind(this);
    this.uploadNewArtifact = this.uploadNewArtifact.bind(this);
  }

  state = {
    isOpen: true,
  }

  onRequestClose() {
    this.props.history.goBack();

    this.setState({ isOpen: false });
  }

  uploadNewArtifact(file, comment) {
    this.props.uploadArtifact(this.props.match.params.collectionId, file, comment);
  }

  render() {
    return (
      <NewArtifactModal
        isOpen={this.state.isOpen}
        onRequestClose={this.onRequestClose}
        uploadArtifact={this.uploadNewArtifact}
      />
    );
  }
}

export default connect(null, { uploadArtifact })(OpenedNewArtifactModal);
