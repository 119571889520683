import { combineReducers } from 'redux';
import { reducer as formReducer } from 'redux-form';

import activities from './modules/activities';
import activity from './modules/activity';
import artifacts from './modules/artifacts';
import assessments from './modules/assessments-list';
import assessmentsOverview from './modules/assessments-overview';
import app from './modules/app';
import auth from './modules/auth';
import grades from './modules/grades';
import group from './modules/group';
import groups from './modules/groups-list';
import history from './modules/history';
import notes from './modules/notes';
import notifications from './modules/notifications';
import resources from './modules/resources';
import scale from './modules/scale';
import students from './modules/students';
import tasks from './modules/tasks';
import scoreSheets from './modules/scoreSheets';

const rootReducer = combineReducers({
  activities,
  activity,
  artifacts,
  assessments,
  assessmentsOverview,
  app,
  auth,
  grades,
  group,
  groups,
  history,
  notes,
  notifications,
  resources,
  scale,
  students,
  tasks,
  scoreSheets,
  form: formReducer,
});

export default rootReducer;
