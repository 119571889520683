import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import { Field, reduxForm, SubmissionError } from 'redux-form';
import { connect } from 'react-redux';

import Title from 'components/Title';
import DescriptionView from './components/DescriptionView';
import EndDate from './components/EndDate';
import Name from './components/Name';
import { Container, Content } from './styled';
import 'react-datepicker/dist/react-datepicker.css';

const onSubmit = (values, dispatch, props) => {
  if (!values.name) {
    throw new SubmissionError({ name: 'A name is required' });
  }
  if (!moment(values.date, 'MM/DD/YYYY', true).isValid()) {
    throw new SubmissionError({ date: 'A valid date is required' });
  }
  props.toggleChooseStudents();
};

const format = (value) => {
  const date = moment(value, 'MM/DD/YYYY', true);
  return date.isValid() ? date : null;
};

const normalize = (value) => {
  const newDate = moment(value, 'MM/DD/YYYY', true);
  return newDate.isValid() ? newDate : null;
};

class Description extends PureComponent {
  static propTypes = {
    description: PropTypes.string.isRequired,
    handleSubmit: PropTypes.func.isRequired,
    onInputFieldChange: PropTypes.func.isRequired,
    toggleAddEditModal: PropTypes.func.isRequired,
    toggleChooseStudents: PropTypes.func.isRequired, // eslint-disable-line react/no-unused-prop-types
  }

  constructor(props) {
    super(props);

    this.onInputDateChange = this.onInputDateChange.bind(this);
    this.onInputNameChange = this.onInputNameChange.bind(this);
  }

  onInputDateChange(value) {
    this.props.onInputFieldChange('end_date', value);
  }

  onInputNameChange(event) {
    this.props.onInputFieldChange('label', event.target.value);
  }

  render() {
    const { description, handleSubmit, toggleAddEditModal } = this.props;

    return (
      <Container>
        <Title text="Description" />
        <Content
          id="activity-description"
          tabIndex={0}
          aria-label="Activity description"
          onSubmit={handleSubmit}
        >
          <Field
            name="name"
            component={Name}
            onChange={this.onInputNameChange}
          />
          <DescriptionView description={description} toggleAddEditModal={toggleAddEditModal} />
          <Field
            name="date"
            component={EndDate}
            format={format}
            normalize={normalize}
            onChange={this.onInputDateChange}
          />
        </Content>
      </Container>
    );
  }
}

const mapStateToProps = ({ activity }) => ({
  initialValues: {
    name: activity.activity.label,
    date: activity.activity.end_date,
  },
});

export default connect(mapStateToProps)(reduxForm({ form: 'activityDescription', onSubmit })(Description));
