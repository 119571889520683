import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';

import CustomSelect from './components/CustomSelect';
import { Container, StyledTimePicker } from './styled';
import 'rc-time-picker/assets/index.css';
import './index.css'; // overwrite .rc-time-picker-panel * class for time picker pop up font

export default class ObservationDate extends PureComponent {
  static propTypes = {
    date: PropTypes.instanceOf(moment).isRequired,
    onChange: PropTypes.func.isRequired,
  }

  static getDates(year = moment().year(), month = moment().month()) {
    const dates = [];
    const daysAmount = moment().year(year).month(month).daysInMonth();

    for (let i = 1; i <= daysAmount; i += 1) {
      dates.push({ value: i, label: i });
    }

    return dates;
  }

  static getMonths() {
    return moment.months().map((month, index) => ({ value: index, label: month }));
  }

  static getYears() {
    const years = [];
    const currentYear = moment().year();

    for (let i = -5; i <= 5; i += 1) {
      years.push({ value: currentYear + i, label: currentYear + i });
    }

    return years;
  }

  constructor(props) {
    super(props);

    this.onChange = this.onChange.bind(this);
    this.onTimeChange = this.onTimeChange.bind(this);
  }

  state = {
    day: this.props.date.date(),
    month: this.props.date.month(),
    time: moment(this.props.date),
    year: this.props.date.year(),
  }

  onChange(field, value) {
    const {
      day,
      month,
      time,
      year,
    } = this.state;

    const date = moment({
      year,
      month,
      day,
      hour: time.hour(),
      minute: time.minute(),
      [field]: value,
    });

    if (!date.isValid()) {
      this.setState({ [field]: value, day: 1 }, () => {
        const validDay = this.state.day;

        this.props.onChange(moment({
          year,
          month,
          validDay,
          hour: time.hour(),
          minute: time.minute(),
          [field]: value,
        }));
      });
    } else {
      this.setState({ [field]: value });
      this.props.onChange(date);
    }
  }

  onTimeChange(value) {
    const { day, month, year } = this.state;

    this.setState({ time: value });
    this.props.onChange(moment(value).year(year).month(month).date(day));
  }

  render() {
    const {
      day,
      month,
      time,
      year,
    } = this.state;

    return (
      <Container>
        <CustomSelect
          name="month"
          value={month}
          options={ObservationDate.getMonths()}
          onChange={this.onChange}
        />
        <CustomSelect
          name="day"
          value={day}
          options={ObservationDate.getDates(year, month)}
          onChange={this.onChange}
        />
        <CustomSelect
          name="year"
          value={year}
          options={ObservationDate.getYears()}
          onChange={this.onChange}
        />
        <StyledTimePicker value={time} onChange={this.onTimeChange} />
      </Container>
    );
  }
}
