import styled from 'styled-components';

export const HeaderContainer = styled.div`
  display: flex;
  justify-content: space-between;
`;

export const Container = styled.div`
  overflow-y: hidden;
  height: 100%;
`;

export const ScrollDiv = styled.div`
  width: 17px;
  flex-shrink: 0;
  background: ${({ theme }) => theme.primaryDark};
`;
