import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import queryString from 'query-string';
import { connect } from 'react-redux';

import { createActivity, loadActivityToCreate, loadDefaultActivityState } from 'redux/modules/activity';
import CreateEditActivity from 'modules/activities/components/CreateEditActivity';

class CreateActivity extends PureComponent {
  static propTypes = {
    createActivity: PropTypes.func.isRequired,
    loadActivityToCreate: PropTypes.func.isRequired,
    loadDefaultActivityState: PropTypes.func.isRequired,
    location: PropTypes.object.isRequired,
    match: PropTypes.object.isRequired,
  }

  constructor(props) {
    super(props);

    this.createActivity = this.createActivity.bind(this);
  }

  componentDidMount() {
    const search = queryString.parse(this.props.location.search, { arrayFormat: 'bracket' });
    const selectedStudents = search.selectedStudents ? search.selectedStudents.map(id => +id) : [];
    this.props.loadActivityToCreate(selectedStudents);
  }

  componentWillUnmount() {
    this.props.loadDefaultActivityState();
  }

  createActivity() {
    this.props.createActivity(this.props.match.params.collectionId);
  }

  render() {
    return <CreateEditActivity onRequestSave={this.createActivity} />;
  }
}

const mapDispatchToProps = {
  createActivity,
  loadActivityToCreate,
  loadDefaultActivityState,
};

export default connect(null, mapDispatchToProps)(CreateActivity);
