import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  border-right: 1px solid ${({ theme }) => theme.primaryGrey};
  max-width: 50%;
`;

export const List = styled.div`
  overflow-y: auto;
`;
