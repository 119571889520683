import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 0;
  padding: 0 20px;
  height: 70px;
  flex-shrink: 0;
  box-sizing: border-box;
  background-color: ${({ theme }) => theme.primaryGreen};
`;

export const Title = styled.h3`
  margin: 0;
`;
