import styled from 'styled-components';
import { Link } from 'react-router-dom';
import Forward from './images/blueForward.png';

export const Arrow = styled.div`
  background: url(${Forward}) no-repeat 50%;
  background-size: 80%;
  height: 30px;
  width: 30px;
  align-self: center;
  margin-right: 20px;
`;

export const Container = styled.li`
  background-color: ${({ theme }) => theme.primaryWhite};
  cursor: pointer;
  list-style-type: none;
  display: flex;
  height: 70px;

  &:focus {
    position: relative;
    z-index: 99;
  }
`;

export const EndDate = styled.div`
  color: ${({ theme }) => theme.darkGrey};
`;

export const Mark = styled.div`
  background-color: #068ccc;
  margin: 2px;
  width: 6px
`;

export const Name = styled.div`
  color: ${({ theme }) => theme.primaryBlue};
`;

export const StyledLink = styled(Link)`
  text-decoration: none;
  margin-top: 10px;
  margin-bottom: 10px;

  &:first-of-type {
    margin-top: 0;
  }
  &:last-of-type {
    margin-bottom: 0;
  }
`;

export const Text = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  flex: 1;
  margin: 10px;
  min-width: 0;
  word-wrap: break-word;
`;
