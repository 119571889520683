import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';

import Container from './styled';

import Grid from './components/Grid';
import AssessmentsScoreDetail from './components/AssessmentsScoreDetail';

export default class TableContent extends PureComponent {
  static propTypes = {
    tasks: PropTypes.array.isRequired,
    registerTableRef: PropTypes.func.isRequired,
    registerSeparatorRef: PropTypes.func.isRequired,
  }

  constructor(props) {
    super(props);

    this.onRowClicked = this.onRowClicked.bind(this);
    this.onStudentModalClosed = this.onStudentModalClosed.bind(this);
  }

  state = {
    studentsModalOpened: false,
    rate: {},
    header: '',
  }

  onStudentModalClosed() {
    this.setState({ studentsModalOpened: false });
  }

  onRowClicked(rate, header) {
    if (!rate.rate) {
      return;
    }
    this.setState({ studentsModalOpened: true, rate, header });
  }

  render() {
    const { registerTableRef, registerSeparatorRef, tasks } = this.props;
    return (
      <Container innerRef={ref => registerTableRef(ref)}>
        <Grid
          tasks={tasks}
          registerSeparatorRef={registerSeparatorRef}
          onRowClicked={this.onRowClicked}
        />
        <AssessmentsScoreDetail
          isOpen={this.state.studentsModalOpened}
          onRequestClose={this.onStudentModalClosed}
          header={this.state.header}
          rate={this.state.rate}
        />
      </Container>
    );
  }
}
