import styled from 'styled-components';
import { Link } from 'react-router-dom';
import Button from 'components/Button';
import Title from 'components/Title';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
`;

export const Content = styled.div`
  overflow-y: auto;
`;

export const StyledButton = styled(Button)`
  text-transform: uppercase;
`;

export const StyledLink = styled(Link)`
  text-decoration: none;
  margin-left: auto;
`;

export const StyledTitle = styled(Title)`
  padding: 10px 15px;
  text-transform: none;
  background-color: ${({ theme }) => theme.lightGrey};
  border-top: none;
`;
