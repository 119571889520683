import React from 'react';
import PropTypes from 'prop-types';
import { Route, Switch } from 'react-router-dom';

import Subheader from 'components/Subheader';
import Button from 'components/Button';
import PersonalInfo from './components/PersonalInfo';
import TasksList from './components/TasksList';
import GroupsList from './pages/Groups';
import History from './pages/History';
import Score from './pages/Score';
import Resources from './pages/Resources';
import { Container, Content, StudentColumn, GroupsColumn } from './styled';

const baseUrl = '/collections/:collectionId/students/:studentId';

const StudentsProfile = ({ history, match }) => {
  const goBack = () => {
    history.push(`/collections/${match.params.collectionId}/students/list`);
  };

  return (
    <Container>
      <Subheader module="students">
        <Button
          icon="back"
          size="lg"
          color="transparent"
          aria-label="back to students list"
          onClick={goBack}
        >
          Students profile
        </Button>
      </Subheader>
      <Content>
        <StudentColumn>
          <PersonalInfo />
          <TasksList data={[]} />
        </StudentColumn>
        <GroupsColumn>
          <Switch>
            <Route path={`${baseUrl}/tasks`} component={GroupsList} />
            <Route path={`${baseUrl}/group/:groupId/task/:taskId/score`} component={Score} />
            <Route path={`${baseUrl}/task/:taskId/history`} component={History} />
            <Route path={`${baseUrl}/task/:taskId/resources`} component={Resources} />
          </Switch>
        </GroupsColumn>
      </Content>
    </Container>
  );
};

StudentsProfile.propTypes = {
  history: PropTypes.object.isRequired,
  match: PropTypes.object.isRequired,
};

export default StudentsProfile;
