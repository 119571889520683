import React from 'react';
import PropTypes from 'prop-types';

import ErrorMessage from 'components/ErrorMessage';
import { Container, StyledDatePicker } from './styled';
import 'react-datepicker/dist/react-datepicker.css';

const EndDate = ({ input, meta: { error, touched } }) => (
  <Container>
    <label htmlFor="date">ACTIVITY DATE</label>
    <StyledDatePicker
      {...input}
      aria-label="Use keyboard to choose a date"
      tabIndex={0}
      id="date"
      placeholderText="Click to select a date"
      selected={input.value}
    />
    {error && touched && <ErrorMessage text={error} />}
  </Container>
);

EndDate.propTypes = {
  input: PropTypes.object.isRequired,
  meta: PropTypes.object.isRequired,
};

export default EndDate;
