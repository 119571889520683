import React from 'react';
import PropTypes from 'prop-types';
import find from 'lodash/find';

import groupColors from 'configs/groupColors';
import { Color, Name, Container } from './styled';

const Group = ({ color, name }) => {
  const colorObj = find(groupColors, ['key', color]);
  const colorCode = colorObj ? colorObj.code : 'transparent';

  return (
    <Container aria-label={name}>
      <Color color={colorCode} />
      <Name>{name}</Name>
    </Container>
  );
};

Group.propTypes = {
  color: PropTypes.string,
  name: PropTypes.string,
};

Group.defaultProps = {
  color: '',
  name: '',
};

export default Group;
