import React from 'react';
import PropTypes from 'prop-types';

import StyledSelect from './styled';
import 'react-select/dist/react-select.css';

const CustomSelect = ({
  name,
  onChange,
  options,
  value,
}) => {
  const onSelectChange = option => onChange(name, option.value);

  return (
    <StyledSelect
      aria-label={`Choose ${name}`}
      name={`${name}`}
      value={value}
      options={options}
      onChange={onSelectChange}
    />
  );
};

CustomSelect.propTypes = {
  name: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  options: PropTypes.arrayOf(PropTypes.object).isRequired,
  value: PropTypes.number.isRequired,
};

export default CustomSelect;
