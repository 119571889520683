import update from 'immutability-helper';

import http from '../../../http';
import formatActivities from './formatters';
import normalizeActivities from './normalizers';

const FETCH_ACTIVITIES_REQUEST = 'rfc/activities/list/FETCH_ACTIVITIES_REQUEST';
const FETCH_ACTIVITIES_SUCCESS = 'rfc/activities/list/FETCH_ACTIVITIES_SUCCESS';
const FETCH_ACTIVITIES_FAILURE = 'rfc/activities/list/FETCH_ACTIVITIES_FAILURE';
const SHOULD_ACTIVITIES_LIST_UPDATE_SET = 'rfc/activities/list/SHOULD_ACTIVITIES_LIST_UPDATE_SET';

const initialState = {
  activitiesListing: {},
  activitiesById: [],
  error: '',
  loading: false,
  shouldListUpdate: true,
};

export default function reducer(state = initialState, action = {}) {
  switch (action.type) {
    case FETCH_ACTIVITIES_REQUEST:
      return update(state, { $merge: { loading: true, shouldListUpdate: true } });
    case FETCH_ACTIVITIES_SUCCESS:
      return update(state, {
        $set: {
          activitiesListing: action.payload.entities.activitiesListing || {},
          activitiesById: action.payload.result,
          error: '',
          loading: false,
          shouldListUpdate: false,
        },
      });
    case FETCH_ACTIVITIES_FAILURE:
      return update(state, { $merge: { error: action.payload, loading: false, shouldListUpdate: true } });
    case SHOULD_ACTIVITIES_LIST_UPDATE_SET:
      return update(state, { shouldListUpdate: { $set: true } });
    default:
      return state;
  }
}

const receiveActivities = payload => ({ type: FETCH_ACTIVITIES_SUCCESS, payload: normalizeActivities(payload) });
const errorActivities = payload => ({ type: FETCH_ACTIVITIES_FAILURE, payload });

export const fetchActivities = (collectionId = 0) => (dispatch, getState) => {
  if (getState().activities.shouldListUpdate) {
    dispatch({ type: FETCH_ACTIVITIES_REQUEST });

    return http.get(`/en/api/v1/user/collections/${collectionId}/activities.json`)
      .then(response => dispatch(receiveActivities(formatActivities(response.data, getState().grades.list))))
      .catch(response => dispatch(errorActivities(response)));
  }

  return {};
};

export const setShouldActivitiesListUpdate = () => ({ type: SHOULD_ACTIVITIES_LIST_UPDATE_SET });
