import React from 'react';
import { Route, Switch } from 'react-router-dom';

import AssessmentsOverview from './pages/AssessmentsOverview';

const Activities = () => (
  <Switch>
    <Route path="/collections/:collectionId/assessments/overview" component={AssessmentsOverview} />
  </Switch>
);

export default Activities;
