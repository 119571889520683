import { createSelector } from 'reselect';
import find from 'lodash/find';

import { getArtifact, formatArtifacts } from 'redux/modules/grades/helpers';
import { getLabelById } from 'redux/modules/scale/helpers';
import { watchPrioriry } from 'redux/modules/tasks/formatters';

function prepareTasksList(sets, tasks, grades, student, scale) {
  const tasksList = [];

  sets.byId.map((setItem) => {
    const set = {
      id: sets.listing[setItem].id,
      title: sets.listing[setItem].label,
      data: [],
    };
    const setData = [];

    watchPrioriry(tasks, scale.showPriority).map((taskItem) => {
      if (taskItem.separator) {
        return null;
      } else if (+taskItem.data_collection_sets_parent_id !== set.id) {
        return null;
      }

      const artifact = getArtifact(
        grades,
        +taskItem.id,
        student,
      );

      const levelId = artifact ? artifact.assessment_items_scales_entries_id : null;
      const comments = formatArtifacts(artifact);

      const task = {
        id: +taskItem.id,
        category: taskItem.taskClass,
        title: taskItem.label,
        comments,
        level: getLabelById(scale, levelId),
      };

      setData.push(task);
      return null;
    });

    set.data = setData;
    tasksList.push(set);
    return null;
  });

  return tasksList;
}

export const getTasksList = createSelector(
  data => data.tasks,
  data => data.grades,
  data => data.scale,
  data => data.currentStudent,
  (tasks, grades, scale, student) => prepareTasksList(tasks.sets, tasks.tasks, grades.list, student, scale),
);

export const getShortcutByTaskId = createSelector(
  (tasks, taskId) => taskId,
  tasks => tasks.taskShortcuts,
  tasks => tasks,
  (taskId, taskShortcuts, tasks) => find(taskShortcuts, item => (+item.id === +taskId)) || tasks,
);
