import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import update from 'immutability-helper';

import Modal from 'components/Modal';
import { Content, Header, Row, StyledButton, TextInput } from './styled';

export default class AddEditModal extends PureComponent {
  static propTypes = {
    description: PropTypes.string.isRequired,
    onModalRequestSave: PropTypes.func.isRequired,
    onRequestClose: PropTypes.func.isRequired,
  }

  constructor(props) {
    super(props);

    this.onInputDescriptionChange = this.onInputDescriptionChange.bind(this);
    this.onSaveClick = this.onSaveClick.bind(this);
  }

  state = {
    description: this.props.description,
  }

  componentWillReceiveProps(nextProps) {
    if (this.props.description !== nextProps.description) {
      this.setState(update(this.state, { description: { $set: nextProps.description } }));
    }
  }

  onInputDescriptionChange(event) {
    this.setState(update(this.state, {
      $merge: {
        description: event.target.value,
      },
    }));
  }

  onSaveClick() {
    this.props.onModalRequestSave(this.state.description);
  }

  render() {
    return (
      <Modal
        aria-label="Description input field"
        headerTitle={(<Header>Add/Edit description</Header>)}
        size="lg"
        {...this.props}
      >
        <Content>
          <TextInput
            aria-label="Type activity description text"
            placeholder="Enter description..."
            rows="24"
            maxLength="512"
            value={this.state.description}
            onChange={this.onInputDescriptionChange}
          />
          <Row>
            <StyledButton
              aria-label="Save"
              color="#70a823"
              onClick={this.onSaveClick}
            >
              Save
            </StyledButton>
            <StyledButton
              aria-label="Cancel"
              color="#ffffff"
              textColor="#cccccc"
              border="1px solid #cccccc"
              onClick={this.props.onRequestClose}
            >
              Cancel
            </StyledButton>
          </Row>
        </Content>
      </Modal>
    );
  }
}
