import { createSelector } from 'reselect';
import { getLabelById } from 'redux/modules/scale/helpers';
import { getArtifact, formatArtifacts, calculateStudentsTableDates } from 'redux/modules/grades/helpers';

export const getAllStudentsIds = createSelector(
  students => students.students,
  students => students.map(student => student.id),
);

export const getGroupStudentsIds = createSelector(
  (students, groupStudents) => groupStudents,
  students => students.students,
  (studentEnrollments, students) => {
    if (studentEnrollments) {
      const groupStudents = students.reduce((result, student) => {
        if (studentEnrollments.includes(student.student_enrollment_id)) {
          result.push(student.id);
        }

        return result;
      }, []);

      return groupStudents;
    }

    return 0;
  },
);

export const getStudentsScores = createSelector(
  data => data.students,
  data => data.grades,
  data => data.currentTask,
  data => data.scale,
  (students, grades, task, scale) => (
    students.students.map((item) => {
      const artifact = getArtifact(
        grades.list,
        task.id,
        item,
      );
      const levelId = artifact ? artifact.assessment_items_scales_entries_id : null;
      const comments = formatArtifacts(artifact);

      return {
        id: item.id,
        student_enrollment_id: item.student_enrollment_id,
        student: {
          avatar: undefined,
          firstname: item.name.firstname,
          lastname: item.name.lastname,
          middlename: item.name.middlename,
          date: comments.createdAt ? comments.createdAt : '',
          gender: item.name.gender,
        },
        comments,
        checked: false,
        selected: levelId ? getLabelById(scale, levelId) : scale.selectedLevels[0].label,
      };
    })
  ),
);

export const getStudentsForTable = createSelector(
  (students, selectedStudents, groupStudents, grades) => grades,
  (students, selectedStudents, groupStudents) => groupStudents,
  (students, selectedStudents) => selectedStudents,
  students => students.students,
  (grades, groupStudents, checkedStudents, studentsList) => studentsList.map((item) => {
    const student = { ...item };
    const tableScoreDates = calculateStudentsTableDates(grades, student.id);

    student.last = tableScoreDates.last;
    student.week = tableScoreDates.thisWeek;
    student.select.checked = checkedStudents.includes(student.student_enrollment_id);

    if (groupStudents) {
      student.disabled = !groupStudents.includes(student.id);
    }
    return student;
  }),
);
