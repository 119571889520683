import axios from 'axios';

const baseURL = process.env.REACT_APP_CAS_API_URL_BASE; // '/' for localhost proxy

const http = axios.create({
  baseURL,
  withCredentials: true,
});

export const getBaseURL = () => baseURL;

export const downloadArtifactFromList = (collectionId = 0, artifactId = 0, activityId = 0) => {
  // eslint-disable-next-line max-len
  if (activityId) window.open(`${baseURL}/en/api/v2/collections/${collectionId}/activities/${activityId}/artifacts/${artifactId}/download.json`);
  else window.open(`${baseURL}/en/api/v2/collections/${collectionId}/artifacts/${artifactId}/download.json`);
};

export const downloadArtifactFromHistory = (collectionId = 0, artifactId = 0, scoreId = 0) => {
  // eslint-disable-next-line max-len
  window.open(`${baseURL}/en/api/v1/user/collections/${collectionId}/grades/${scoreId}/artifacts/${artifactId}/download.json`);
};

export const downloadResource = (collectionId = 0, taskId = 0, resourceId = 0) => {
  window.open(`${baseURL}/en/api/v1/user/collections/${collectionId}/items/${taskId}/resources/${resourceId}.json`);
};

export default http;
