import styled from 'styled-components';

const ButtonRating = styled.button.attrs({
  tabIndex: -1,
})`
  margin: 0 10px;
  height: 34px;
  width: 34px;
  min-width: 34px;
  background-color: ${props => (props.theme.grades[props.grade.toUpperCase()])};
  border: none;
  color: ${({ theme }) => (theme.primaryWhite)};
  font-weight: 500;
  font-size: 14px;
  border-radius: 50%;
  line-height: 100%;
  outline: none;
`;

export default ButtonRating;
