import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import Truncate from 'react-truncate';
import { withRouter } from 'react-router-dom';

import Checkbox from 'components/Checkbox';
import { CheckboxContainer, Container, Description, Label, ObservationDate, StyledButton, Text } from './styled';

class Item extends PureComponent {
  static defaultProps = {
    lines: 2,
    onNoteSelect: () => {},
    selected: null,
  }

  static propTypes = {
    clickable: PropTypes.bool.isRequired,
    history: PropTypes.object.isRequired,
    lines: PropTypes.number,
    note: PropTypes.object.isRequired,
    onNoteSelect: PropTypes.func,
    selected: PropTypes.array,
  }

  static getEllipsis(text, onClick, periods = false) {
    return (
      <span>
        {periods && <span>...<br /></span>}
        <StyledButton
          size="sm"
          color="transparent"
          textColor="#169bd5"
          onClick={onClick}
        >
          {text}
        </StyledButton>
      </span>
    );
  }

  constructor(props) {
    super(props);

    this.onNoteClick = this.onNoteClick.bind(this);
    this.onNoteSelect = this.onNoteSelect.bind(this);
    this.handleKeyDown = this.handleKeyDown.bind(this);
    this.handleTruncate = this.handleTruncate.bind(this);
    this.toggleLines = this.toggleLines.bind(this);
  }

  state = {
    expanded: false,
    truncated: false,
  }

  onNoteSelect() {
    this.props.onNoteSelect(this.props.note.id);
  }

  onNoteClick(event) {
    const {
      clickable,
      history,
      note: { id },
      onNoteSelect,
    } = this.props;

    if (event.target.textContent === 'Show More' || event.target.textContent === 'Show Less') {
      this.toggleLines(event);
    } else if (clickable) {
      history.push(`../notes/${id}/edit`);
    } else {
      onNoteSelect(id);
    }
  }

  handleKeyDown = (event) => {
    const keyCodes = {
      down: 40,
      enter: 13,
      space: 32,
      up: 38,
    };

    const prevTaskItem = event.target.previousElementSibling || '';
    const nextTaskItem = event.target.nextElementSibling || '';

    if (event.keyCode === keyCodes.down && nextTaskItem) {
      event.preventDefault();
      nextTaskItem.focus();
    }

    if (event.keyCode === keyCodes.up && prevTaskItem) {
      event.preventDefault();
      prevTaskItem.focus();
    }

    if (event.keyCode === keyCodes.space || event.keyCode === keyCodes.enter) {
      this.onNoteClick(event);
    }
  }

  handleTruncate(truncated) {
    if (this.state.truncated !== truncated) {
      this.setState({ truncated });
    }
  }

  toggleLines(event) {
    event.preventDefault();

    this.setState({ expanded: !this.state.expanded });
  }

  render() {
    const { expanded, truncated } = this.state;
    const {
      lines,
      note: {
        date,
        id,
        label,
        description,
        time,
      },
      selected,
    } = this.props;

    return (
      <Container
        aria-label="note"
        role="button"
        tabIndex={0}
        onClick={this.onNoteClick}
        onKeyDown={this.handleKeyDown}
      >
        {selected &&
          <CheckboxContainer>
            <Checkbox tabIndex={-1} checked={selected.includes(id)} />
          </CheckboxContainer>
        }
        <Description>
          <Label>{label}</Label>
          <ObservationDate>{date} - {time}</ObservationDate>
          <Text>
            <Truncate
              lines={!expanded && lines}
              ellipsis={Item.getEllipsis('Show More', this.toggleLines, true)}
              onTruncate={this.handleTruncate}
            >
              {description}
            </Truncate>
            {!truncated && expanded && Item.getEllipsis('Show Less', this.toggleLines)}
          </Text>
        </Description>
      </Container>
    );
  }
}

export default withRouter(Item);
