import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import update from 'immutability-helper';
import { Redirect, withRouter } from 'react-router';
import { connect } from 'react-redux';

import { copyActivity, deleteActivity, openCloseActivity } from 'redux/modules/activity';
import Note from './components/Note';
import { ButtonsColumn, Column, Container, StyledButton, Text, TextInput } from './styled';

class Detail extends PureComponent {
  static propTypes = {
    activity: PropTypes.object.isRequired,
    copyActivity: PropTypes.func.isRequired,
    detailOpened: PropTypes.bool.isRequired,
    history: PropTypes.object.isRequired,
    match: PropTypes.object.isRequired,
    deleteActivity: PropTypes.func.isRequired,
    openCloseActivity: PropTypes.func.isRequired,
    shouldGoToActivitiesList: PropTypes.bool.isRequired,
  }

  constructor(props) {
    super(props);

    this.toogleNoteInput = this.toogleNoteInput.bind(this);
    this.onCopyClick = this.onCopyClick.bind(this);
    this.onDeleteClick = this.onDeleteClick.bind(this);
    this.onEditClick = this.onEditClick.bind(this);
    this.onOpenCloseActivityClick = this.onOpenCloseActivityClick.bind(this);
  }

  state = {
    noteInputOpen: false,
  }

  onCopyClick() {
    const { activityId, collectionId } = this.props.match.params;
    this.props.copyActivity(collectionId, activityId);
  }

  onDeleteClick() {
    const { activityId, collectionId } = this.props.match.params;
    this.props.deleteActivity(collectionId, activityId);
  }

  onEditClick() {
    this.props.history.push('./edit');
  }

  onOpenCloseActivityClick() {
    const { activityId, collectionId } = this.props.match.params;
    this.props.openCloseActivity(collectionId, activityId);
  }

  toogleNoteInput() {
    this.setState(update(this.state, {
      noteInputOpen: { $apply: noteInputOpen => !noteInputOpen },
    }));
  }

  render() {
    const { activity, detailOpened, shouldGoToActivitiesList } = this.props;

    if (shouldGoToActivitiesList) {
      return <Redirect to="../list/open" />;
    }

    return (
      <Container detailOpened={detailOpened}>
        <Column>
          <Text>{activity.description}</Text>
          <Note note={activity.notes} />
          {
            this.state.noteInputOpen ?
              <TextInput
                aria-label="Input note"
                placeholder="Type your note here..."
                rows="4"
              /> :
              null
          }
        </Column>
        <ButtonsColumn>
          {
            activity.closed ?
              <div>
                <StyledButton
                  tabIndex={detailOpened ? 0 : -1}
                  icon="downArrow"
                  onClick={this.onOpenCloseActivityClick}
                >
                  Reopen activity
                </StyledButton>
                <StyledButton
                  tabIndex={detailOpened ? 0 : -1}
                  icon="deleteWhite"
                  onClick={this.onDeleteClick}
                >
                  Delete activity
                </StyledButton>
              </div> :
              <StyledButton
                tabIndex={detailOpened ? 0 : -1}
                icon="upArrow"
                onClick={this.onOpenCloseActivityClick}
              >
                Close activity
              </StyledButton>
          }
          <StyledButton
            tabIndex={detailOpened ? 0 : -1}
            icon="whitePen"
            onClick={this.onEditClick}
          >
            Edit activity
          </StyledButton>
          <StyledButton
            tabIndex={detailOpened ? 0 : -1}
            icon="copy"
            onClick={this.onCopyClick}
          >
            Make a copy
          </StyledButton>
          <StyledButton
            tabIndex={detailOpened ? 0 : -1}
            icon="plus"
            onClick={this.toogleNoteInput}
          >
            Add note
          </StyledButton>
        </ButtonsColumn>
      </Container>
    );
  }
}

const mapStateToProps = ({ activity }) => ({
  shouldGoToActivitiesList: activity.shouldGoToActivitiesList,
});

const mapDispatchToProps = {
  copyActivity,
  deleteActivity,
  openCloseActivity,
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Detail));
