import React from 'react';
import PropTypes from 'prop-types';

import SelectGroup from 'components/SelectGroup';

import { Container, Students, Item, Round } from './styled';

const Header = ({ scale, activityStudents }) => (
  <Container>
    <Students>
      Name {!activityStudents.length && <SelectGroup />}
    </Students>
    {scale.map(item => (
      <Item grade={item} key={item.id}>
        <Round>{item.label}</Round>
      </Item>
    ))}
  </Container>
);

Header.propTypes = {
  activityStudents: PropTypes.array.isRequired,
  scale: PropTypes.array.isRequired,
};

export default Header;
