import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import moment from 'moment';

import { getBaseURL } from '../../http';
import Button from 'components/Button';
import { Container, Description, Info, Comment, ArtifactContainer, ArtifactImage, DeleteButton } from './styled';

import noImage from './images/noArtifact-placeholder.png';

class ArtifactItem extends PureComponent {
  static defaultProps = {
    downloadable: false,
    onDelete: () => {},
    onDownload: () => {},
    withBorder: false,
  }

  static propTypes = {
    artifact: PropTypes.object.isRequired,
    downloadable: PropTypes.bool,
    onDelete: PropTypes.func,
    onDownload: PropTypes.func,
    match: PropTypes.object.isRequired,
    withBorder: PropTypes.bool,
  }

  constructor(props) {
    super(props);

    this.isExist = true;
    this.handleKeyDown = this.handleKeyDown.bind(this);
    this.loadingSuccess = this.loadingSuccess.bind(this);
  }

  state = {
    url: noImage,
  }

  componentDidMount() {
    const {
      artifact: { id: artifactId },
      match: { params: { collectionId, activityId } },
    } = this.props;

    const img = new Image();
    if (artifactId) {
      // eslint-disable-next-line max-len
      if (activityId) img.src = `${getBaseURL()}/en/api/v2/collections/${collectionId}/activities/${activityId}/artifacts/${artifactId}/download.json`;
      else img.src = `${getBaseURL()}/en/api/v2/collections/${collectionId}/artifacts/${artifactId}/download.json`;
    } else {
      img.src = this.props.artifact.s3_file_name;
    }
    img.onload = this.loadingSuccess;
  }

  componentWillUnmount() {
    this.isExist = false;
  }

  handleKeyDown = (event) => {
    const keyCodes = {
      enter: 13,
      space: 32,
    };

    if (event.keyCode === keyCodes.space || event.keyCode === keyCodes.enter) {
      event.preventDefault();
      this.props.onDownload();
    }
  }

  loadingSuccess() {
    if (this.isExist) {
      const {
        artifact: { id: artifactId },
        match: { params: { collectionId, activityId } },
      } = this.props;

      if (artifactId) {
        if (activityId) {
          this.setState({
            // eslint-disable-next-line max-len
            url: `${getBaseURL()}/en/api/v2/collections/${collectionId}/activities/${activityId}/artifacts/${artifactId}/download.json`,
          });
        } else {
          this.setState({
            url: `${getBaseURL()}/en/api/v2/collections/${collectionId}/artifacts/${artifactId}/download.json`,
          });
        }
      } else {
        this.setState({ url: this.props.artifact.s3_file_name });
      }
    }
  }

  render() {
    const {
      artifact: {
        original_file_name: filename,
        created_at: createdAt,
        comment,
      },
      downloadable,
      onDelete,
      onDownload,
      withBorder,
    } = this.props;

    return (
      <Container withBorder={withBorder}>
        <ArtifactContainer
          aria-label={downloadable ? 'Download artifact' : ''}
          tabIndex={downloadable ? 0 : -1}
          role="button"
          downloadable={downloadable}
          onClick={onDownload}
          onKeyDown={this.handleKeyDown}
        >
          <ArtifactImage src={this.state.url} alt="artifact" />
        </ArtifactContainer>
        <Description>
          <Comment>
            <h4>Comment</h4>
            {comment || 'There is no comment for this artifact'}
          </Comment>
          <Info>
            <h4>Upload date</h4>
            { moment(+(`${createdAt}000`)).format('h:mm A M/D/YYYY') }
          </Info>
          <Info>
            <h4>File name</h4>
            {filename}
          </Info>
        </Description>
        {withBorder &&
          <DeleteButton>
            <Button icon="close" color="transparent" onClick={onDelete} />
          </DeleteButton>
        }
      </Container>
    );
  }
}

export default withRouter(ArtifactItem);
