import styled from 'styled-components';
import Button from 'components/Button';

export const Container = styled.li`
  display: flex;
  height: 185px;
  box-sizing: border-box;
  flex: 1;
  list-style-type: none;
  margin: 15px;
  flex-direction: row;
  border: 1px solid ${({ theme }) => theme.primaryGrey};
`;

export const ControlsBlock = styled.div`
  display: flex;
  flex-shrink: 0;
  width: 40px;
  height: 100%;
  justify-content: center;
  padding: 10px;
`;

export const StyledButton = styled(Button)`
  padding: 5px;
`;
