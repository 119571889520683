import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  width: 100%;
  height: 100%;
  align-items: center;
  text-decoration: none;
  color: #666;
`;

export const Color = styled.div`
  width: 42px;
  height: 42px;
  background-color: ${props => props.color};
  flex-shrink: 0;
`;

export const Name = styled.p`
  margin-left: 16px;
  overflow: hidden;
  text-overflow: ellipsis;
`;
