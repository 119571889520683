import http from '../../../http';
import formatAssessmentsList from './formatters';

const FETCH_ASSESSMENTS_SUCCESS = 'rfc/assessments/FETCH_ASSESSMENTS_SUCCESS';
const FETCH_ASSESSMENTS_FAILURE = 'rfc/assessments/FETCH_ASSESSMENTS_FAILURE';
const FETCH_ASSESSMENTS_REQUEST = 'rfc/assessments/FETCH_ASSESSMENTS_REQUEST';

const initialState = {
  list: [],
  error: '',
  loading: true,
};

export default function reducer(state = initialState, action = {}) {
  switch (action.type) {
    case FETCH_ASSESSMENTS_SUCCESS:
      return { ...state, ...{ list: action.payload, loading: false } };
    case FETCH_ASSESSMENTS_FAILURE:
      return { ...state, ...{ error: action.payload, loading: false } };
    case FETCH_ASSESSMENTS_REQUEST:
      return { ...state, ...{ loading: true } };
    default:
      return state;
  }
}

const success = payload => ({ type: FETCH_ASSESSMENTS_SUCCESS, payload });
const failed = payload => ({ type: FETCH_ASSESSMENTS_FAILURE, payload });
const loading = () => ({ type: FETCH_ASSESSMENTS_REQUEST });

export const getAssessmentsList = () => (dispatch) => {
  dispatch(loading());
  http.get('en/api/v1/user/collections.json')
    .then(response => dispatch(success(formatAssessmentsList(response.data))))
    .catch(error => dispatch(failed(error)));
};
