import update from 'immutability-helper';

import http from '../../../http';
import getFileBase64 from '../../../services/getFileBase64';

const API_REQUEST = 'rfc/activities/list/artifacts/API_REQUEST';
const FETCH_ARTIFACTS_SUCCESS = 'rfc/activities/list/FETCH_ARTIFACTS_SUCCESS';
const FETCH_ARTIFACTS_FAILURE = 'rfc/activities/list/FETCH_ARTIFACTS_FAILURE';
const UPLOAD_ARTIFACT_SUCCESS = 'rfc/activities/artifact/create/CREATE_ARTIFACT_SUCCESS';
const UPLOAD_ARTIFACT_FAILURE = 'rfc/activities/artifact/create/CREATE_ARTIFACT_FAILURE';

const initialState = {
  artifacts: [],
  createdId: null,
  error: '',
  loading: true,
};

export default function reducer(state = initialState, action = {}) {
  switch (action.type) {
    case API_REQUEST:
      return update(state, { $merge: { loading: true } });
    case FETCH_ARTIFACTS_SUCCESS:
      return update(state, {
        $set: {
          artifacts: action.payload,
          createdId: null,
          error: '',
          loading: false,
        },
      });
    case UPLOAD_ARTIFACT_SUCCESS:
      return update(state, {
        artifacts: { $push: [action.payload] },
        $merge: { createdId: action.payload.id, error: '', loading: false },
      });
    case FETCH_ARTIFACTS_FAILURE:
      return update(state, { $merge: { createdId: null, error: action.payload, loading: false } });
    case UPLOAD_ARTIFACT_FAILURE:
      return update(state, { $merge: { createdId: null, error: action.payload, loading: false } });
    default:
      return state;
  }
}

const errorArtifacts = payload => ({ type: FETCH_ARTIFACTS_FAILURE, payload });
const receiveArtifacts = payload => ({ type: FETCH_ARTIFACTS_SUCCESS, payload });

export const fetchArtifacts = (collectionId = 0) => (dispatch) => {
  dispatch({ type: API_REQUEST });
  http.get(`/en/api/v2/collections/${collectionId}/artifacts.json`)
    .then(response => dispatch(receiveArtifacts(response.data.reverse())))
    .catch(response => dispatch(errorArtifacts(response)));
};

const failedUploadArtifact = payload => ({ type: UPLOAD_ARTIFACT_FAILURE, payload });
const successUploadArtifact = payload => ({ type: UPLOAD_ARTIFACT_SUCCESS, payload });

export const uploadArtifact = (collectionId = 0, file, comment) => async (dispatch) => {
  dispatch({ type: API_REQUEST });
  const base64 = await getFileBase64(file);
  http.post(`/en/api/v2/collections/${collectionId}/artifacts.json`, { name: file.name, data: base64, comment })
    .then(response => dispatch(successUploadArtifact(response.data)))
    .catch(response => dispatch(failedUploadArtifact(response)));
};
