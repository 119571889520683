import styled from 'styled-components';

export const Button = styled.button`
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100%;
    padding: 0 10px;
    border-radius: 4px;
    border: none;
    background-color: ${({ theme, backgroundColor }) => backgroundColor || theme.primaryGreen};
    color: ${({ theme, color }) => color || theme.primaryWhite};
    font-size: ${({ fontSize = '14px' }) => fontSize};
    font-weight: ${({ fontWeight = '700' }) => fontWeight};
    text-transform: ${({ uppercase }) => (uppercase ? 'uppercase' : 'none')};
    cursor: pointer;

    :disabled {
        opacity: 0.5;
        cursor: default;
    }
`;

export default Button;
