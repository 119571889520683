import React from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';

import RatingChanger from 'components/RatingChanger';
import ScaleModal from 'components/Modals/ScaleModal';

import { Container, Column, Title, RatedAt, StyledButton } from './styled';

const Rating = ({
  scale,
  selected,
  onScoreChange,
  grade,
  isOpen,
  openScaleModal,
  closeScaleModal,
}) => (
  <Container>
    <Title>Rating</Title>
    <Column>
      <RatingChanger onScoreChange={onScoreChange} score={scale} selected={selected} />
      {grade && <RatedAt>{moment(+(`${grade.created_at}000`)).format('M/D/YY - h:m A')}</RatedAt>}
    </Column>
    <StyledButton onClick={openScaleModal}>Browse Scale Description</StyledButton>
    <ScaleModal
      isOpen={isOpen}
      onRequestClose={closeScaleModal}
      scale={scale}
    />
  </Container>
);

Rating.propTypes = {
  scale: PropTypes.array.isRequired,
  grade: PropTypes.object,
  selected: PropTypes.string.isRequired,
  onScoreChange: PropTypes.func.isRequired,
  isOpen: PropTypes.bool.isRequired,
  openScaleModal: PropTypes.func.isRequired,
  closeScaleModal: PropTypes.func.isRequired,
};

Rating.defaultProps = {
  grade: null,
};

export default Rating;
