import { createSelector } from 'reselect';

const groupListSelector = createSelector(
  groups => groups.groupsListing,
  groups => groups.groupsById,
  (listing, byId) => byId.map(id => listing[id]),
);

export const getGroupsNames = createSelector(
  groups => groups.groupsListing,
  groups => groups.groupsById,
  (listing, byId) => {
    const options = byId.map((id, index) => ({ value: index, label: listing[id].label }));
    options.unshift({ value: -1, label: 'All Students' });
    return options;
  },
);

export default groupListSelector;
