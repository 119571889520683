import { normalize, schema } from 'normalizr';

const studentsIdsSchema = new schema.Entity('students_ids');
const groupSchema = new schema.Entity('groups', {
  studentsIds: [studentsIdsSchema],
});
const groupsListSchema = [groupSchema];

const normalizeGroupsList = list => normalize(list, groupsListSchema);

export default normalizeGroupsList;
