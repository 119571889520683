import styled from 'styled-components';

export const Container = styled.div`
  height: 100%;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
`;

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  align-self: center;
  width: 90%;
  height: 95%;
  max-width: 1000px;
`;

export const TextWrapper = styled.div`
  color: ${({ theme }) => theme.darkGrey};
  display: flex;
  justify-content: center;
  align-items: center;
  height: 50%;

  p {
    font-size: 36px;
    margin: 5px 0;
    text-align: center;

    @media (max-width: 576px) {
      font-size: 20px;
    }
  }
`;

export const ButtonWrapper = styled.div`
  width: 100%;
  max-width: 275px;
  height: 65px;
  margin: 2% 0 8%;
`;

export const RouteLink = styled.span`
  cursor: pointer;
  font-size: 16px;
  color: ${({ theme }) => theme.primaryBlue};
  width: fit-content;
  padding: 0 10px;
`;
