import http from '../../../http';

const FETCH_HISTORY_SUCCESS = 'rfc/history/FETCH_HISTORY_SUCCESS';
const FETCH_HISTORY_FAILURE = 'rfc/history/FETCH_HISTORY_FAILURE';
const FETCH_HISTORY_REQUEST = 'rfc/history/FETCH_HISTORY_REQUEST';

const initialState = {
  data: [],
  error: '',
  loading: false,
};

export default function reducer(state = initialState, action = {}) {
  switch (action.type) {
    case FETCH_HISTORY_SUCCESS:
      return { ...state, ...{ data: action.payload, loading: false } };
    case FETCH_HISTORY_FAILURE:
      return { ...state, ...{ error: action.payload, loading: false } };
    case FETCH_HISTORY_REQUEST:
      return { ...state, ...{ loading: true } };
    default:
      return state;
  }
}

const success = payload => ({ type: FETCH_HISTORY_SUCCESS, payload });
const failed = payload => ({ type: FETCH_HISTORY_FAILURE, payload });
const loading = () => ({ type: FETCH_HISTORY_REQUEST });

export function fetchHistory(collectionId = 0, studentId = 0, itemId = 0) {
  return (dispatch) => {
    dispatch(loading());
    return (
      http.get(`en/api/v1/user/collections/${collectionId}/students/${studentId}/items/${itemId}/grades/history.json`)
        .then((response) => {
          dispatch(success(response.data));
        })
        .catch((error) => {
          dispatch(failed(error));
        })
    );
  };
}

