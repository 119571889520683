import styled from 'styled-components';

export const Container = styled.li`
  display: flex;
  align-items: center;
  border-bottom: 1px solid ${({ theme }) => theme.primaryGrey};
  padding: 10px 15px;
`;

export const Content = styled.div`
  margin-left: 10px;
  color: ${({ theme }) => theme.darkGrey};
`;

export const TaskClass = styled.div`
  font-size: 12px;
  font-weight: 600;
`;
