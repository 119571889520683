import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';

import Row from './components/Row';

import { Container, HeaderContainer, Header, ScrollDiv, Content, Column } from './styled';

export default class Table extends PureComponent {
  static propTypes = {
    data: PropTypes.arrayOf(PropTypes.object).isRequired,
    columns: PropTypes.arrayOf(PropTypes.object).isRequired,
    isExpanded: PropTypes.bool,
  };

  static defaultProps = {
    isExpanded: true,
  };

  constructor() {
    super();

    this.registerRef = this.registerRef.bind(this);
  }

  state = {
    isScrollVisible: false,
  };

  componentDidMount() {
    if (this.tableRef.clientWidth === this.tableRef.offsetWidth) {
      this.setState({ isScrollVisible: true });
    }
  }

  componentWillUnmount() {
    this.tableRef = null;
  }

  registerRef(ref) {
    this.tableRef = ref;
  }

  tableRef = null;

  render() {
    return (
      <Container>
        {
          this.props.isExpanded &&
            <HeaderContainer>
              <Header>
                {this.props.columns.map((item, index) => (
                  <Column
                    key={index}
                    columnWidth={item.width}
                    primaryWidth={item.primaryWidth}
                  >
                    {item.title}
                  </Column>
                ))}
              </Header>
              {!this.state.isScrollVisible && <ScrollDiv />}
            </HeaderContainer>
        }
        <Content innerRef={this.registerRef} isExpanded={this.props.isExpanded}>
          {this.props.data.map((row, rowIndex) => (
            <Row
              key={rowIndex}
              rowIndex={rowIndex}
              data={this.props.data}
              columns={this.props.columns}
              isExpanded={this.props.isExpanded}
            />
          ))}
        </Content>
      </Container>
    );
  }
}
