import React from 'react';
import PropTypes from 'prop-types';
import { Container, NotificationItemStyled, ItemDelete } from './styled';

const NotificationList = props => (
  <Container>
    {props.notifications.map((notification, index) => (
      <NotificationItemStyled key={index}>
        <ItemDelete />
        <span>{notification.lastname} {notification.firstname}</span>
        <span>{notification.taskinfo}</span>
        <span>Due Date: {notification.date}</span>
      </NotificationItemStyled>
    ))}
  </Container>
);

NotificationList.propTypes = {
  notifications: PropTypes.arrayOf(PropTypes.shape({
    lastname: PropTypes.string,
    firstname: PropTypes.string,
    taskinfo: PropTypes.string,
    date: PropTypes.string,
  })).isRequired,
};

export default NotificationList;
