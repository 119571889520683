import styled from 'styled-components';
import searchIcon from './images/searchIcon.png';

export const Container = styled.div`
  display: flex;
  align-items: center;
  height: 60px;
  padding: 0 15px;
  color: ${({ theme }) => theme.darkGrey};
`;

export const Search = styled.input.attrs({
  placeholder: 'Keyword Search',
  type: 'search',
})`
  width: 300px;
  padding: 6px 6px 6px 35px;
  background-color: ${({ theme }) => theme.lightGrey};
  background-image: url(${searchIcon});
  background-position: 15px;
  background-repeat: no-repeat;
  background-size: 15px;
  color: ${({ theme }) => theme.darkGrey};
  border: 1px solid ${({ theme }) => theme.primaryGrey};
  border-radius: 4px;
  font-size: 14px;
`;
