import React from 'react';
import { Route, Switch } from 'react-router-dom';

import List from './pages/List';
import Profile from './pages/Profile';

const Students = () => (
  <Switch>
    <Route path="/collections/:collectionId/students/list" component={List} />
    <Route path="/collections/:collectionId/students/:studentId" component={Profile} />
  </Switch>
);

export default Students;
