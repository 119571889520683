import React from 'react';
import PropTypes from 'prop-types';

import Button from 'components/Button';
import { Container, Thumbnail, Details } from './styled';

const Attachment = ({ name, preview, onRemove }) => (
  <Container>
    <Thumbnail src={preview} alt="browsed" />
    <Details>
      {name}
      <Button
        icon="delete"
        aria-label="delete browsed image"
        tabIndex={0}
        color="transparent"
        onClick={onRemove}
      />
    </Details>
  </Container>
);

Attachment.propTypes = {
  name: PropTypes.string.isRequired,
  preview: PropTypes.string.isRequired,
  onRemove: PropTypes.func.isRequired,
};

export default Attachment;
