import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  height: 100%;
  overflow-y: auto;
`;

export const Task = styled.div`
  position: relative;
  display: flex;
  box-sizing: border-box;
  flex-direction: column;
  padding: 20px;
  width: 50%;
  background-color: #fff;
  color: #666;
  padding-bottom: 80px;
`;

export const TaskTitle = styled.div`
  margin-bottom: 20px;
  text-transform: uppercase;
  font-size: 13px;
  font-weight: 600;
`;

export const TaskDescription = styled.div`
  display: flex;
  flex-direction: column;
  overflow-y: auto;
  line-height: 1.3;
`;

export const StyledLink = styled.div`

`;

export const ScaleContainer = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  width: 50%;
  height: 100%;
  box-sizing: border-box;
  padding: 20px;
  background-color: #f1f1f1;
  overflow-y: auto;
`;
