import styled from 'styled-components';
import Button from 'components/Button';

export const ArtifactsList = styled.ul`
  margin: 0;
  padding: 0;
  overflow-y: auto;
`;

export const Container = styled.div`
  display: flex;
  height: calc(100% - 70px);
  flex-direction: column;
`;

export const StyledButton = styled(Button)`
  margin-left: auto;
  padding: 5px;
`;
