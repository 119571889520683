import React from 'react';
import PropTypes from 'prop-types';

import Item from './components/Item';

import { Container, Title } from './styled';

const Scale = ({ grades }) => (
  <Container>
    <Title>Level Descriptors</Title>
    {grades.map((item, index) => (
      <Item key={index} label={item.label} description={item.description} />
    ))}
  </Container>
);

Scale.propTypes = {
  grades: PropTypes.array.isRequired,
};

export default Scale;
