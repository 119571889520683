import moment from 'moment';

const formatAssessmentsList = list => (
  list.filter(item => item.assessments.taxonomy === 'FORMATIVE').map(item => ({
    id: item.id,
    name: item.name,
    end_date: moment(+(`${item.cycle_end_date}000`)).format('M/D/YYYY'),
  }))
);

export default formatAssessmentsList;
