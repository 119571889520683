import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import { getShortcutByTaskId } from 'redux/modules/tasks/selectors';
import Button from 'components/Button';
import ResourcesList from 'components/ResourcesList';
import { Category, Container, Header, Subheader, Task, Title } from './styled';

const Resources = ({ history, task: { category, group, title } }) => (
  <Container>
    <Header>
      <Button
        icon="blueBack"
        size="lg"
        color="transparent"
        textColor="#666666"
        onClick={history.goBack}
        aria-label="back to history"
      >
        Resources/materials
      </Button>
    </Header>
    <Subheader>{group}</Subheader>
    <Task>
      <Category>{category}</Category>
      <Title>{title}</Title>
    </Task>
    <ResourcesList />
  </Container>
);

Resources.propTypes = {
  history: PropTypes.object.isRequired,
  match: PropTypes.object.isRequired, // eslint-disable-line react/no-unused-prop-types
  task: PropTypes.object.isRequired,
};

const mapStateToProps = ({ tasks }, { match }) => ({
  task: getShortcutByTaskId(tasks, match.params.taskId),
});

export default connect(mapStateToProps)(Resources);
