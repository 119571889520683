import React from 'react';
import { Route, Redirect, Switch } from 'react-router-dom';

import ActivitiesList from './pages/List';
import ActivityDetail from './pages/Detail';
import BrowseArtifacts from './pages/BrowseArtifacts';
import CreateActivity from './pages/Create';
import CreateNote from './pages/CreateNote';
import EditActivity from './pages/Edit';
import EditNote from './pages/EditNote';

const base = '/collections/:collectionId/activities';

const Activities = () => (
  <Switch>
    <Route exact path={`${base}/:activityId/detail`} component={ActivityDetail} />
    <Route exact path={`${base}/:activityId/edit`} component={EditActivity} />
    <Route exact path={`${base}/create`} component={CreateActivity} />
    <Redirect exact from={`${base}/list`} to="./list/open" />
    <Route path={`${base}/list`} component={ActivitiesList} />
    <Route path={`${base}/:activityId/artifacts`} component={BrowseArtifacts} />
    <Route path={`${base}/notes/create`} component={CreateNote} />
    <Route path={`${base}/notes/:noteId/edit`} component={EditNote} />
  </Switch>
);

export default Activities;
