import React from 'react';
import PropTypes from 'prop-types';

import { Container } from './styled';

const Subheader = ({ children, module }) => (
  <Container module={module}>
    {children}
  </Container>
);

Subheader.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.object),
    PropTypes.object,
  ]).isRequired,
  module: PropTypes.string.isRequired,
};

export default Subheader;
