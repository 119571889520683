import { createStore, applyMiddleware } from 'redux';
import ReduxThunk from 'redux-thunk';
import { composeWithDevTools } from 'redux-devtools-extension';

import rootReducer from './reducers';

import { setShouldRedirectToLogin } from 'redux/modules/app';

const authorizationChecker = () => next => (action) => {
  if (window.location.pathname !== '/login') {
    const { payload } = action;
    if (payload && payload.response && payload.response.status === 401) {
      return next(setShouldRedirectToLogin());
    }
  }

  return next(action);
};

const createStoreWithMiddleware = applyMiddleware(authorizationChecker, ReduxThunk)(createStore);

export default function configureStore(initialState) {
  const store = createStoreWithMiddleware(rootReducer, initialState, composeWithDevTools());
  return store;
}
