import React from 'react';
import PropTypes from 'prop-types';

import { Container, Input } from './styled';

const Checkbox = ({ checked, ...rest }) => (
  <Container isChecked={checked} >
    <Input
      type="checkbox"
      role="checkbox"
      aria-checked={checked}
      aria-label={checked ? 'checked' : 'unchecked'}
      defaultChecked={checked}
      {...rest}
    />
  </Container>
);

Checkbox.propTypes = {
  checked: PropTypes.bool,
};

Checkbox.defaultProps = {
  checked: false,
};

export default Checkbox;
