import styled from 'styled-components';

export const Container = styled.div`
  height: 100%;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
`;

export const Text = styled.div`
  color: ${({ theme }) => theme.primaryGrey};
  display: flex;
  flex-direction: column;
  height: 30%;

  p {
    font-size: 1.7rem;
    margin: 5px 0;
  }
`;

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-self: center;
  width: 90%;
  height: 90%;
  max-width: 1000px;
`;
