import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';

import { downloadArtifactFromList } from '../../../../http';
import ArtifactItem from 'components/ArtifactItem';
import Checkbox from 'components/Checkbox';
import { Container, ControlsBlock, StyledButton } from './styled';

class ListItem extends PureComponent {
  static defaultProps = {
    deletable: false,
    onSelect: () => {},
    selected: undefined,
  }

  static propTypes = {
    artifact: PropTypes.object.isRequired,
    deletable: PropTypes.bool,
    downloadable: PropTypes.bool.isRequired,
    match: PropTypes.object.isRequired,
    onDeleteArtifact: PropTypes.func.isRequired,
    onSelect: PropTypes.func,
    selected: PropTypes.array,
  }

  constructor(props) {
    super(props);

    this.onDeleteClick = this.onDeleteClick.bind(this);
    this.onDownloadClick = this.onDownloadClick.bind(this);
    this.onSelectArtifact = this.onSelectArtifact.bind(this);
  }

  onDeleteClick() {
    this.props.onDeleteArtifact(this.props.artifact.id);
  }

  onDownloadClick() {
    if (this.props.downloadable) {
      const { artifact, match: { params: { collectionId, activityId } } } = this.props;

      downloadArtifactFromList(collectionId, artifact.id, activityId);
    }
  }

  onSelectArtifact() {
    this.props.onSelect(this.props.artifact.id);
  }

  render() {
    const {
      selected,
      deletable,
      downloadable,
      artifact,
    } = this.props;

    return (
      <Container>
        {selected &&
          <ControlsBlock>
            <Checkbox checked={selected.includes(artifact.id)} onClick={this.onSelectArtifact} />
          </ControlsBlock>
        }
        {deletable &&
          <ControlsBlock>
            <StyledButton
              aria-label="Delete activity artifact"
              color="transparent"
              icon="delete"
              onClick={this.onDeleteClick}
            />
          </ControlsBlock>
        }
        <ArtifactItem artifact={artifact} downloadable={downloadable} onDownload={this.onDownloadClick} />
      </Container>
    );
  }
}

export default withRouter(ListItem);
