import React from 'react';
import { Route, Switch } from 'react-router-dom';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

import Preloader from 'components/Preloader';

import { Content, Header, HeaderText, Container } from './styled';

import ScoreSheetsUpload from './pages/ScoreSheetsUpload';
import ScoreSheetsPreview from './pages/ScoreSheetsPreview';
import ScoreSheetsHistory from './pages/ScoreSheetsHistory';

const ScoreSheets = ({ imageUploading }) => (
  <Content>
    <Preloader loading={imageUploading} />
    <Header>
      <HeaderText>SCORE SHEET UPLOAD</HeaderText>
    </Header>
    <Container>
      <Switch>
        <Route path="/scoresheets/upload" component={ScoreSheetsUpload} />
        <Route path="/scoresheets/preview" component={ScoreSheetsPreview} />
        <Route path="/scoresheets/history" component={ScoreSheetsHistory} />
      </Switch>
    </Container>
  </Content>
);

const mapStateToProps = ({
  scoreSheets: {
    imageUploading,
  },
}) => ({
  imageUploading,
});

ScoreSheets.propTypes = {
  imageUploading: PropTypes.bool.isRequired,
};

export default connect(mapStateToProps)(ScoreSheets);
