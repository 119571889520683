import styled from 'styled-components';
import CameraIcon from './images/camera.png';

export const Collapsed = styled.div`
  position: relative;
  display: flex;
  flex-shrink: 0;
  align-items: center;
  justify-content: center;
  width: 90px !important;
  height: 90px !important;
  margin-right: 15px;
  background: ${({ theme }) => theme.primaryGrey};
  border: 7px solid ${({ theme }) => theme.primaryGrey};
  box-sizing: border-box;
  cursor: ${({ disabled }) => (disabled ? 'default' : 'pointer')};

  button {
    display:  ${({ disabled }) => (disabled ? 'none' : 'block')};
  }

  &:hover {
    button {
      display: none;
      transition: 0.2s linear;
    }

    img {
      transform: scale(1.15);
    }
  }
`;

export const Container = styled.div`
  display: block;
`;

export const Controls = styled.div`
  position: absolute;
  width: 70px;
  height: 100%;
  right: 0;
  top: 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 20px 0;
  box-sizing: border-box;
  justify-content: space-between;

  button {
    &:active {
      transform: scale(0.95);
    }
  }

  div {
    height: 120px;
    display: flex;
    flex-direction: column-reverse;
    justify-content: space-between;
  }
`;

export const Description = styled.div`
  margin-top: 15px;
  z-index: 1000;
  color: ${({ theme }) => theme.primaryWhite};
  font-size: 18px;
  display: flex;
  align-items: center;
  flex-direction: column;
  line-height: 1.5;
  text-shadow: 1px 1px 2px #252525;
`;

export const DownloadHelp = styled.span`
  margin-top: 8px;
  z-index: 1000;
  color: ${({ theme }) => theme.error};
  font-weight: 500;
  font-size: 20px;
  text-shadow: 1px 1px 2px #252525;
`;

export const Expanded = styled.div`
  position: fixed;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  z-index: 99;

  img {
    z-index: 99;
    max-width: ${props => props.maxWidth}%;
    box-shadow: 1px 1px 15px #252525;
  }
`;

export const FullImage = styled.img`
  background: ${({ theme }) => theme.primaryWhite};
  cursor: pointer;
`;

export const ImageContainer = styled.div`
  overflow: hidden;
`;

export const Img = styled.img`
  width: 100%;
  transition: 0.1s linear;
`;

export const Overlay = styled.div`
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;

  background: #000;
  opacity: 0.6;
  align-items: center;
  justify-content: center;

  &:focus {
    opacity: 0.8;
  }
`;

export const Toggle = styled.button`
  position: absolute;
  z-index: 0;
  left: -10px;
  bottom: -10px;
  border: none;
  background: #000;
  opacity: 0.8;
  width: 25px;
  height: 25px;
  padding: 0;

  &:after {
    content: '';
    display: block;
    width: 20px;
    height: 20px;
    background: url(${CameraIcon}) no-repeat;
    background-size: 80%;
    background-position: 30% 30%;
    margin: 0 auto;
    cursor: pointer;
  }
`;
