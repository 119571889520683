import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  width: 100%;
  height: 100%;
  align-items: center;
`;

export const Avatar = styled.div`
  height: 50px;
  width: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
  border: 1px solid #ccc;
  padding: 2px;
  border-radius: 50%;
`;

export const Image = styled.img`
  width: 100%;
  height: 100%;
  overflow: hidden;
  border-radius: 50px;
  user-select: none;
`;

export const Info = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  padding: 5px 0;
  margin-left: 15px;
`;

export const Name = styled.div`
  display: flex;
  font-size: 20px;
`;

export const SDate = styled.div`
  display: flex;
  font-size: 16px;
`;

export const LabelDate = styled.div`
  display: flex;
  font-size: 14px;
`;

