import queryString from 'query-string';

import http from '../../../http';
import { getLevelIdByLabel } from 'redux/modules/scale/helpers';
import { postByScoreId } from 'redux/modules/grades/helpers';

const FETCH_GRADES_SUCCESS = 'rfc/grades/FETCH_GRADES_SUCCESS';
const FETCH_GRADES_FAILURE = 'rfc/grades/FETCH_GRADES_FAILURE';
const FETCH_GRADES_REQUEST = 'rfc/grades/FETCH_GRADES_REQUEST';

const initialState = {
  list: [],
  newGrade: {},
  error: '',
  loading: false,
};

export default function reducer(state = initialState, action = {}) {
  switch (action.type) {
    case FETCH_GRADES_SUCCESS:
      return { ...state, ...{ list: action.payload, loading: false } };
    case FETCH_GRADES_FAILURE:
      return { ...state, ...{ error: action.payload, loading: false } };
    case FETCH_GRADES_REQUEST:
      return { ...state, ...{ loading: true } };
    default:
      return state;
  }
}

const success = payload => ({ type: FETCH_GRADES_SUCCESS, payload });
const failed = payload => ({ type: FETCH_GRADES_FAILURE, payload });
const loading = () => ({ type: FETCH_GRADES_REQUEST });

export const fetchGrades = collectionId => (dispatch) => {
  dispatch(loading());

  return http.get(`en/api/v1/user/collections/${collectionId}/grades.json`)
    .then(response => dispatch(success(response.data)))
    .catch(error => dispatch(failed(error)));
};

export const postGrade = (rank, scale, postParams, modalInput) => (dispatch) => {
  dispatch(loading());
  const params = { ...postParams };
  const collectionId = params.data_collection_parent_id;

  const levelId = getLevelIdByLabel(rank, scale);

  params.assessment_items_scales_entries_id = levelId;
  params.comment = modalInput.comment || '';

  const data = queryString.stringify(params);
  const urlGrade = `en/api/v1/user/collections/${collectionId}/grades.json`;

  // http.post(urlGrade, data) should return grade object(response.data) to have possibility
  // to post other requests (like add/delete artifacts,comments etc.)
  return http.post(urlGrade, data)
    .then(response => postByScoreId(collectionId, response.data.id, modalInput))
    .catch(error => dispatch(failed(error)));
};

export const deleteGrade = (gradeId, collectionId) => (dispatch) => {
  dispatch(loading());

  return http.delete(`en/api/v1/user/collections/${collectionId}/grades/${gradeId}.json`)
    .then(response => response.data)
    .catch(error => dispatch(failed(error)));
};
