/* eslint-disable import/no-webpack-loader-syntax */
/* eslint-disable import/extensions */

import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import QrScanner from 'qr-scanner';

import { setCurrentScoreSheet, uploadScoreSheetFailure } from 'redux/modules/scoreSheets';

import { Label, Input, UploadWrapper } from './styled';

class UploadButton extends PureComponent {
  static defaultProps = {
    backgroundColor: '#03699A',
    textColor: '#fff',
    border: 'none',
    size: 'md',
  }
  static propTypes = {
    history: PropTypes.object.isRequired,
    buttonText: PropTypes.string.isRequired,
    backgroundColor: PropTypes.string,
    textColor: PropTypes.string,
    border: PropTypes.string,
    size: PropTypes.oneOf(['lg', 'md', 'mdIcon', 'sm']),
    actions: PropTypes.shape({
      setCurrentScoreSheet: PropTypes.func.isRequired,
      uploadScoreSheetFailure: PropTypes.func.isRequired,
    }).isRequired,
  }

  constructor(props) {
    super(props);

    this.onFileChangeHandler = this.onFileChangeHandler.bind(this);
  }

  onFileChangeHandler(e) {
    const currentFile = e.target.files[0];

    if (currentFile) {
      const imageUrl = URL.createObjectURL(currentFile);

      QrScanner.scanImage(imageUrl)
        .then((result) => {
          // QR code decoded case
          const resultArray = result.split(/\/COLLECTIONS\/|\/ENROLLMENTS\/|\/SCORESHEETS/);
          const collectionId = resultArray[1];
          const enrollmentId = resultArray[2];

          // eslint-disable-next-line no-restricted-globals
          if (isNaN(collectionId) || isNaN(enrollmentId)) {
            throw String('Incorrect QR code found');
          } else {
            this.props.actions.setCurrentScoreSheet({
              file: currentFile,
              collectionId,
              enrollmentId,
              url: imageUrl,
            });
          }
        })
        .catch((error) => {
          // QR code not found case
          // QR code incorrect
          this.props.actions.setCurrentScoreSheet({
            file: null,
            collectionId: null,
            enrollmentId: null,
            url: imageUrl,
          });
          this.props.actions.uploadScoreSheetFailure(error);
        });
      this.props.history.push('/scoresheets/preview');
    }
  }

  render() {
    const { buttonText, ...rest } = this.props;

    return (
      <UploadWrapper>
        <Label
          {...rest}
        >
          {buttonText}
          <Input
            type="file"
            accept="image/*"
            capture="environment"
            onChange={this.onFileChangeHandler}
            aria-label="Upload photo"
          />
        </Label>
      </UploadWrapper>
    );
  }
}

const mapDispatchToProps = dispatch => ({
  actions: bindActionCreators({ setCurrentScoreSheet, uploadScoreSheetFailure }, dispatch),
});

export default connect(null, mapDispatchToProps)(UploadButton);
