import styled from 'styled-components';

export const Container = styled.ul`
  color: ${({ theme }) => theme.primaryWhite};
  padding-left: 0;
  overflow-y: auto;
  display: flex;
  flex-direction: column;
  max-height: 340px;
  height: 40%;
`;

export const Message = styled.li`
  background-color: ${({ theme }) => theme.primaryWhite};
  margin-top: 10px;
  margin-bottom: 10px;
  list-style-type: none;
  color: ${({ theme }) => theme.primaryBlue};
  display: flex;
  align-items: center;
  justify-content: center;
`;
