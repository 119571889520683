import React from 'react';
import { Route, Switch } from 'react-router-dom';

import GroupsList from './pages/List';
import CreateGroup from './pages/Create';
import EditGroup from './pages/Edit';

const Groups = () => (
  <Switch>
    <Route path="/collections/:collectionId/groups/list" component={GroupsList} />
    <Route path="/collections/:collectionId/groups/create" component={CreateGroup} />
    <Route path="/collections/:collectionId/groups/:id/edit" component={EditGroup} />
  </Switch>
);

export default Groups;
