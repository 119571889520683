import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';

import Button from 'components/Button';
import Checkbox from 'components/Checkbox';
import CommentsModal from 'components/Modals/CommentsModal';
import Student from 'components/Student';
import withKeyDown from 'components/withKeyDown';
import { Container, Info, Value, ColoredRound, ArtifactsButton, Clickable } from './styled';

const ClickableWithKeyDown = withKeyDown(Clickable, 'Space');
const ColoredRoundWithKeyDown = withKeyDown(ColoredRound, 'Space');

class Item extends Component {
  constructor(props) {
    super(props);

    this.openCommentsModal = this.openCommentsModal.bind(this);
    this.closeCommentsModal = this.closeCommentsModal.bind(this);
    this.goToAddArtifact = this.goToAddArtifact.bind(this);
    this.selectStudent = this.selectStudent.bind(this);
  }

  state = {
    isCommentsModalOpened: false,
  }

  closeCommentsModal() {
    this.setState({ isCommentsModalOpened: false });
  }

  openCommentsModal() {
    this.setState({ isCommentsModalOpened: true });
  }

  selectStudent() {
    const { data: { student_enrollment_id }, select } = this.props;

    select(student_enrollment_id);
  }

  goToAddArtifact(level) {
    const { taskId } = this.props.match.params;

    if (this.props.location.search) {
      this.props.history.push(`./${taskId}/student/${this.props.data.id + this.props.location.search}&rating=${level}`);
      return;
    }

    this.props.history.push(`./${taskId}/student/${this.props.data.id}?rating=${level}`);
  }

  render() {
    const { isCommentsModalOpened } = this.state;
    const {
      activityStudents,
      data: {
        checked,
        comments,
        selected,
        student,
      },
      scale,
    } = this.props;

    const isShowButton = comments.comment || (comments.comments && comments.comments.length);

    return (
      <Container>
        <Info>
          <ClickableWithKeyDown
            aria-label={`select student. Current value: ${checked}`}
            tabIndex={0}
            onClick={this.selectStudent}
          >
            {!activityStudents.length &&
              <Checkbox checked={checked} onClick={this.selectStudent} />
            }
            <Student {...student} withLabel />
          </ClickableWithKeyDown>
          {isShowButton ?
            <ArtifactsButton>
              <Button
                icon="file"
                iconAlign="right"
                onClick={this.openCommentsModal}
                aria-label={
                  `open comments modal for student
                  ${student.firstname}
                  ${student.lastname}`
                }
              >
                {+comments.comments.length ? comments.comments.length : ''}
              </Button>
            </ArtifactsButton>
            : null
          }
        </Info>
        {scale.map((item, index) => {
          const selectedGrade = scale.filter(gradeItem => gradeItem.label === selected)[0];
          const selectedGradeLabel = selectedGrade && selectedGrade.label;
          return (
            <Value key={index}>
              <ColoredRoundWithKeyDown
                onClick={() => {
                  this.goToAddArtifact(item.label);
                }}
                aria-label={`set rating to ${item.label}`}
                tabIndex={0}
                grade={selectedGradeLabel}
                isSelected={selected === item.label}
              >
                {selected === item.label ? item.label : null}
              </ColoredRoundWithKeyDown>
            </Value>
          );
        })}
        <CommentsModal
          isOpen={isCommentsModalOpened}
          onRequestClose={this.closeCommentsModal}
          data={comments}
        />
      </Container>
    );
  }
}

Item.propTypes = {
  data: PropTypes.object.isRequired,
  scale: PropTypes.array.isRequired,
  select: PropTypes.func.isRequired,
  activityStudents: PropTypes.array.isRequired,
  history: PropTypes.object.isRequired,
  location: PropTypes.object.isRequired,
  match: PropTypes.object.isRequired,
};

export default withRouter(Item);
