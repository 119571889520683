import styled from 'styled-components';
import Button from 'components/Button';

export const Container = styled.li`
  display: flex;
  border-bottom: 1px solid ${({ theme }) => theme.primaryGrey};
  padding: 10px 15px;
`;

export const Content = styled.div`
  min-width: 0;
  word-wrap: break-word;
  color: ${({ theme }) => theme.darkGrey};
`;

export const Score = styled.div`
  display: flex;
  align-items: center;
  flex: none;
  margin-left: auto;
`;

export const StyledButton = styled(Button)`
  text-transform: uppercase;
  margin-left: 15px;
`;

export const TaskClass = styled.div`
  font-size: 12px;
  font-weight: 600;
`;
