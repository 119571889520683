import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';

const withKeyDown = (WrappedComponent, handledKeys = 'Both') => class extends PureComponent {
  static defaultProps = {
    onClick: () => {},
    tabIndex: 0,
  }

  static propTypes = {
    onClick: PropTypes.func,
    tabIndex: PropTypes.number,
  }

  static getCondition(event, condition) {
    const conditions = {
      Enter: event.key === 'Enter',
      Space: event.key === ' ',
      Both: (event.key === 'Enter' || event.key === ' '),
    };

    return conditions[condition];
  }

  constructor(props) {
    super(props);

    this.keyDownHandler = this.keyDownHandler.bind(this);
  }

  keyDownHandler(event) {
    if (this.constructor.getCondition(event, handledKeys)) {
      event.preventDefault();
      this.props.onClick();
    }
  }

  render() {
    return (
      <WrappedComponent
        role="button"
        tabIndex={this.props.tabIndex}
        onKeyDown={this.keyDownHandler}
        {...this.props}
      />
    );
  }
};

export default withKeyDown;
