import React, { Component } from 'react';
import PropTypes from 'prop-types';

import Button from 'components/Button';
import Droplist from './components/Droplist';
import { Container, ChildrenContainer, DropContainer, Wrapper, MenuItems, Chevron, Arrow } from './styled';


class DropdownWrapper extends Component {
  static defaultProps = {
    selectedItemId: '',
  }

  static propTypes = {
    children: PropTypes.oneOfType([
      PropTypes.arrayOf(PropTypes.object),
      PropTypes.object,
    ]).isRequired,
    list: PropTypes.array.isRequired,
    type: PropTypes.string.isRequired,
    onDropdownSelect: PropTypes.func.isRequired,
    selectedItemId: PropTypes.string,
  }

  constructor(props) {
    super(props);

    this.toggleDropdown = this.toggleDropdown.bind(this);
    this.onDocumentClick = this.onDocumentClick.bind(this);
    this.handleBtnKeyDown = this.handleBtnKeyDown.bind(this);
    this.innerRef = this.innerRef.bind(this);
  }

  state = {
    isDropdownOpened: false,
  }

  componentDidMount() {
    this.main = document.getElementById('assessment-main');
    document.addEventListener('click', this.onDocumentClick, false);
    document.addEventListener('touchend', this.onDocumentClick, false);
  }

  componentWillUnmount() {
    this.main = undefined;
    document.removeEventListener('click', this.onDocumentClick, false);
    document.removeEventListener('touchend', this.onDocumentClick, false);
  }

  onDocumentClick(event) {
    if (!this.dropdown) {
      return;
    }

    if (!this.dropdown.contains(event.target) || event.target === this.main) {
      this.setState({ isDropdownOpened: false });
    }
  }

  toggleDropdown() {
    this.setState({ isDropdownOpened: !this.state.isDropdownOpened });
  }

  handleBtnKeyDown(event) {
    if (event.keyCode === 32 || event.keyCode === 13) {
      // 32 - space
      // 13 - enter
      event.preventDefault();
      this.toggleDropdown();
    }
  }

  innerRef(node) {
    this.dropdown = node;
  }

  render() {
    return (
      <Wrapper innerRef={node => this.innerRef(node)}>
        <Container>
          <MenuItems>
            <ChildrenContainer>
              {this.props.children}
            </ChildrenContainer>
            <DropContainer>
              <Chevron
                role="button"
                aria-label="toggle dropdown"
                tabIndex={0}
                onClick={() => this.toggleDropdown()}
                onKeyDown={event => this.handleBtnKeyDown(event)}
              >
                {this.props.type === 'header' ?
                  <Arrow aria-label="Expand assessments list" tabIndex={-1} /> :
                  <Button
                    size="lg"
                    tabIndex={-1}
                    aria-label="Expand groups list"
                    color="transparent"
                    icon="menuDropdown"
                  />
                }
              </Chevron>
            </DropContainer>
          </MenuItems>
        </Container>
        {this.state.isDropdownOpened &&
          <Droplist
            onDropdownSelect={this.props.onDropdownSelect}
            onDropdownKeySelect={this.toggleDropdown}
            type={this.props.type}
            list={this.props.list}
            selectedItemId={this.props.selectedItemId}
          />
        }
      </Wrapper>
    );
  }
}

export default DropdownWrapper;
