import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import Preloader from 'components/Preloader';
import Button from 'components/Button';
import Subheader from 'components/Subheader';
import Table from 'components/Table';
import Group from './components/Group';
import { Container, TableContainer } from './styled';

import { getGroupsList } from 'redux/modules/groups-list';
import groupListSelector from 'redux/modules/groups-list/selectors';

class GroupsList extends PureComponent {
  static propTypes = {
    getGroupsList: PropTypes.func.isRequired,
    groups: PropTypes.arrayOf(PropTypes.object).isRequired,
    history: PropTypes.object.isRequired,
    loading: PropTypes.bool.isRequired,
    match: PropTypes.object.isRequired,
  }

  constructor(props) {
    super(props);

    this.toCreateGroup = this.toCreateGroup.bind(this);
  }

  componentDidMount() {
    this.props.getGroupsList(this.props.match.params.collectionId);
  }

  toCreateGroup = () => {
    this.props.history.push('./create');
  };

  render() {
    const columns = [
      {
        title: 'Group name',
        field: 'name',
        clickable: true,
        component: <Group id={-1} onClick={row => this.props.history.push(`./${row.id}/edit`)} />,
        primaryWidth: '70%',
      },
      {
        title: '# Students',
        field: 'studentsAmount',
      },
    ];
    return (
      <Container>
        <Preloader loading={this.props.loading} />
        <Subheader module="groups">
          <div>Groups</div>
          <Button icon="plus" color="#70a823" onClick={this.toCreateGroup}>Create group</Button>
        </Subheader>
        <TableContainer>
          <Table columns={columns} data={this.props.groups} />
        </TableContainer>
      </Container>
    );
  }
}

const mapStateToProps = ({ groups }) => ({
  groups: groupListSelector(groups),
  loading: groups.loading,
});

export default connect(mapStateToProps, { getGroupsList })(GroupsList);
