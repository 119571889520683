import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  justify-content: space-between;
  height: 50px;
  width: 100%;
  align-items: center;
  flex-shrink: 0;
`;

export const Students = styled.div`
  padding: 5px 20px;
  display: flex;
  align-items: center;
  box-sizing: border-box;
  height: 100%;
  min-width: 50%;
  flex: 1;
  justify-content: space-between;
  background-color: #333;
`;

export const Item = styled.div`
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 50px;
  width: 5.55%;
  background-color: ${props => (props.theme.grades[props.grade.label])};
`;

export const Round = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 30px;
  height: 30px;
  border-radius: 50%;
  background-color: rgba(0,0,0,0.6);
  color: ${({ theme }) => (theme.primaryWhite)};
`;
