import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { Route, Switch } from 'react-router-dom';
import { connect } from 'react-redux';

import { fetchArtifacts } from 'redux/modules/artifacts';
import Preloader from 'components/Preloader';
import ArtifactsList from 'components/ArtifactsList';
import OpenedNewArtifactModal from './components/OpenedNewArtifactModal';
import { Container } from './styled';

const baseUrl = '/collections/:collectionId/activities/list';

class Artifacts extends PureComponent {
  static defaultProps = {
    createdId: null,
  }

  static propTypes = {
    artifacts: PropTypes.arrayOf(PropTypes.object).isRequired,
    createdId: PropTypes.number,
    fetchArtifacts: PropTypes.func.isRequired,
    loading: PropTypes.bool.isRequired,
    match: PropTypes.object.isRequired,
  }

  componentDidMount() {
    this.props.fetchArtifacts(this.props.match.params.collectionId);
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.createdId && this.props.createdId !== nextProps.createdId) {
      this.props.fetchArtifacts(this.props.match.params.collectionId);
    }
  }

  render() {
    const { artifacts, loading } = this.props;

    return (
      <Container role="main">
        <Preloader loading={loading} />
        <ArtifactsList artifacts={artifacts} downloadable />
        <Switch>
          <Route exact path={`${baseUrl}/artifacts/create`} component={OpenedNewArtifactModal} />
        </Switch>
      </Container>
    );
  }
}

const mapStateToProps = ({ artifacts }) => ({
  artifacts: artifacts.artifacts,
  createdId: artifacts.createdId,
  loading: artifacts.loading,
});

export default connect(mapStateToProps, { fetchArtifacts })(Artifacts);
