import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import find from 'lodash/find';

import { getTasksList } from 'redux/modules/tasks/selectors';

import Item from './components/Item';

import { Container, Header } from './styled';

const GroupsList = ({ tasksList }) => (
  <Container>
    {
      tasksList.map((set, setIndex) => (
        <div key={setIndex}>
          <Header>{set.title}</Header>
          {
            set.data.map((item, itemIndex) => (
              <Item
                key={itemIndex}
                title={item.title}
                category={item.category}
                comments={item.comments}
                level={item.level}
                taskId={item.id}
                groupId={set.id}
              />
            ))
          }
        </div>
      ))
    }
  </Container>
);

GroupsList.propTypes = {
  tasksList: PropTypes.arrayOf(PropTypes.object).isRequired,
};

const mapStateToProps = ({
  students,
  tasks,
  grades,
  scale,
}, props) => {
  const currentStudent = find(students.list, item => item.id === +props.match.params.studentId);
  return {
    tasksList: getTasksList({
      tasks,
      scale,
      grades,
      currentStudent,
    }),
  };
};

export default withRouter(connect(mapStateToProps)(GroupsList));
