import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import { getActivityStudents } from 'redux/modules/activity/selectors';
import Title from 'components/Title';
import Item from './components/Item';
import { Container, Content, StyledButton, StyledLink, StyledTitle } from './styled';

class Students extends PureComponent {
  static propTypes = {
    students: PropTypes.arrayOf(PropTypes.object).isRequired,
  }

  render() {
    const { students } = this.props;
    const text = 'No students for this activity';

    return (
      <Container tabIndex={0} aria-label={`${students.length} students in students list`}>
        <Title text={`${students.length} Students`}>
          <StyledLink aria-label="Edit students list" to="./edit?mode=students">
            <StyledButton
              tabIndex={-1}
              aria-label="Edit students list"
              size="sm"
              icon="whitePen"
              color="#70a823"
            >
              Edit
            </StyledButton>
          </StyledLink>
        </Title>
        <Content role="tree" aria-label="Students list">
          {students.length ?
            students.map((student, index) => (
              <Item key={index} student={student} />
            )) :
            <StyledTitle tabIndex={0} aria-label={text} text={text} />
          }
        </Content>
      </Container>
    );
  }
}

const mapStateToProps = ({ activity, students }) => ({
  students: getActivityStudents(activity, students),
});

export default connect(mapStateToProps)(Students);
