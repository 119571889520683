import styled from 'styled-components';
import DatePicker from 'react-datepicker';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1 0;
  padding-bottom: 30px;
`;

const TextInput = styled.input`
  color: ${({ theme }) => theme.darkGrey};
  border: 1px solid ${({ theme }) => theme.primaryGrey};
  border-radius: 4px;
  padding: 10px;
  margin-top: 5px;
  font-size: initial;
`;

export const StyledDatePicker = TextInput.withComponent(DatePicker);
