import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';

import { authenticate } from 'redux/modules/auth';
import LoginForm from './components/LoginForm';
import { Container, Wrapper, Heading, Errors } from './styled';

class Login extends PureComponent {
  static propTypes = {
    actions: PropTypes.shape({
      authenticate: PropTypes.func.isRequired,
    }).isRequired,
    authenticated: PropTypes.bool.isRequired,
    error: PropTypes.string.isRequired,
    history: PropTypes.object.isRequired,
    loading: PropTypes.bool.isRequired,
    location: PropTypes.object.isRequired,
  }

  constructor(props) {
    super(props);

    this.submit = this.submit.bind(this);
  }

  componentDidUpdate() {
    const {
      authenticated,
      loading,
      location,
      history,
    } = this.props;

    if (authenticated && !loading) {
      const path = location.state ? location.state.from.pathname : '/collections/list';
      history.push(path);
    }
  }

  submit(credentials) {
    this.props.actions.authenticate(credentials);
  }

  render() {
    const { error, loading } = this.props;

    return (
      <Container>
        <Wrapper>
          <Heading alt="logo" />
          <LoginForm role="form" loading={loading} onSubmit={this.submit} />
          <div>
            {error && <Errors>{error}</Errors>}
          </div>
        </Wrapper>
      </Container>
    );
  }
}

const mapDispatchToProps = dispatch => ({
  actions: bindActionCreators({ authenticate }, dispatch),
});

const mapStateToProps = ({ auth }) => ({
  error: auth.error,
  loading: auth.loading,
  authenticated: auth.authenticated,
});

export default connect(mapStateToProps, mapDispatchToProps)(Login);
