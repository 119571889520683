import React, { Component } from 'react';
import PropTypes from 'prop-types';

import Header from './components/Header';
import Item from './components/Item';

import { Container, Content, EmptyItem } from './styled';

class Table extends Component {
  constructor(props) {
    super(props);

    this.selectStudent = this.selectStudent.bind(this);
  }

  selectStudent(id) {
    this.props.selectStudent(id);
  }

  render() {
    return (
      <Container>
        <Header
          scale={this.props.grades}
          activityStudents={this.props.activityStudents}
        />
        <Content>
          { this.props.data.length ?
            this.props.data.map((item, index) => (
              <Item
                scale={this.props.grades}
                data={item}
                key={index}
                select={this.selectStudent}
                activityStudents={this.props.activityStudents}
              />
            ))
            : <EmptyItem>This Group is empty.</EmptyItem>
          }
        </Content>
      </Container>
    );
  }
}

Table.propTypes = {
  data: PropTypes.array.isRequired,
  grades: PropTypes.array.isRequired,
  selectStudent: PropTypes.func.isRequired,
  activityStudents: PropTypes.array.isRequired,
};

export default Table;
