import styled from 'styled-components';

export const Header = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  justify-content: space-between;
  min-height: 40px;
`;

export const Content = styled.div`

`;

export const CloseButton = styled.div`
  position: absolute;
  display: flex;
  align-items: center;
  box-sizing: border-box;
  height: 100%;
  right: 0;
  top: 0;

  button {
    padding: 10px 13px;
    height: auto;
  }
`;
