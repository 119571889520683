import styled from 'styled-components';
import Button from 'components/Button';

export const Content = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  margin: 20px;
`;

export const Header = styled.div`
  display: flex;
  box-sizing: border-box;
  align-items: center;
  height: 50px;
  width: 100%;
  padding: 10px 20px;
  font-size: 16px;
  border-bottom: 1px solid ${({ theme }) => theme.primaryGrey};
  color: ${({ theme }) => theme.darkGrey};
  background-color: ${({ theme }) => theme.lightGrey};
  text-transform: uppercase;
`;

export const Row = styled.div`
  display: flex;
  margin-top: 15px;
`;

export const StyledButton = styled(Button)`
  margin-right: 15px;
  padding: 0 40px;
  width: 140px;
`;

export const TextInput = styled.textarea`
  color: ${({ theme }) => theme.darkGrey};
  border: 1px solid ${({ theme }) => theme.primaryGrey};
  border-radius: 4px;
  padding: 10px;
  resize: none;
`;
