import styled from 'styled-components';

export const Container = styled.div`
  background-color: #f1f1f1;
  height: 100%;
  color: #ffffff;
  display: flex;
  flex-direction: column;
  overflow: hidden;
`;

export const Title = styled.div`
  width:100%;
  flex-shrink: 0;
  box-sizing: border-box;
  height: 50px;
  display: flex;
  padding-left: 20px;
  align-items: center;
  background-color: #F1F1F1;
  color: #666;
  text-transform: uppercase;
  font-size: 14px;
  font-weight: 600;
`;

export const ExpandContent = styled.div`
  display: flex;
  flex-direction: column;
  flex-shrink: 0;
  overflow-y: auto;
  background-color: #f1f1f1;
`;

export const Scrollable = styled.div`
  height: 100%;
  overflow-y: auto;
`;

export const Footer = styled.div`
  height: 70px;
  width: 100%;
  flex-shrink: 0;
  background-color: #333333;
  display: flex;
  align-items: center;
  padding: 20px;
  box-sizing: border-box;

  button {
    margin-left: 20px;
  }
`;
