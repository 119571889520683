import styled from 'styled-components';

export const Category = styled.span`
  display: flex;
  font-size: 14px;
  font-weight: 600;
`;

export const Container = styled.div`
  width: 100%;
  height: 100%;
  overflow-y: auto;
  display: flex;
  align-items: center;
  flex-direction: column;
  background: ${({ theme }) => theme.lightGrey};
`;

export const Header = styled.div`
  box-sizing: border-box;
  height: 70px;
  flex-shrink: 0;
  width: 100%;
  padding: 0 25px;
  background: ${({ theme }) => theme.lightGrey};
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-bottom: 1px solid ${({ theme }) => theme.primaryGrey};
  text-transform: uppercase;
  font-size: 16px;
  color: ${({ theme }) => theme.darkGrey};
  font-weight: 600;
`;

export const Subheader = styled.div`
  box-sizing: border-box;
  height: 50px;
  flex-shrink: 0;
  width: 100%;
  padding-left: 25px;
  display: flex;
  align-items: center;
  background: ${({ theme }) => theme.lightGrey};
  border-bottom: 1px solid ${({ theme }) => theme.primaryGrey};
  font-weight: 600;
  color: ${({ theme }) => theme.darkGrey};
  text-transform: uppercase;
  font-size: 14px;
`;

export const Task = styled.div`
  display: flex;
  color: ${({ theme }) => theme.darkGrey};
  flex-direction: column;
  justify-content: space-around;
  width: 100%;
  height: 70px;
  padding: 15px 25px;
  flex-shrink: 0;
  box-sizing: border-box;
  background: ${({ theme }) => theme.primaryWhite};
  border-bottom: 1px solid ${({ theme }) => theme.primaryGrey};
`;

export const Title = styled.span`
  display: flex;
  font-size: 16px;
  font-weight: 500;
`;
