import React from 'react';

import BaseFooter from 'components/BaseFooter';
import Button from 'components/Button';
import StyledLink from './styled';

const Footer = () => (
  <BaseFooter>
    <StyledLink id="browse-artifacts-button" to="./artifacts">
      <Button tabIndex={-1} icon="search" color="#ff9933">Browse activity artifacts</Button>
    </StyledLink>
  </BaseFooter>
);

export default Footer;
