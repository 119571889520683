export default () => [
  {
    id: 0,
    label: 'R',
    checked: true,
    default: true,
  }, {
    id: 1,
    label: 'N',
    checked: true,
  }, {
    id: 2,
    label: 'NE',
    checked: true,
  }, {
    id: 3,
    label: 'A',
    checked: true,
  }, {
    id: 4,
    label: 'B',
    checked: true,
  }, {
    id: 5,
    label: 'C',
    checked: true,
  }, {
    id: 6,
    label: 'D',
    checked: true,
  }, {
    id: 7,
    label: '1',
    checked: true,
  }, {
    id: 8,
    label: '2',
    checked: true,
  }, {
    id: 9,
    label: '3',
    checked: true,
  }, {
    id: 10,
    label: '4',
    checked: true,
  }, {
    id: 11,
    label: '5',
    checked: true,
  },
];
