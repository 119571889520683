import http from '../../../http';
import update from 'immutability-helper';

import formatGroupsList from './formatters';
import normalizeGroupsList from './normalizers';

const FETCH_GROUPS_REQUEST = 'rfc/groups/FETCH_GROUPS_REQUEST';
const FETCH_GROUPS_SUCCESS = 'rfc/groups/FETCH_GROUPS_SUCCESS';
const FETCH_GROUPS_FAILURE = 'rfc/groups/FETCH_GROUPS_FAILURE';
const SHOULD_GROUPS_LIST_UPDATE_SET = 'rfc/groups/SHOULD_GROUPS_LIST_UPDATE_SET';

const initialState = {
  groupsListing: {},
  groupsById: [],
  error: '',
  loading: false,
  shouldListUpdate: true,
};

export default function reducer(state = initialState, action = {}) {
  switch (action.type) {
    case FETCH_GROUPS_REQUEST:
      return { ...state, loading: true, shouldListUpdate: true };
    case FETCH_GROUPS_SUCCESS:
      return update(state, {
        $set: {
          groupsListing: action.payload.entities.groups || {},
          groupsById: action.payload.result,
          error: '',
          loading: false,
          shouldListUpdate: false,
        },
      });
    case FETCH_GROUPS_FAILURE:
      return update(state, { $merge: { error: action.payload, loading: false } });
    case SHOULD_GROUPS_LIST_UPDATE_SET:
      return { ...state, shouldListUpdate: true };
    default:
      return state;
  }
}

const requestGroups = () => ({ type: FETCH_GROUPS_REQUEST });
const errorGroups = payload => ({ type: FETCH_GROUPS_FAILURE, payload });
const recieveGroups = payload => ({ type: FETCH_GROUPS_SUCCESS, payload: normalizeGroupsList(payload) });

export const setShouldGroupsListUpdate = () => ({ type: SHOULD_GROUPS_LIST_UPDATE_SET });

export function getGroupsList(collectionId) {
  return (dispatch, getState) => {
    if (getState().groups.shouldListUpdate) {
      dispatch(requestGroups());

      http({ method: 'get', url: `en/api/v1/user/collections/${collectionId}/groups.json` })
        .then(response => dispatch(recieveGroups(formatGroupsList(response.data))))
        .catch(error => dispatch(errorGroups(error)));
    }
  };
}
