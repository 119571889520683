import styled from 'styled-components';
import Button from 'components/Button';

export const Label = styled.p`
  color: ${({ theme }) => theme.primaryWhite};
  font-size: 12px;
  margin-left: 15px;
`;

export const StyledButton = styled(Button).attrs({
  color: '#70a823',
})`
  margin: 10px 0 10px 15px;
  padding: 0 40px;
`;
