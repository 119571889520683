import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import { getStudentsForTable } from 'redux/modules/students/selectors';
import Checkbox from 'components/Checkbox';
import Table from 'components/Table';
import StudentItem from 'components/Student';

class StudentsListTable extends PureComponent {
  static defaultProps = {
    groupStudents: null,
  }

  static propTypes = {
    onStudentSelect: PropTypes.func.isRequired,
    onStudentClick: PropTypes.func.isRequired,
    selectedStudents: PropTypes.array.isRequired, // eslint-disable-line react/no-unused-prop-types
    groupStudents: PropTypes.array, // eslint-disable-line react/no-unused-prop-types
    isExpanded: PropTypes.bool.isRequired,
    data: PropTypes.array.isRequired,
  }

  columnsExpanded = [
    {
      title: 'SELECT',
      field: 'select',
      disableSort: true,
      component: <Checkbox tabIndex={-1} aria-label="Select student" />,
      onClick: this.props.onStudentSelect,
      width: 70,
    },
    {
      title: 'NAME',
      field: 'name',
      clickable: true,
      component: <StudentItem id={-1} onClick={this.props.onStudentClick} />,
      primaryWidth: '40%',
    },
    {
      title: 'TOTAL SKBs RATED',
      field: 'total',
    },
    {
      title: 'TOTAL SKBs RATED (This Week)',
      field: 'week',
    },
    {
      title: 'LAST RATING DATE',
      field: 'last',
    },
  ];

  columns = [
    {
      title: 'Select',
      field: 'select',
      disableSort: true,
      component: <Checkbox tabIndex={-1} aria-label="Select student" />,
      onClick: this.props.onStudentSelect,
      width: 70,
    },
    {
      title: 'Name',
      field: 'name',
      component: <StudentItem id={-1} onClick={this.props.onStudentClick} />,
    },
  ];

  render() {
    const { data, isExpanded } = this.props;

    return (
      <Table
        columns={isExpanded ? this.columnsExpanded : this.columns}
        data={data}
        isExpanded={isExpanded}
      />
    );
  }
}

const mapStateToProps = ({ students, grades }, { selectedStudents, groupStudents }) => ({
  data: getStudentsForTable(students, selectedStudents, groupStudents, grades.list),
});

export default connect(mapStateToProps)(StudentsListTable);
