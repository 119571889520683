import React from 'react';
import PropTypes from 'prop-types';

import StudentsListTable from 'components/StudentsListTable';
import Title from 'components/Title';

import { Container, Header, StudentList } from './styled';

const SelectStudents = ({ onChange, studentsIds }) => (
  <Container>
    <Header>
      <Title text="Select students" />
    </Header>
    <StudentList role="group" aria-label="select students">
      <StudentsListTable
        selectedStudents={studentsIds}
        isExpanded={false}
        onStudentClick={onChange}
        onStudentSelect={onChange}
      />
    </StudentList>
  </Container>
);

SelectStudents.propTypes = {
  onChange: PropTypes.func.isRequired,
  studentsIds: PropTypes.array.isRequired,
};

export default SelectStudents;
