import styled from 'styled-components';
import EditIcon from './images/edit.png';

export const Container = styled.div`
  box-sizing: border-box;
  width: 100%;
  height: 60%;
  min-height: 350px;
  padding: 25px;
  display: flex;
  flex-direction: column;
  color: ${({ theme }) => (theme.darkGrey)};
`;

export const Avatar = styled.div`
  position: relative;
  height: 140px;
  width: 140px;
  display: flex;
  flex-shrink: 0;
  margin: 0 auto;
  justify-content: center;
  align-items: center;
  align-self: center;
  border: 1px solid ${({ theme }) => (theme.primaryGrey)};
  padding: 8px;
  border-radius: 100%;
`;

export const Image = styled.img`
  width: 100%;
  height: 100%;
  overflow: hidden;
  border-radius: 50%;
  user-select: none;
`;

export const EditButton = styled.span`
  display: block;
  width: 25px;
  height: 25px;
  position: absolute;
  bottom: -5px;
  right: -10px;
  cursor: pointer;
  background: url(${EditIcon}) no-repeat center center;
  background-size: contain;
`;

export const TextInfo = styled.div`
  height: calc(100% - 140px);
  width: 100%;
  font-size: 20px;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: center;
  margin: 20px 0;
  font-size: 14px;
`;

export const Header = styled.h4`
  font-size: 16px;
  font-weight: 100;
  margin: 0;
`;
